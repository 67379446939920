import Vue from 'vue';
import store from '../store';
import router from '../router';
import Toasted from 'vue-toasted';
import { fireGoogleTagError } from '@/utils/google-tag-manager-helpers';

Vue.use(Toasted)

export function handleAllErrors (errorPrefix: string, error: any): void {
	// 500s
	if (typeof error === 'string') {
		Vue.toasted.show(errorPrefix + error, { type: 'error', position: 'top-center' }).goAway(5000);
	}

	if (error.response?.data?.message) {
		// Avoid DTO validation errors
		if (Array.isArray(error.response.data.message)) {
			Vue.toasted.show(errorPrefix, { type: 'error', position: 'top-center' }).goAway(5000);
		}
		else {
			Vue.toasted.show(errorPrefix + error.response.data.message, { type: 'error', position: 'top-center' }).goAway(5000);
		}
	}

	else {
		Vue.toasted.show(errorPrefix, { type: 'error', position: 'top-center' }).goAway(5000);
	}
}

/**
 * Check if the error is due to an invalid/expired user Salata token
 * If so log the user out and redirect to login page
 *
 * @param {any} error
 * @param {string} redirect - Where to redirect to after error handling and logout
 * This was needed for login as because on refresh restaurant and event data is lost which is needed for this type of login to work
 * Because authentication happens before any routes resolve, we never hit the restaurant index if authentication fails, so we never get the data we need
 * So we redirect to the restaurant index first, which fetches the needed data first then handles where to redirect to afterwards
 * @return {void}
 */
export function expiredTokenErrorHandler(error: any, redirect?: string): void {
	if (error.response?.data?.code === 201) {
		store.commit('auth/LOG_USER_OUT');
		store.commit('auth/LOG_OPERATOR_OUT');
		store.commit('cart/CLEAR_ITEMS_AND_CARD_INFO');
		store.commit('filters/CLEAR_SELECTED_FILTERS');
		router.replace(redirect || { path: '/login', query: router.currentRoute.query });
		fireGoogleTagError({ name: 'expiredTokenError', error });
	}
}