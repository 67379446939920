
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import CameraIcon from 'vue-feather-icons/icons/CameraIcon';
import LockIcon from 'vue-feather-icons/icons/LockIcon';
import CreditCardIcon from '../../assets/images/icons/credit-card-icon.svg?inline';
import VisaLogoIcon from '../../assets/images/icons/visa-logo-icon-blue.svg?inline';
import MastercardLogoIcon from '../../assets/images/icons/mastercard-logo-icon.svg?inline';
import AmexLogoIcon from '../../assets/images/icons/amex-logo-icon.svg?inline';

import { Validate } from '@/types';
import { formatCVD, formatCCNumber, formatExpiryDate } from '../../utils/format';
import { findCardType } from '../../utils/helpers';
import '@/validation-rules';

@Component<AddCreditCard>({
	components: {
		ArrowLeftIcon,
		ValidationObserver,
		ValidationProvider,
		CameraIcon,
		LockIcon,
		CreditCardIcon,
		VisaLogoIcon,
		MastercardLogoIcon,
		AmexLogoIcon,
	}
})
export default class AddCreditCard extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private creditCard!: PaymentOption;
	@Prop({ type: String, required: true, default: '' }) private isLoading!: string;
	@Prop({ type: Array, required: true, default: []}) private paymentMethods!: PaymentOption[];
	@Getter('showLoginAsBanner', { namespace: 'auth' }) private showLoginAsBanner!: boolean;
	$refs!: {observer: Validate}

	private isEditing: boolean = this.creditCard.ccNumber ? true : false;
	private isOnlyPaymentMethod: boolean = !!(this.paymentMethods.length === 0 || this.paymentMethods.length <= 1 && this.isEditing);
	private paymentInfo: PaymentOption = { ...this.creditCard };

	private get editingSavedPayment(): boolean {
		return this.isEditing && this.paymentInfo.cvs === '•••' && this.paymentInfo.postalCode === '••••••';
	}

	/**
	 * Set default payment to true if first payment method 
	 * 
	 * @return {void}
	 */
	private mounted(): void {
		if (this.paymentMethods.length === 0) {
			this.paymentInfo.isDefault = true;
		}
	}

	/**
	 * Use helper to identify card type
	 *
	 * @param {string} cardNumber
	 * @return {string}
	 */
	private findCardType(cardNumber: string): string {
		return findCardType(cardNumber.replace(/\D/g,''))
	}

	/**
	 * Empty cvs input field on first focus
	 *
	 * @return {void}
	 */
	private clearInput(): void {
		if (this.isEditing && this.paymentInfo.cvs === '•••') {
			this.paymentInfo.cvs = '';
		}
	}

	/**
	 * Emit previous step event to the parent component with the payment information data and edit flag
	 *
	 * @return {void}
	 */
	private prev(): void {
		this.$emit('prev');
	}

	/**
	 * Emit next step event to the parent component with the payment information data and edit flag
	 *
	 * @return {void}
	 */
	private async submit(): Promise<void> {
		const isValid = await this.$refs.observer.validate();
		if (isValid) {
			this.$emit('next', {
				paymentInfo: this.paymentInfo,
				type: this.isEditing ? 'edit' : 'add'
			});
		}
	}

	/**
	 * Emit next step event to the parent component with a delete flag and the payment information data
	 *
	 * @return {void}
	 */
	private deletePaymentOption(): void {
		this.$emit('next', {
			paymentInfo: this.paymentInfo,
			type: 'delete'
		});
	}

	/**
	 * Trigger the camera
	 *
	 * @return {void}
	 */
	// TODO: Implement feature and bind to the camera button icon
	private useCamera(): void {
		// console.log('useCamera triggered');
	}

	/**
	 * Format the credit card numbers into 4 digits separated by dashes
	 *
	 * @return {void}
	 */
	private formatCCNumber(): void {
		this.paymentInfo.ccNumber = formatCCNumber(this.paymentInfo.ccNumber);
	}

	/**
	 * Format CVS to respect the format with no digital characters
	 *
	 * @return {void}
	 */
	private formatCVD(): void {
		this.paymentInfo.cvs = formatCVD(this.paymentInfo.cvs);
	}

	/**
	 * Format the expiry date with a slash between month and year
	 *
	 * @return {void}
	 */
	private formatExpiryDate(): void {
		this.paymentInfo.expiryDate = formatExpiryDate(this.paymentInfo.expiryDate);
	}

	/**
	 * Format postal code to only show uppercase letters
	 * 
	 * @return {void}
	 */
	private formatPostalCode(): void {
		this.paymentInfo.postalCode = this.paymentInfo.postalCode.toUpperCase();
	}
}
