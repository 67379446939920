
import { Vue, Component, Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
const clickaway = require('vue-clickaway').mixin;

@Component<Modal>({
	mixins: [ clickaway ]
})

export default class Modal extends Vue {
	@Prop({ type: Boolean, required: false, default: false }) private closeOnClickOutside!: boolean;

	/**
	 * Listen to resize event to change dimensions of the menu
	 * when window is being resized
	 *
	 * @return {void}
	 */
	private mounted(): void {
		this.handleResize();
		window.addEventListener('resize', debounce(() => {
			this.handleResize();
		}, 100));
	}

	/**
	 * Handle the resize of the viewer when viewport is being
	 * changed.
	 *
	 * @return {void}
	 */
	private handleResize(): void {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}

	/**
	 * Destroy listener on close
	 *
	 * @return {void}
	 */
	private beforeDestroy(): void {
		window.removeEventListener('resize', this.handleResize);
	}

	/**
	 * Send event to parent to close the modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
