import i18n from '@/i18n';
import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { FiltersState, RootState } from '../types';
const namespaced: boolean = true;

export const state: FiltersState = {
	//filters must match the options on the B2B app and the value is the name of the label on B2B
	dietOptions: [
		{
			label: i18n.t('vuex_store.filters.diets.pescatarian_label'),
			itemLabel: i18n.t('vuex_store.filters.diets.item.pescatarian_label'),
			value: 'Pescatarian',
			selected: false
		},
		{
			label: i18n.t('vuex_store.filters.diets.vegan_label'),
			itemLabel: i18n.t('vuex_store.filters.diets.item.vegan_label'),
			value: 'Vegan',
			selected: false
		},
		{
			label: i18n.t('vuex_store.filters.diets.vegetarian_label'),
			itemLabel: i18n.t('vuex_store.filters.diets.item.vegetarian_label'),
			value: 'Vegetarian',
			selected: false
		}
	],
	allergenOptions: [{
		label: i18n.t('vuex_store.filters.allergens.peanuts_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.peanuts_label'),
		value: 'Peanuts',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.treenuts_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.treenuts_label'),
		value: 'Treenuts',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.dairy_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.dairy_label'),
		value: 'Dairy',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.gluten_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.gluten_label'),
		value: 'Gluten',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.egg_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.egg_label'),
		value: 'Egg',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.fish_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.fish_label'),
		value: 'Fish',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.soy_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.soy_label'),
		value: 'Soy',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.sesame_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.sesame_label'),
		value: 'Sesame',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.mustard_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.mustard_label'),
		value: 'Mustard',
		selected: false
	},
	{
		label: i18n.t('vuex_store.filters.allergens.alcohol_label'),
		itemLabel: i18n.t('vuex_store.filters.allergens.item.alcohol_label'),
		value: 'Alcohol',
		selected: false
	}]
};

export const Filters: Module<FiltersState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Filters;