
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { findCardType } from '../../utils/helpers';
import PoweredByApp8 from '@/components/shared/PoweredByApp8.vue';
import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
import LockIcon from 'vue-feather-icons/icons/LockIcon';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import EditIcon from '../../assets/images/icons/edit.svg?inline';
import CreditCardIcon from '../../assets/images/icons/credit-card-icon.svg?inline';
import VisaLogoIcon from '../../assets/images/icons/visa-logo-icon-blue.svg?inline';
import MastercardLogoIcon from '../../assets/images/icons/mastercard-logo-icon.svg?inline';
import AmexLogoIcon from '../../assets/images/icons/amex-logo-icon.svg?inline';

@Component<PaymentMethods>({
	components: {
		ArrowLeftIcon,
		PlusIcon,
		LockIcon,
		EditIcon,
		CreditCardIcon,
		VisaLogoIcon,
		MastercardLogoIcon,
		AmexLogoIcon,
		PoweredByApp8
	}
})
export default class PaymentMethods extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private paymentMethods!: PaymentOption[];
	@Prop({ type: String, required: true, default: () => '' }) private isLoading!: String;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Getter('showLoginAsBanner', { namespace: 'auth' }) private showLoginAsBanner!: boolean;
	@Watch('paymentMethods')
	onPaymentMethodsupdate(): void {
		this.mutablePaymentMethods = this.paymentMethods.map(paymentOption => {
			return { ...paymentOption }
		});
	}

	private mutablePaymentMethods: PaymentOption[] = this.paymentMethods.map(paymentOption => {
		return { ...paymentOption }
	});

	private showError: boolean = false;
	private isRegisterFlow: boolean = this.$route.name === 'Login';

	private errorMessage: string = '';

	/**
	* Return if a payment option is selected
	*
	* @return {boolean}
	*/
	private get isPaymentSelected(): boolean {
		return this.mutablePaymentMethods.find(paymentOption => paymentOption.isDefault) ? true : false;
	}

	/**
	 * Use helper to identify card type
	 *
	 * @param {string} cardNumber
	 * @return {string}
	 */
	private findCardType(cardNumber: string): string {
		return findCardType(cardNumber.replace(/\D/g,''))
	}

	/**
	 * Check the loading before emitting prev event to go back to contact information step
	 *
	 * @return {void}
	 */
	private prev() {
		this.$emit('prev');
	}

	/**
	 * Emit next event to go to edit card step
	 *
	 * @return {void}
	 */
	private editPaymentOption(paymentMethod: PaymentOption) {
		this.$emit('next', { type: 'edit', paymentMethod });
	}

	/**
	 * Emit next event to go to add card step
	 *
	 * @return {void}
	 */
	private next() {
		this.$emit('next', { type: 'add' });
	}
	
	/**
	 * Validate the mutable payment methods before emitting a submit event to the parent component
	 *
	 * @param {string} type
	 * @return {void}
	 */
	private submit(type: string): void {
		if (type === 'skip') {
			this.$emit('submit', type);
		}
		else {
			if (this.mutablePaymentMethods.length === 0) {
				this.showError = true;
				this.errorMessage = this.$t('auth.payment_methods.error_add_card');
			}
			else if (!this.isPaymentSelected) {
				this.showError = true;
				this.errorMessage = this.$t('auth.payment_methods.error_select_card');
			}
			else {
				this.$emit('submit', type);
			}
		}
	}
}
