
import { Vue, Component } from 'vue-property-decorator';
import PoweredByApp8En from '../../assets/images/powered-by-app8-en.svg?inline';
import PoweredByApp8Fr from '../../assets/images/powered-by-app8-fr.svg?inline';

@Component<PoweredByApp8>({
	components: {
		PoweredByApp8En,
		PoweredByApp8Fr
	}
})
export default class PoweredByApp8 extends Vue {}
