
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { handleAllErrors } from '@/utils/errorHandling';
import ContactInformation from '@/components/auth/ContactInformation.vue';

const namespace: string = 'auth';

@Component<UserInformation>({
	components: {
		ContactInformation
	}
})
export default class UserInformation extends Vue {
	@Action('editUser', { namespace }) private editUserContactInfo!: (payload: EditContactInfoPayload) => Promise<void>;
	@Getter('getUser', { namespace }) private user!: UserInfo;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('getUserEventSuite', { namespace: 'suites' }) private userEventSuite!: UserEvent;

	private loading: string = '';

	/**
	 * Redirect to profile index
	 *
	 * @return {void}
	 */
	private toIndex(): void {
		this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {});
	}

	/**
	 * Edit user info and redirects to appropriate page.
	 * If the user is a suite owner with no payment method, redirects to the payment methods page.
	 *
	 * @param {UserContactInfo} userInfo
	 * @return {Promise<void>}
	 */
	private async editUser(userInfo: UserContactInfo): Promise<void> {
		try {
			this.loading = 'edit';
			await this.editUserContactInfo({ userId: this.user.id, token: this.user.token, userInfo });
			if (this.userEventSuite && !this.user.paymentMethods.length) {
				this.$router.push({ path: '/profile/user-payment-methods', query: this.$route.query }).catch(() => {});
			}
			else {
				this.$router.push({ path: `/${this.restaurant.slug}`, query: this.$route.query }).catch(() => {});
			}
		} catch (error) {
			handleAllErrors(this.$t('profile.edit_profile.error_prefix'), error);
		} finally {
			setTimeout(() => {
				this.loading = '';
			}, 500);
		}
	}
}
