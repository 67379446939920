import Vue from 'vue';
import { MutationTree } from 'vuex';
import { SuitesState } from '../types';
import { state as restaurantState } from '../restaurant/restaurant';
import { state as cartState } from '../cart/cart';
import { formatOrderItemFromEventSuiteOrder, formatOrderOptionFromEventSuiteOrder } from '../../utils/format';
import Cookies from 'js-cookie';

export const mutations: MutationTree<SuitesState> = {
	/**
	 * Set the suites location id and save it in cookies if not pre-ordering
	 * Event day ordering can use menus and send orders to a different location from the suites location,
	 * so we need to save the suites location id in cookies so we can use it to fetch event information from the correct schema
	 * Pre-ordering sends orders to the suites location, so we can use the restaurant id, so we should remove the suites location id from cookies
	 *
	 * @param {SuitesState} state
	 * @param {string} suitesLocationId
	 */
	SET_SUITES_LOCATION_ID(state: SuitesState, suitesLocationId: string): void {
		if (!state.preOrdering) {
			Cookies.set('suitesLocationId', suitesLocationId);
		}
		else if (Cookies.get('suitesLocationId')) {
			Cookies.remove('suitesLocationId');
		}
		Vue.set(state, 'suitesLocationId', suitesLocationId);
	},

	/**
	 * Set the minimal event info
	 * We also set the table number here as the suite name
	 *
	 * @param {SuitesState} state
	 * @param {MinimalEventInfo} minimalEventInfo
	 * @return {void}
	 */
	SET_MINIMAL_EVENT_INFO(state: SuitesState, minimalEventInfo: MinimalEventInfo): void {
		Vue.set(state, 'minimalEventInfo', minimalEventInfo);
		if(minimalEventInfo.eventSuite) {
			Vue.set(cartState.config, 'tableNum', minimalEventInfo.eventSuite.id.toString());
		}
	},

	/**
	 * Set the selected event suite ID for the query params
	 *
	 * @param {SuitesState} state
	 * @param {number} eventSuiteId
	 * @return {void}
	 */
	SET_SELECTED_EVENT_SUITE_ID(state: SuitesState, eventSuiteId: number): void {
		Vue.set(state, 'eventSuiteId', eventSuiteId);
	},

	/**
	 * Set the selected event suite details
	 *
	 * @param {SuitesState} state
	 * @param {UserEvent} userEventSuite
	 * @return {void}
	 */
	SET_SELECTED_USER_EVENT_SUITE(state: SuitesState, userEventSuite: UserEvent): void {
		Vue.set(state, 'userEventSuite', userEventSuite);
	},

	/**
	 * Set the membership program info
	 *
	 * @param {SuitesState} state
	 * @param {UserEvent[]} payload
	 * @return {void}
	 */
	SET_USER_UPCOMING_EVENTS(state: SuitesState, payload: UserEvent[]): void {
		Vue.set(state, 'upcomingEvents', payload);
	},

	/**
	 * Set the catering order of the event suite
	 *
	 * @param {SuitesState} state
	 * @param {EventSuiteOrder} suiteCateringOrder
	 * @return {void}
	 */
	SET_SUITE_CATERING_ORDER(state: SuitesState, suiteCateringOrder: EventSuiteOrder): void {
		Vue.set(state, 'suiteCateringOrder', suiteCateringOrder);
	},

	/**
	 * Set the selected event suite and set the
	 * pre-ordering flag if not set already
	 * TODO build constants for formatting that will be used across the board,
	 * because right now if we change the format of the item somewhere, we'll have to change in
	 * multiple places.
	 *
	 * @param {SuitesState} state
	 * @param {EventSuiteOrder} suiteCateringOrder
	 * @return {void}
	 */
	SET_PRE_ORDER_CART(state: SuitesState, suiteCateringOrder: EventSuiteOrder): void {
		// loop through suite catering order items
		try {

			// Set the pre-order information (delivery time, notes and answered questions)
			cartState.checkout.suitesInfo = {
				tabLimit: state.userEventSuite?.tab_limit ? state.userEventSuite.tab_limit : null,
				deliveryTime: state.userEventSuite?.pre_order_delivery_time ? state.userEventSuite.pre_order_delivery_time : '',
				notes: state.userEventSuite?.pre_order_additional_notes ? state.userEventSuite.pre_order_additional_notes : ''
			}
			cartState.checkout.questions = suiteCateringOrder.data.checkout.questions?.length ? suiteCateringOrder.data.checkout.questions : null;

			cartState.items = suiteCateringOrder.data.items.map((tempOrderItem: OrderItem) => {
				let orderItem: OrderItem = {} as OrderItem;
				// find the item in the menu
				const menuItem = restaurantState.itemList.find((menuItem: MenuItem) => menuItem.id === tempOrderItem.id);
				if(menuItem) {
					orderItem = formatOrderItemFromEventSuiteOrder(tempOrderItem, menuItem);

					// Order options
					orderItem.orderOptions = tempOrderItem.orderOptions && tempOrderItem.orderOptions.length ? tempOrderItem.orderOptions.map((tempOrderOption: OrderOption) => {
						let orderOption: OrderOption = {} as OrderOption;
						const optionGroup = menuItem && menuItem.options && menuItem.options.find((tempOptionGroup: OptionGroup) => tempOptionGroup.id === tempOrderOption.id);

						// Here we want to still treat the special instructions
						if(!optionGroup && !tempOrderOption.memo) {
							throw new Error('Option group not found');
						}
						else {
							orderOption = formatOrderOptionFromEventSuiteOrder(tempOrderItem, menuItem, tempOrderOption, optionGroup ? optionGroup : undefined);
						}
						return orderOption;
					}) : []

					// Increment orderId since we already have one item in the cart
					orderItem.orderId = cartState.orderId;
					cartState.orderId++;
				}
				else {
					throw new Error('Menu item not found');
				}
				return orderItem;
			});
		} catch (error) {
			throw new Error('Something went wrong');
		}
	},

	/**
	 * Set the pre ordering flag
	 *
	 * @param {SuitesState} state
	 * @param {boolean} preOrdering
	 * @return {void}
	 */
	SET_PRE_ORDERING_FLAG(state: SuitesState, preOrdering: boolean): void {
		Vue.set(state, 'preOrdering', preOrdering);
	},

	/**
	 * Set the event day ordering flag
	 *
	 * @param {SuitesState} state
	 * @param {boolean} eventDayOrdering
	 * @return {void}
	 */
	SET_EVENT_DAY_ORDERING_FLAG(state: SuitesState, eventDayOrdering: boolean): void {
		Vue.set(state, 'eventDayOrdering', eventDayOrdering);
	},

	/**
	 * Set active events
	 *
	 * @param {SuitesState} state
	 * @param {EventLocationInfo[]} activeEvents
	 * @return {void}
	 */
	SET_ACTIVE_EVENTS(state: SuitesState, activeEvents: EventLocationInfo[]): void {
		Vue.set(state, 'activeEvents', activeEvents);
	},

	/**
	 * Set the selected event for operator event day ordering
	 *
	 * @param {SuitesState} state
	 * @param {EventInfo} selectedEvent
	 */
	SET_SELECTED_EVENT(state: SuitesState, selectedEvent: EventInfo): void {
		Vue.set(state, 'selectedEvent', selectedEvent);
	},

	/**
	 * Reset suite and event info
	 *
	 * @param {SuitesState} state
	 * @return {void}
	 */
	RESET_SUITE_INFO(state: SuitesState): void {
		Vue.set(state, 'eventSuiteId', null);
		Vue.set(state, 'userEventSuite', null);
		Vue.set(state, 'minimalEventInfo', null);
		Vue.set(state, 'upcomingEvents', []);
		Vue.set(state, 'suiteCateringOrder', null);
		Vue.set(state, 'selectedEvent', null);
	}
};