
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import EyeIcon from 'vue-feather-icons/icons/EyeIcon';
import EyeOffIcon from 'vue-feather-icons/icons/EyeOffIcon';
import '@/validation-rules';
import VTooltip from 'v-tooltip';
import InfoBanner from '@/components/shared/InfoBanner.vue';

Vue.use(VTooltip);

VTooltip.options.themes.tooltip.triggers = ['hover', 'click'];

@Component<LoginForm>({
	components: {
		ValidationObserver,
		ValidationProvider,
		EyeIcon,
		EyeOffIcon,
		InfoBanner
	}
})
export default class LoginForm extends Vue {
	@Getter('getMinimalEventInfo', { namespace: 'suites'}) private minimalEventInfo!: MinimalEventInfo;
	@Prop({ type: Object, required: true, default: () => {} }) private credentials!: LoginCredentials;
	@Prop({ type: Boolean, required: true, default: false }) private isNativeLoading!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private isAnyLoading!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private forgotPasswordToggle!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private isPreOrdering!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private isSuiteOperatorLogin!: boolean;

	private loginCredentials: LoginCredentials = { ...this.credentials };
	private showPassword: boolean = false;
	private emailAutoFilled: boolean = false;

	$refs!: {observer: Validate};

	/**
	 * Check if email has been return minimal event info and set the email
	 *
	 * @return {void}
	 */
	private created(): void {
		if (this.isPreOrdering && this.minimalEventInfo.eventSuite.suite_owner?.email) {
			this.loginCredentials.email = this.minimalEventInfo.eventSuite.suite_owner.email;
			this.emailAutoFilled = true;
		}
	}

	/**
	 * Show or hide the password depending on the status of the eye icon.
	 * We have to change the icon when the state change and also the input type
	 *
	 * @return {void}
	 */
	private showHidePassword(): void {
		this.showPassword = !this.showPassword;
		const passwordInput = document.getElementById('password')!;
		this.showPassword ? passwordInput.setAttribute('type', 'text') : passwordInput.setAttribute('type', 'password');
	}

	/**
	 * Submit the login request
	 *
	 * @return {Promise<void>}
	 */
	private async submit(): Promise<void> {
		const isValid = await this.$refs.observer.validate();
		if (isValid) {
			if (!this.forgotPasswordToggle) {
				this.$emit('submit', this.loginCredentials);
			}
			else {
				this.$emit('forgot-password-submit', this.loginCredentials.email);
			}
		}
	}

	/**
	 * Fire password clicked event to the parent
	 *
	 * @return {void}
	 */
	private forgotPasswordToggled(): void {
		if (!this.isAnyLoading) {
			this.$emit('forgot-password-toggled');
		}
	}
}
