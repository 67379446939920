
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import CartUpsellItemCard from './CartUpsellItemCard.vue';

const namespace: string = 'cart';

@Component<CartUpsellItemList>({ components: { CartUpsellItemCard } })
export default class CartUpsellItemList extends Vue {
	@Action('setTemporaryItem', { namespace }) private setTemporaryItem!: (temporaryItemPayload: { tempItem: MenuItem|null, quantity?: number }) => void;
	@Action('addToCart', { namespace }) private addToCart!: (extraData: any) => Promise<void>;
	@Action('setCosts', { namespace }) private setCosts!: (specifier?: string) => void;
	@Getter('getLegalCheck', { namespace }) private legalChecked!: boolean;
	@Prop({ type: Array, required: true, default: () => [] }) private items!: MenuItem[];

	private isLoading: boolean = false;

	/**
	 * Add the item to the cart if it has no options, otherwise show the item modal.
	 * If we add the item, update the cart costs as well.
	 * * If we update the logic of adding the item to the cart, check if it impacts addItemToCart in MenuItemViewer.vue
	 *
	 * @param {MenuItem} item
	 * @param {number} index
	 * @return {Promise<void>}
	 */
	private async addItemToCart(item: MenuItem, index: number): Promise<void> {
		if (this.legalCheck(item, index)) {
			this.isLoading = true;
			try {
				this.setTemporaryItem({ tempItem: item });
				await this.addToCart({ special_instructions: '', originalPrice: item!.price, type: 'addUpsellItem' });

				try {
					await this.setCosts();
				}
				catch (error) {
					if(typeof error === 'string') {
						this.$toasted.show(error, { type: 'error', position: 'top-center' }).goAway(5000);
					}
					else {
						this.$toasted.show(this.$t('cart.pricing.error_totals'), { type: 'error', position: 'top-center' }).goAway(5000);
					}
				}
			}
			catch {
				this.$emit('error');
			}
			finally {
				setTimeout(() => this.isLoading = false, 500);
			}
		}
	}

	/**
	 * Check if the item contains alcohol to trigger the legal check
	 *
	 * @param {MenuItem} item
	 * @param {number} index
	 * @return {boolean}
	 */
	private legalCheck(item: MenuItem, index: number): boolean {
		if (item.allergens?.includes('Alcohol') && !this.legalChecked) {
			this.$emit('check-legal', () => this.addItemToCart(item, index));
			return false;
		}
		return true;
	}
}
