
import { Component, Vue } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import EditLocationIcon from '../assets/images/icons/edit-location.svg?inline';

@Component<ComingSoon>({
	components: { EditLocationIcon }
})
export default class ComingSoon extends Vue {
	private get background(): { backgroundImage: string } {
		return {
			backgroundImage: `url('${process.env.VUE_APP_IMAGE_BUCKET}/bowlero/coming-soon.jpg')`
		};
	}

	/**
	 * Remove geolocation cookie and redirect to location search
	 *
	 * @return {void}
	 */
	private redirectToLocationSearch(): void {
		if (Cookies.get('currentLocation')) {
			Cookies.remove('currentLocation');
		}
		if (Cookies.get('previousLocation')) {
			Cookies.remove('previousLocation');
		}
		this.$router.push({ path: '/bowlero-location-search' }).catch(() => {});
	}
}
