
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { filterProvince } from '@/utils/filters';
import '@/validation-rules';

const DEFAULT_DELIVERY_INFORMATION = {
	type: 'address',
	extra: ''
};

@Component<DeliveryDefault>({
	components: {
		VueGoogleAutocomplete,
		ValidationProvider
	}
})
export default class DeliveryDefault extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private restaurantDeliveryInfo!: DefaultDelivery;
	@Prop({ type: Object, required: true, default: () => {} }) private deliveryInformation!: CheckoutDeliveryInfo;
	@Prop({ type: String, required: true, default: '' }) private coordinatesErrorMsg!: string;

	private delivery: CheckoutDeliveryInfo = { ...DEFAULT_DELIVERY_INFORMATION };
	private address: object = {};
	private autocompleteFilledUp: boolean = false;

	/**
	 * Set the temporary delivery information to the
	 * vuex store values if there are any to prevent
	 * refilling the same information again, also verifying
	 * if there are lat/lng to make sure that we can compare
	 * the distance.
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if (this.deliveryInformation) {
			this.delivery = this.deliveryInformation;
			this.delivery.type = 'address';

			if(this.delivery.lat && this.delivery.lng) {
				this.autocompleteFilledUp = true;
			}
		}
	}

	/**
	 * Send event to the parent to update the delivery
	 * information in the vuex store
	 *
	 * @return {void}
	 */
	private updateDeliveryInfo(): void {
		this.$emit('input', this.delivery);
	}


	/**
	 * Clear selection on input to force user to
	 * select an address from the autocomplete
	 *
	 * @return {void}
	 */
	private clearDeliverySelection(): void {
		if(this.delivery.address) {
			this.delivery = {
				type: 'address',
				extra: ''
			};
			this.updateDeliveryInfo();
		}
	}

	/**
	 * Deconstruct the address object returned by
	 * the autocomplete.
	 *
	 * @return {void}
	 */
	private autofillAddress(addressData: any, placeResultData: any): void {
		this.delivery.address = placeResultData.formatted_address;
		this.delivery.country = addressData.country;
		this.delivery.lat = addressData.latitude;
		this.delivery.lng = addressData.longitude;
		this.delivery.city = addressData.locality;
		this.delivery.province = filterProvince(addressData.administrative_area_level_1);
		this.delivery.postalCode = addressData.postal_code;
		this.delivery.streetName = addressData.route;
		this.delivery.streetNumber = addressData.street_number;
		this.delivery.radius = this.restaurantDeliveryInfo.radius;
		this.updateDeliveryInfo();
	}

	/**
	* When the location found
	* @param {any} addressData - Data of the found location
	* @param {any} placeResultData - PlaceResult object
	* @return {void}
	*/
	private getAddressData(addressData: any, placeResultData: any): void {
		this.autocompleteFilledUp = true;
		this.autofillAddress(addressData, placeResultData);
	}
}
