
import { Vue, Component, Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce';

@Component<Index>({})
export default class Index extends Vue {
	@Prop({ type: String, required: true, default: '' }) private content!: string;

	private seeMore: boolean = false;
	private maxLength: number = 200;

	/**
	 * Update max length on mounted and listen to
	 * resize event to update the max length
	 *
	 * @return {void}
	 */
	private mounted(): void {
		this.updateMaxLength(window.outerWidth);
		this.listenToResize();
	}

	/**
	 * Listen to resize event
	 *
	 * @return {void}
	 */
	private listenToResize(): void {
		window.addEventListener('resize', debounce(() => {
			this.updateMaxLength(window.outerWidth);
		}, 100));
	}

	/**
	 * Update max length of the shorten description depending
	 * on viewport width
	 *
	 * @param {number} width - viewport width
	 * @return {void}
	 */
	private updateMaxLength(width: number): void {
		if(width < 768) {
			this.maxLength = 200;
		}
		else if(width >= 768 && width < 850) {
			this.maxLength = 215;
		}
		else if(width >= 850 && width < 1024) {
			this.maxLength = 250;
		}
		else if (width >= 1024 && width < 1280) {
			this.maxLength = 280;
		}
		else if (width >= 1280 && width < 1440) {
			this.maxLength = 305;
		}
		else if (width >= 1440 && width < 1540) {
			this.maxLength = 330;
		}
		else {
			this.maxLength = 400;
		}
	}

	/**
	 * Remove resize event
	 *
	 * @return {void}
	 */
	private beforeDestroy(): void {
		window.removeEventListener('resize', this.listenToResize);
	}
}
