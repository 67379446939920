import { ActionTree } from 'vuex';
import { AuthState, RootState } from '../types';
import { state } from './auth';
import { state as suitesState } from '../suites/suites';
import { state as restaurantState } from '../restaurant/restaurant';
import { fireGoogleTag, fireGoogleTagError } from '@/utils/google-tag-manager-helpers';
import { expiredTokenErrorHandler } from '@/utils/errorHandling';
import axios from 'axios';
import Cookies from 'js-cookie';
import i18n from '@/i18n';
import router from '@/router';

export const actions: ActionTree<AuthState, RootState> = {

	setAxiosInstance({ }, restaurant: Restaurant) {
		updateAxiosInstance(restaurant.id, Cookies.get('locale') ? Cookies.get('locale') : localStorage.getItem('locale'));
	},

	setLocale({ }, locale: string): void {
		Cookies.set('locale', locale);
		localStorage.setItem('locale', locale);
		updateAxiosInstance(restaurantState.restaurant.id, locale);
	},

	/**
	 * Authenticate the logged in user with info from url
	 *
	 * @param {string} redirectOnError - route to redirect on error (usually expired token) defaults to /login
	 * @return {Promise<void>}
	 */
	async authenticate({ commit, dispatch }, redirectOnError?: string): Promise<void> {
		dispatch('validateAuthentication');

		const accessToken = Cookies.get('accessToken') ? Cookies.get('accessToken') : localStorage.getItem('accessToken');
		const userId = Cookies.get('userId') ? Cookies.get('userId') : localStorage.getItem('userId');

		// If the user has a token in its cookies, we need to get their user info
		if (accessToken && userId) {
			commit('SET_TOKEN', accessToken);
			commit('SET_USER_ID', userId);
			try {
				// If authentication fails for login as, first redirect to menu to fetch event info before redirecting to login
				await dispatch('getUserInformation', state.loginAs ? redirectOnError : undefined);
				commit('SET_AUTHENTICATED', true);
				state.loginAs ? commit('SET_LOGIN_AS_BANNER', true) : commit('SET_WELCOME_BANNER', true);
			} catch (error) {
				await dispatch('logout');
			}
		}
		else {
			await dispatch('logout');
		}
	},

	/**
	 * Authenticate the logged in suite operator with info from url
	 *
	 * @param {string} redirectOnError - route to redirect on error (usually expired token) defaults to /login
	 * @return {Promise<void>}
	 */
	async authenticateOperator({ commit, dispatch }, redirectOnError?: string): Promise<void> {
		const operatorAccessToken = Cookies.get('operatorAccessToken') ? Cookies.get('operatorAccessToken') : localStorage.getItem('operatorAccessToken');
		const operatorUserId = Cookies.get('operatorUserId') ? Cookies.get('operatorUserId') : localStorage.getItem('operatorUserId');

		// Remove any existing user data, should only be present during login as
		if (!state.loginAs) {
			commit('LOG_USER_OUT');
		}

		// If there is suite operator token and id in cookies, get their user info as well
		if (operatorAccessToken && operatorUserId) {
			commit('SET_OPERATOR_TOKEN', operatorAccessToken);
			commit('SET_OPERATOR_USER_ID', operatorUserId);
			try {
				await dispatch('getOperatorInformation', state.loginAs ? redirectOnError : undefined);
				commit('SET_AUTHENTICATED', true);
			} catch (error) {
				await dispatch('logout');
			}
		}
		else {
			await dispatch('logout');
		}
	},

	/**
	 * Get basic user info from salata of the logged in user and its payment methods
	 * If there is no user info at all, we set the checkout contact info to empty strings,
	 * readying it up for anonymous ordering.
	 *
	 * @param {string} redirectOnError - route to redirect on error (usually expired token) defaults to /login
	 * @return {Promise}
	 */
	async getUserInformation({ commit }, redirectOnError?: string): Promise<void> {
		if (state.user.token && state.user.id) {
			try {
				const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/get-salata-user-info`, {
					userId: state.user.id,
					token: state.user.token
				});
				commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
				commit('SET_USER', {
					token: state.user.token,
					id: state.user.id,
					...response.data,
					phoneNumber: response.data.phoneNumber && response.data.phoneNumber.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-')
				});
				commit('cart/UPDATE_PAYMENT_OPTION', response.data.paymentMethods, { root: true });
			} catch (error) {
				expiredTokenErrorHandler(error, redirectOnError);
				fireGoogleTagError({ name: 'gettingUserInformationError', error});
				throw i18n.t('vuex_store.cart.suites.error_getting_user_information');
			}
		}
	},

	/**
	 * Get suite operator info from salata for login as
	 *
	 * @param {string} redirectOnError - route to redirect on error (usually expired token) defaults to /login
	 * @returns {Promise<void>}
	 */
	async getOperatorInformation({ commit }, redirectOnError?: string): Promise<void> {
		if (state.suiteOperator.token && state.suiteOperator.id) {
			try {
				const operator = await state.axiosInstance.post<UserInfo>(`${process.env.VUE_APP_API_PREFIX}/auth/get-salata-user-info`, {
					userId: state.suiteOperator.id,
					token: state.suiteOperator.token
				});
				if (operator.data.suiteOperator) {
					commit('SET_SUITE_OPERATOR', {
						...operator.data,
						token: state.suiteOperator.token,
						phoneNumber: operator.data.phoneNumber && operator.data.phoneNumber.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-')
					});
				}
				else {
					throw i18n.t('vuex_store.cart.suites.error_not_operator');
				}
			} catch (error) {
				expiredTokenErrorHandler(error, redirectOnError);
				fireGoogleTagError({ name: 'gettingSuiteOperatorInformationError', error});
				throw i18n.t('vuex_store.cart.suites.error_getting_operator_information');
			}
		}
	},

	/**
	 * Get Google user info by sending the access token
	 *
	 * @param {string} accessToken
	 * @return {Promise<GoogleUserInfo>}
	 */
	async getGoogleUserInfo({}, accessToken: string): Promise<GoogleUserInfo> {
		try {
			const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/get-google-user-info`, { accessToken });
			return response.data;
		} catch (error) {
			fireGoogleTagError({ name: 'gettingGoogleUserInfoError', error});
			throw error;
		}
	},

	/**
	 * Check if the user exists in the database
	 *
	 * @param {string} email
	 * @return {boolean}
	 */
	async doesUserExist({}, email: string): Promise<boolean> {
		try {
			const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/user-exist`, { email });
			return response.data;
		} catch (error) {
			fireGoogleTagError({ name: 'doesUserExistsError', error });
			throw error;
		}
	},

	/**
	 * Sign up/in the user natively
	 *
	 * @param {LoginCredentials} credentials
	 * @return {void}
	 */
	async loginWithCredentials({ commit, dispatch }, credentials: LoginCredentials): Promise<void> {
		try {
			const response = await state.axiosInstance.post<UserInfo>(`${process.env.VUE_APP_API_PREFIX}/auth/login-native`, { ...credentials });
			commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });

			// If the user is a suite operator, we run different logic
			if (response.data.suiteOperator) {
				await dispatch('operatorLogin', { credentials: {}, operatorInfo: response.data });
			}
			else {
				commit('SET_USER', {
					...response.data,
					phoneNumber: response.data.phoneNumber && response.data.phoneNumber.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-')
				});
				commit('SET_WELCOME_BANNER', true);
				commit('SET_AUTHENTICATED', true);

				// If user is pre ordering, we try to fetch their events once more after login
				if (suitesState.preOrdering) {
					await dispatch('suites/postToGetMinimalEventInfo', suitesState.eventSuiteId, { root: true });
				}

				if (response.data.paymentMethods.length) {
					commit('cart/UPDATE_PAYMENT_METHOD', 'credit_card', { root: true });
				}
				fireGoogleTag({ name: 'loginSignup', specifier: 'email', detail: 'login' });
			}

		} catch (error) {
			fireGoogleTagError({ name: 'loginWithCredentialsError', error });
			throw error;
		}
	},

	/**
	 * Attempt to login as a suite owner
	 * First get suite operator info and set in authState
	 * Then using the suite operator token and suite owner email from suiteState, login as the suite owner
	 *
	 * @param {LoginAsCredentials} credentials
	 * @returns {Promise<void>}
	 **/
	async loginAs({ commit, dispatch }, credentials?: LoginAsCredentials): Promise<void> {
		try {
			// If we don't have a suite operator token, login operator first, then login as
			if (!state.suiteOperator.token) {
				await dispatch('operatorLogin', { credentials: credentials });
			}

			// If the user is logged in as a suite operator, continue logging in as a suite owner
			if (state.suiteOperator) {
				const eventSuiteId: number | null = credentials?.eventSuiteId || suitesState.eventSuiteId;

				if (!eventSuiteId) {
					throw i18n.t('vuex_store.cart.suites.error_no_event_suite');
				}

				// We use the suites axios instance here, because we need to fetch suite owner info for login as
				const loginAsResponse = await suitesState.suitesAxiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/login-as`, {
					eventSuiteId,
					token: state.suiteOperator.token,
					suiteOperatorEmail: state.suiteOperator.email,
				});

				commit('SET_USER', {
					...loginAsResponse.data,
					phoneNumber: loginAsResponse.data.phoneNumber && loginAsResponse.data.phoneNumber.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-'),
				});

				commit('SET_AUTHENTICATED', true);
				commit('SET_LOGIN_AS', true);
				commit('SET_LOGIN_AS_BANNER', true);

				if (suitesState.preOrdering || suitesState.eventDayOrdering) {
					await dispatch('suites/setSelectedEventSuiteId', eventSuiteId, { root: true })
				}

				if (loginAsResponse.data.paymentMethods.length) {
					commit('cart/UPDATE_PAYMENT_METHOD', 'credit_card', { root: true });
				}
				fireGoogleTag({ name: 'loginAs', specifier: 'email', detail: 'login' });
			}
			else {
				throw i18n.t('vuex_store.cart.suites.error_not_operator');
			}
		}
		catch (error) {
			fireGoogleTagError({ name: 'loginAsError', error });
			throw error;
		}
	},

	/**
	 * Login as a suite operator
	 *
	 * @param {Object} payload
	 * @param {LoginCredentials} payload.credentials
	 * @param {UserInfo} payload.operatorInfo
	 * @returns {Promise<void>}
	 **/
	async operatorLogin({ commit }, payload: { credentials?: LoginCredentials, operatorInfo?: UserInfo }): Promise<void> {
		try {
			let { operatorInfo } = payload;
			if (!operatorInfo) {
				const response = await state.axiosInstance.post<UserInfo>(`${process.env.VUE_APP_API_PREFIX}/auth/login-native`, payload.credentials);
				operatorInfo = response.data;
			}
			if (operatorInfo.suiteOperator) {
				commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
				commit('SET_SUITE_OPERATOR', { ...operatorInfo });
				commit('SET_AUTHENTICATED', true);
				commit('cart/UPDATE_PAYMENT_METHOD', null, { root: true });

				fireGoogleTag({ name: 'operatorLogin', specifier: 'email', detail: 'login' });
			}
			else {
				throw i18n.t('vuex_store.cart.suites.error_not_operator');
			}
		} catch (error) {
			fireGoogleTagError({ name: 'operatorLoginError', error });
			throw error;
		}
	},

	/**
	 * Sign up/in the user natively
	 *
	 * @param {RegisterUserPayload} userCredentialsAndInfo
	 * @return {void}
	 */
	async registerUser({ commit }, userCredentialsAndInfo: RegisterUserPayload): Promise<void> {
		try {
			const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/register-user`, userCredentialsAndInfo);
			if (!userCredentialsAndInfo.registerAtCheckout) {
				commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
			}
			commit('SET_USER', response.data);
			commit('SET_AUTHENTICATED', true);
			commit('SET_WELCOME_BANNER', false);

			if (response.data.paymentMethods.length) {
				commit('cart/UPDATE_PAYMENT_METHOD', 'credit_card', { root: true });
			}
		} catch (error) {
			fireGoogleTagError({ name: 'registerUserError', error });
			throw error;
		}
	},

	/**
	 * Login or register the user through its social logins
	 *
	 * @param {SocialLoginOrRegisterPayload} payload
	 * @return {Promise<void>}
	 */
	async socialLoginOrRegister({ commit }, { type, payload }: SocialLoginOrRegisterPayload): Promise<void> {
		try {
			const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/login-${type}`, payload);
			commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
			commit('SET_USER', response.data);
			commit('SET_AUTHENTICATED', true);
			commit('SET_WELCOME_BANNER', payload.register ? false : true);

			if (response.data.paymentMethods.length) {
				commit('cart/UPDATE_PAYMENT_METHOD', 'credit_card', { root: true });
			}

			fireGoogleTag({ name: payload.register ? 'signupSucces' : 'loginSignup', specifier: type, detail: payload.register ? undefined : 'login' })
		} catch (error) {
			fireGoogleTagError({ name: 'socialLoginOrRegisterError', error, specifier: type });
			throw error;
		}
	},

	/**
	 * Edit the user contact info
	 *
	 * @param {EditUserContactInfoPayload} payload
	 * @return {Promise<void>}
	 */
	async editUser({ commit }, payload: EditContactInfoPayload): Promise<void> {
		try {
			const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/edit-user`, payload);
			commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
			commit('EDIT_USER', response.data);
			fireGoogleTag({ name: 'editUser'});
		} catch (error) {
			expiredTokenErrorHandler(error);
			throw error;
		}
	},

	/**
	 * Add payment method to the user
	 *
	 * @param {AddPaymentMethodPayload} payload
	 * @return {Promise<void>}
	 */
	async addPaymentMethod({ commit }, payload: AddPaymentMethodPayload): Promise<void> {
		try {
			const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/payment-method`, payload);
			commit('SET_PAYMENT_METHODS', response.data);

			// If we are saving this payment method through the checkout, we do not want to reset the checkout info
			if(!payload.savingFromCheckout) {
				commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
			}

			commit('cart/UPDATE_PAYMENT_METHOD', 'credit_card', { root: true });
			fireGoogleTag({ name: 'addPaymentMethod'});
		} catch (error) {
			expiredTokenErrorHandler(error);
			throw error;
		}
	},

	/**
	 * Update payment method
	 *
	 * @param {AddPaymentMethodPayload} payload
	 * @return {Promise<void>}
	 */
	async updatePaymentMethod({ commit }, payload: AddPaymentMethodPayload): Promise<void> {
		try {
			const response = await state.axiosInstance.put(`${process.env.VUE_APP_API_PREFIX}/auth/payment-method`, payload);
			commit('SET_PAYMENT_METHODS', response.data);
			commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
			commit('cart/UPDATE_PAYMENT_METHOD', 'credit_card', { root: true });
			fireGoogleTag({ name: 'editPaymentMethod'});
		} catch (error) {
			expiredTokenErrorHandler(error);
			throw error;
		}
	},

	/**
	 * Delete payment method
	 *
	 * @param {DeletePaymentMethodPayload} payload
	 * @return {Promise<void>}
	 */
	async deletePaymentMethod({ commit }, payload: DeletePaymentMethodPayload): Promise<void> {
		try {
			const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/delete-payment`, payload);
			commit('SET_PAYMENT_METHODS', response.data);
			commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
			commit('cart/UPDATE_PAYMENT_METHOD', 'credit_card', { root: true });
			fireGoogleTag({ name: 'deletePaymentMethod'});
		} catch (error) {
			expiredTokenErrorHandler(error);
			throw error;
		}
	},

	/**
	 * Log out user and/or suite operator by clearing state, cookies, local storage
	 * and invalidating App8 token on Salata
	 *
	 * @return {Promise<void>}
	 */
	async logout({ commit, dispatch }): Promise<void> {
		try {
			if (state.user.token) {
				await dispatch('logoutUser');
			}
			if (state.suiteOperator.token) {
				await dispatch('logoutOperator');
			}
		} catch (error) {
			commit('LOG_USER_OUT');
			commit('LOG_OPERATOR_OUT');
			commit('cart/CLEAR_ITEMS_AND_CARD_INFO', { root: true });
			commit('filters/CLEAR_SELECTED_FILTERS', { root: true });
			router.replace({ path: '/login', query: router.currentRoute.query });
			throw error;
		}
	},

	/**
	 * Log out user by clearing state, cookies, local storage
	 * and invalidating App8 token on Salata
	 *
	 * @return {Promise<void>}
	 */
	async logoutUser({ commit }): Promise<void> {
		try {
			await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/logout`, { accessToken: state.user.token });
			commit('LOG_USER_OUT');
			commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
			fireGoogleTag({ name: 'logout'});
		} catch (error) {
			expiredTokenErrorHandler(error);
			throw error;
		}
	},

	/**
	 * Log out suite operator by clearing state, cookies, local storage
	 * and invalidating App8 token on Salata
	 *
	 * @return {Promise<void>}
	 */
	async logoutOperator({ commit }): Promise<void> {
		try {
			await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/logout`, { accessToken: state.suiteOperator.token });
			commit('LOG_OPERATOR_OUT');
			commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', null, { root: true });
			fireGoogleTag({ name: 'operatorLogout'});
		} catch (error) {
			expiredTokenErrorHandler(error);
			throw error;
		}
	},

	/**
	 * Log out user and clear suite info then reauthenticate the operator
	 *
	 * @return {Promise<void>}
	 */
	async suiteLogout({ commit, dispatch }): Promise<void> {
		await dispatch('logoutUser');
		Cookies.remove('suitesLocationId');
		commit('suites/RESET_SUITE_INFO', null, { root: true });
		await dispatch('authenticateOperator');
	},


	/**
	 * Trigger email to reset password
	 *
	 * @param {string} email
	 * @return {Promise<void>}
	 */
	async forgotPassword({}, email: string): Promise<void> {
		try {
			await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/forgot-password`, { email });
			fireGoogleTag({ name: 'forgotPassword'});
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Check for membership cookie for the respective restaurant.
	 * If cookie, dispatch to validate it
	 *
	 * @return {Promise<void>}
	 */
	async getMemberCookie({ dispatch }): Promise<void> {
		const member = Cookies.get(`${restaurantState.restaurant.slug}-member`);
		if(member) {
			await dispatch('checkIfMemberAndApplyPromo', member);
		}
	},

	/**
	 * Check if the user is part of the membership program of the respective restaurant.
	 * If the user is a part of, we set the member information with its info and
	 * handle the payload returned (contact info, promos, address, etc)
	 *
	 * @param {string} identifier
	 * @return {Promise<any>}
	 */
	async checkIfMemberAndApplyPromo({ dispatch, commit }, identifier: string): Promise<any> {
		const payload = {
			identifier,
			app8RestaurantId: restaurantState.restaurant.app8_restaurant
		};
		return await new Promise((resolve, reject) => {
			state.axiosInstance
				.post(`${process.env.VUE_APP_API_PREFIX}/auth/get-user-membership-promo`, payload)
				.then(response => {

					// We don't wanna populate the member object with an empty response
					if(response.data && response.data.id) {
						commit('SET_MEMBER_INFO', response.data);
					}

					fireGoogleTag({
						name: 'memberApplied'
					});
					dispatch('search/updateSearchInput', {}, { root: true });
					resolve(response.data);
				})
				.catch(error => {
					Cookies.remove(`${restaurantState.restaurant.slug}-member`);

					fireGoogleTagError({ specifier: 'memberApplied', error });
					reject(error);
				});
		});
	},

	/**
	 * Get past receipts of the user
	 *
	 * @param {UserIdentifier} payload
	 * @return {Promise<ReceiptInfo[]>}
	 */
	async getPastReceipts({}, payload: UserIdentifier): Promise<ReceiptInfo[]> {
		try {
			const response = await state.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/auth/get-past-receipts`, payload);
			fireGoogleTag({ name: 'viewPastReceipts'});
			return response.data;
		} catch (error) {
			expiredTokenErrorHandler(error);
			throw error;
		}
	},

	/**
	 * Get the last items ordered by the user
	 *
	 * @param {UserIdentifier} payload
	 * @return {Promise<void>}
	 */
	async getLastItemsOrdered({ commit }, payload: UserIdentifier): Promise<void> {
		try {
			const { data } = await state.axiosInstance.post<LastOrderedItem[]>(`${process.env.VUE_APP_API_PREFIX}/auth/get-previously-ordered-items`, payload);
			commit('SET_LAST_ITEMS_ORDERED', data);
		} catch (error) {
			expiredTokenErrorHandler(error);
			throw error;
		}
	},

	/**
	 * Hide welcome user banner
	 *
	 * @return {void}
	 */
	removeWelcomeBanner({ commit }): void {
		commit('SET_WELCOME_BANNER', false);
	},

	/**
	 * If not logging in as validate that user login is not happeing via url manipulation and clear any existing suite operator data
	 *
	 * @return {void}
	 */
	validateAuthentication({ commit }) {
		// Remove any existing suite operator data, should only be present during login as
		if (!state.loginAs) {
			const operatorAccessToken = Cookies.get('operatorAccessToken') ? Cookies.get('operatorAccessToken') : localStorage.getItem('operatorAccessToken');
			const operatorUserId = Cookies.get('operatorUserId') ? Cookies.get('operatorUserId') : localStorage.getItem('operatorUserId');

			// If there is a suite operator token in cookies/local storage when not logging in as, we need to log the user out
			// This prevents accessing the user's account without appearing logged in as, by manipulating the query params after logging in as the user
			if (operatorAccessToken || operatorUserId) {
				commit('LOG_USER_OUT');
				commit('SET_AUTHENTICATED', false);
			}
			commit('LOG_OPERATOR_OUT');
		}
	}
};

/**
 * Update axios instance when getting the tenantId
 *
 * @param {string} tenantId
 * @param {string|any} locale
 * @return {void}
 */
function updateAxiosInstance(tenantId: string, locale: string|any): void {
	state.axiosInstance = axios.create({
		headers: locale ? { 'X-RESTAURANT-ID': tenantId, 'Locale': locale } : { 'X-RESTAURANT-ID': tenantId }
	});
}
