import { ActionTree } from 'vuex';
import { SearchState, RootState } from '../types';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';

export const actions: ActionTree<SearchState, RootState> = {
	updateSearchInput({ commit }, searchInput: string) {
		commit('UPDATE_SEARCH_INPUT', searchInput);
	},

	updateResultItems({ commit }, items: MenuItem[]) {
		fireGoogleTag({ name: 'search' });
		commit('UPDATE_RESULT_ITEMS', items);
	}
};