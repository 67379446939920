
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { handleAllErrors } from '@/utils/errorHandling';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import Receipt from '@/components/profile/Receipt.vue';

const namespace: string = 'auth';

@Component<PastReceipts>({
	components: {
		ArrowLeftIcon,
		Receipt
	}
})
export default class PastReceipts extends Vue {
	@Action('getPastReceipts', { namespace }) private getPastReceipts!: (payload: UserIdentifier) => Promise<ReceiptInfo[]>;
	@Getter('getUser', { namespace }) private user!: UserInfo;
	@Getter('showLoginAsBanner', { namespace }) private showLoginAsBanner!: boolean;

	private receipts: ReceiptInfo[] = [];

	private dataFetched: boolean = false;
	private isAnyLoading: boolean = false;

	private mounted() {
		this.getReceipts();
	}

	/**
	 * Redirect to the profile page
	 *
	 * @return {void}
	 */
	private toProfile(): void {
		this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {});
	}

	/**
	 * Fetch past receipts of the user
	 *
	 * @return {Promise<void>}
	 */
	private async getReceipts(): Promise<void> {
		try {
			const receipts = await this.getPastReceipts({ userId: this.user.id, token: this.user.token });
			this.receipts = receipts;
		} catch (error) {
			handleAllErrors(this.$t('profile.past_receipts.error_fetch_receipts'), error);
		} finally {
			this.dataFetched = true;
		}
	}
}
