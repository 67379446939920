
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<MenuCardVertical>({})

export default class MenuCardVertical extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private menuItem!: MenuItem;
	@Prop({ type: Boolean, required: false, default: false }) private showImage!: boolean;
	@Prop({ type: String, required: false, default: null }) private imageURL!: string;
	@Prop({ type: String, required: false, default: null }) private formattedDescription!: string;
}
