
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { shuffle } from 'lodash';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import CartItemList from './CartItemList.vue';
import CartUpsellItemList from './CartUpsellItemList.vue';
import CartPricing from './CartPricing.vue';
import CartSuiteTab from './CartSuiteTab.vue';
import BannerContent from '@/components/shared/BannerContent.vue';
import Modal from '@/components/shared/Modal.vue';
import ErrorValidationInformation from '@/components/shared/ErrorValidationInformation.vue';
import MenuItemViewer from '@/components/menu/MenuItemViewer.vue';

const namespace: string = 'cart';

@Component<CartViewer>({
	components: {
		ArrowLeftIcon,
		BannerContent,
		CartPricing,
		CartItemList,
		CartUpsellItemList,
		CartSuiteTab,
		Modal,
		ErrorValidationInformation,
		MenuItemViewer
	}
})
export default class CartViewer extends Vue {
	@Prop({ type: Boolean, required: true, default: false }) private displayChangePreOrderButton!: boolean;
	@Action('validateCart', { namespace }) private validateCart!: () => void;
	@Action('updateValidationError', { namespace }) private updateValidationError!: (error: object) => void;
	@Action('setCosts', { namespace }) private setCosts!: (specifier: string) => void;
	@Getter('getItems', { namespace }) private items!: MenuItem;
	@Getter('getSubtotal', { namespace }) private subtotal!: string;
	@Getter('getPromoDiscount', { namespace }) private promoDiscount!: string;
	@Getter('getMemberDiscount', { namespace }) private memberDiscount!: string;
	@Getter('getServiceCharge', { namespace }) private serviceCharge!: string | null;
	@Getter('getDeliveryCharge', { namespace }) private deliveryCharge!: string | null;
	@Getter('getTaxes', { namespace }) private taxes!: string;
	@Getter('getVoucherAmount', { namespace }) private voucherAmount!: string;
	@Getter('getTotal', { namespace }) private total!: string;
	@Getter('getTip', { namespace }) private tip!: string;
	@Getter('getTotalWithTip', { namespace }) private totalWithTip!: string;
	@Getter('getLegalCheck', { namespace }) private legalChecked!: boolean;
	@Getter('isEventDayOrdering', { namespace: 'suites' }) private isEventDayOrdering!: boolean;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean
	@Getter('isLoginAs', { namespace: 'auth' }) private isLoginAs!: boolean;
	@Getter('getFullName', { namespace: 'auth' }) private fullName!: string;
	@Getter('getFilteredMenus', { namespace: 'restaurant' }) private filteredMenus!: Menu[];
	@Getter('getMaxItemsPerCart', { namespace: 'restaurant' }) private maxItemsPerCart!: number;
	@Getter('isMarketplaceHub', { namespace: 'restaurant' }) private isMarketplaceHub!: boolean;

	$refs!: { cartPricing: CartPricing };

	private cartErrorModalOpened: boolean = false;
	private showItemDetails: boolean = false;
	private legalCheckRequired: boolean = false;
	private upsellItemId: number|null = null;
	private legalCheckedHandler: Function | null = null;

	/**
	 * Logged in as banner properties
	 *
	 * @return {BannerItem}
	 */
	private get loggedInAsBanner(): BannerItem {
		return {
			name: 'login-as',
			type: 'info',
			show: this.isLoginAs,
			message: this.$t('menu.logged_in_as_banner'),
			action: {
				text : this.fullName,
				onClick : () => this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {})
			}
		}
	}

	/**
	 * Check if the cart is full
	 *
	 * @return {boolean}
	 */
	private get isCartFull(): boolean {
		if (!this.maxItemsPerCart) {
			return false;
		}
		const itemsInCart: number = this.items.reduce((sum: number, item: MenuItem) => sum += item.quantity, 0);
		return itemsInCart >= this.maxItemsPerCart;
	}

	/**
	 * Check through each menu and section items to find the upsell items,
	 * and filter out the items that are already in the cart.
	 *
	 * @return {MenuItem[]}
	 */
	 private get upsellItems(): MenuItem[] {
		const allUpsellItems = this.filteredMenus.flatMap(menu => {
			const upsellMenuItems = menu.items.filter(item => item.upsell_item && !item.sold_out && !item.unavailable);
			const upsellSectionItems = menu.sections.flatMap(section => section.items.filter(item => item.upsell_item && !item.sold_out && !item.unavailable));

			return [...upsellMenuItems, ...upsellSectionItems];
		});

		const filteredUpsellItems = allUpsellItems.filter(item => !this.items.find((cartItem: MenuItem) => cartItem.id === item.id));

		if (filteredUpsellItems.length <= 3) {
			return filteredUpsellItems;
		}

		return shuffle(filteredUpsellItems).slice(0, 3);
	}

	/**
	 * Go to the checkout view
	 *
	 * @return {void}
	 */
	private showCheckout(): void {
		this.$emit('checkout');
	}

	/**
	 * Send event to the parent
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}

	/**
	 * Validate the cart with the API to check many caveats
	 * (IE: schedule, different menus availability, sold_out, etc)
	 *
	 * @return {Promise<void>}
	 */
	private async validate(): Promise<void> {
		try {
			await this.validateCart();
			this.showCheckout();
		} catch (err) {
			this.cartErrorModalOpened = true;
		} finally {
			if (!this.cartErrorModalOpened) {
				this.closeModal();
			}
		}
	}

	/**
	 * Close the validation error modal.
	 * If the legal check was required and the user has checked it, add the item to the cart.
	 *
	 * @return {void}
	 */
	private closeCartErrorModal(): void {
		this.cartErrorModalOpened = false;
		if (this.legalCheckRequired && this.legalChecked && this.legalCheckedHandler) {
			this.legalCheckedHandler();
		}
		this.legalCheckRequired = false;
		this.legalCheckedHandler = null;
	}

	/**
	 * Show the upsell item details modal
	 *
	 * @param {number} id - The item id
	 * @return {void}
	 */
	private showItem(id: number): void {
		this.showItemDetails = true;
		this.upsellItemId = id;
	}

	/**
	 * Open the legal check modal
	 *
	 * @param {Function} onLegalCheckedHandler - Function to trigger if legal gets checked
	 * @return {void}
	 */
	private openLegalCheckModal(onLegalCheckedHandler: Function): void {
		this.cartErrorModalOpened = true;
		this.legalCheckRequired = true;
		this.legalCheckedHandler = onLegalCheckedHandler;
		this.updateValidationError({
			message: this.$t('menu.item_viewer.alcohol_legal_warning')
		});
	}

	/**
	 * Update the cart costs after an upsell item is added to the cart.
	 *
	 * @return {void}
	 */
	private updateCosts(): void {
		const cartPricing = this.$refs.cartPricing;
		cartPricing.getCosts?.();
	}
}
