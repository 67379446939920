
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component<Index>({})
export default class Index extends Vue {
	@Action('getCookies', { namespace: 'cart' }) private getCookies!: () => Promise<void>;
	@Watch('$route') private onRouteChange(): void {
		this.url = window.location.href;
	}

	private url: string = window.location.href;

	/**
	 * Fetch the cookies
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		await this.getCookies();
	}
}
