
import { Vue, Prop, Component } from 'vue-property-decorator';
import InfoIcon from 'vue-feather-icons/icons/InfoIcon';

@Component<InfoBanner>({
	components: {
		InfoIcon
	}
})

export default class InfoBanner extends Vue {
	@Prop({ type: String, required: true, default: '' }) private message!: string;
}
