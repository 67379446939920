
import { Component, Vue } from 'vue-property-decorator';
import { changeFavicon } from '@/utils/styling';
import VueMeta from 'vue-meta';
import concoursesList from '../concourses.data.json';
import SearchIcon from '../assets/images/icons/search.svg?inline';
import axios from 'axios';
import 'vue-select/dist/vue-select.css';

Vue.use(VueMeta);

type ConcourseItem = {
	id: number,
	name: string,
	address: string,
	url: string,
	label?: string
}

@Component<Concourses>({
	components: { SearchIcon },
	metaInfo() {
		changeFavicon(`${process.env.VUE_APP_IMAGE_BUCKET}/a-table/favicon.png`);

		const restaurantName: string = 'App8 Restaurant';
		return {
			title: (this.$t('menu.meta.page_title', { restaurantName })) as string,
			meta: [
				{ charset: 'utf-8' },
				{ name: 'keyword', content: this.$t('menu.meta.keyword', { restaurantName }) },
				{ name: 'description', vmid: 'description', content: this.$t('menu.meta.description') },
				{ name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
				{ property: 'og:title', content: this.$t('menu.meta.title', { restaurantName }) },
				{ property: 'og:description', content: this.$t('menu.meta.title', { restaurantName }) },
				{ property: 'og:site_name', content: this.$t('menu.meta.title', { restaurantName }) },
				{ property: 'og:type', content: 'Website' },
				{ property: 'twitter:title', content: this.$t('menu.meta.title', { restaurantName }) },
				{ property: 'twitter:description', content: this.$t('menu.meta.title', { restaurantName }) }
			]
		};
	}
})
export default class Concourses extends Vue {
	private concourses: ConcourseItem[] = [];
	private searchField: ConcourseItem|null = null;

	private get aTableLogo(): string {
		return `${process.env.VUE_APP_IMAGE_BUCKET}/a-table/a-table-logo.png`;
	}

	private get aTableHeroImage(): string {
		return `${process.env.VUE_APP_IMAGE_BUCKET}/a-table/hero-background.png`;
	}

	private get aTableContentImage(): string {
		return `${process.env.VUE_APP_IMAGE_BUCKET}/a-table/content-image.png`;
	}

	private get concoursesWithLabel(): ConcourseItem[] {
		return this.concourses.map(concourse => {
			return {
				...concourse,
				label: concourse.name + ' ' + concourse.address
			}
		})
	}

	private async created(): Promise<void> {
		if(process.env.NODE_ENV === 'development') {
			this.concourses = concoursesList;
		}
		else {
			const url: string = window.location.href;
			if (!url.includes('a-table.ca')) {
				this.$router.replace({ name: 'PageNotFound' }).catch(() => {});
			}
			const response = await axios.get<ConcourseItem[]>(`${window.location.origin}/a-table/concourses.data.json`);
			this.concourses = response.data;
		}
	}

	/**
	 * Redirect to the selected concourse
	 *
	 * @param {ConcourseItem} selectedConcourse
	 * @return {void}
	 */
	private selectConcourse(selectedConcourse: ConcourseItem): void {
		window.location.href = selectedConcourse.url;
	}
}
