import { ActionTree } from 'vuex';
import { RootState, SuitesState } from '../types';
import { state } from './suites';
import { state as cartState } from '../cart/cart';
import { state as restaurantState } from '../restaurant/restaurant';
import { state as authState } from '../auth/auth';
import { fireGoogleTagError } from '@/utils/google-tag-manager-helpers';
import Cookies from 'js-cookie';
import axios from 'axios';
import router from '@/router';
import i18n from '@/i18n';

export const actions: ActionTree<SuitesState, RootState> = {
	/**
	 * Set the suites location id and update the axios instance for suites requests
	 * Falls back to the restaurant id if no location id is provided or not found in cookies
	 *
	 * @param {number|null} locationId
	 * @return {void} 
	 */
	setSuitesLocationId({ commit }, locationId: string | null): void {
		const suitesLocationId = locationId || Cookies.get('suitesLocationId') || restaurantState.restaurant.id;
		commit('SET_SUITES_LOCATION_ID', suitesLocationId);
		updateSuitesAxiosInstance(suitesLocationId, Cookies.get('locale') ? Cookies.get('locale') : localStorage.getItem('locale'));
	},

	/**
	 * Get the minimal event information
	 * If the user is already logged in, we grab their event suites info as well
	 *
	 * @param {number} eventSuiteId
	 * @return {Promise<void>}
	 */
	async postToGetMinimalEventInfo({ commit, dispatch }, eventSuiteId: number): Promise<void> {
		try {
			// Check query params for encrypted email and add it to the payload if it exists
			const encryptedEmail = router.currentRoute.query.value as string;
			const payload = encryptedEmail ? { eventSuiteId, encryptedValue: encryptedEmail } : { eventSuiteId };
			const minimalEventInfo = await state.suitesAxiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/catering/events/get-minimal-info`, payload);

			commit('SET_MINIMAL_EVENT_INFO', minimalEventInfo.data);
			if(authState.authenticated) {
				// Double check that we properly fetched the user's info
				if (authState.user.email) {
					await dispatch('getUserUpcomingEvents');

					// Find the event suite info and set the info & suite catering order, if there is one.
					const userEventSuiteInfo = state.upcomingEvents.find((event: UserEvent) => event.id === eventSuiteId);

					// User is not a suite owner for this event suite
					if(!userEventSuiteInfo) {
						throw(i18n.t('vuex_store.cart.suites.invalid_suite_owner'));
					}

					commit('SET_SELECTED_USER_EVENT_SUITE', userEventSuiteInfo);
					if(userEventSuiteInfo && userEventSuiteInfo.orders && userEventSuiteInfo.orders.length) {
						dispatch('setSuiteCateringOrder', userEventSuiteInfo.orders[0]);
					}
				}
				else {
					throw(i18n.t('vuex_store.cart.suites.invalid_suite_owner'));
				}
			}
			if (!state.eventDayOrdering) {
				dispatch('setPreOrderingFlag', true);
			}
		} catch (e) {
			// Reset the table number to avoid someone ordering as dine-in to a suite name
			cartState.config.tableNum = '';
			const error: any = e;
			fireGoogleTagError({ name: 'gettingMinimalEventInfoError', error});
			await dispatch('auth/logout', null, { root: true });
			router.push({ path: authState.suiteOperatorLogin ? '/login' : `/${restaurantState.restaurant.slug}/landing`, query: router.currentRoute.query }).catch(() => {});
			throw error;
		}
	},

	/**
	 * Sends request to an endpoint that requires user to be logged in as a suite operator to get the event info
	 * Will return a 403 otherwise
	 *
	 * @param {object} payload
	 * @param {number} payload.eventId - The event id
	 * @param {string} payload.userId - The suite operator's user id
	 * @param {string} payload.token - The suite operator's token
	 */
	async suiteOpGetEventInfo({ commit }, payload: { eventId: number, userId: string, token: string }): Promise<void> {
		try {
			const eventInfo = await state.suitesAxiosInstance.post<EventInfo>(`${process.env.VUE_APP_API_PREFIX}/catering/events/auth-get-event-info`, payload);
			commit('SET_SELECTED_EVENT', eventInfo.data);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get user upcoming events
	 *
	 * @return {Promise<void>}
	 */
	async getUserUpcomingEvents({ commit }): Promise<void> {
		try {
			const response = await state.suitesAxiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/catering/suite-owners/get-upcoming-events`, { email: authState.user.email });
			commit('SET_USER_UPCOMING_EVENTS', response.data)
		} catch (error) {
			fireGoogleTagError({ name: 'gettingUserUpcomingEventsError', error});
			throw error;
		}
	},

	/**
	 * Get all active events for today
	 *
	 * @return {Promise<void>}
	 */
	async getLocationGroupEvents({ commit }): Promise<void> {
		try {
			const response = await authState.axiosInstance.get(`${process.env.VUE_APP_API_PREFIX}/catering/events/get-active-events`);
			commit('SET_ACTIVE_EVENTS', response.data);
		} catch (error) {
			fireGoogleTagError({ name: 'gettingActiveEventsError', error});
			throw error;
		}
	},

	/**
	 * Update the event day suite settings (payment preference and tab limit) for an event suite
	 * Requires user to be logged in as a suite operator, will return a 403 otherwise
	 *
	 * @param {EventDaySettings} payload
	 * @param {EventSuite} payload.eventSuite - The event id
	 * @param {string} payload.userId - The suite operator's user id
	 * @param {string} payload.token - The suite operator's token
	 * @return {Promise<void>}
	 */
	async suiteOpUpdateEventDaySettings({ commit }, payload: EventDaySettings): Promise<void> {
		const { eventSuite, userId, token } = payload;
		try {
			// Create payload for update request
			const requestPayload = {
				userId,
				token,
				event_day_payment_preference: eventSuite.event_day_payment_preference,
				tab_limit: eventSuite.tab_limit,
				order_restaurant_id: restaurantState.restaurant.id
			};
			const { data } = await state.suitesAxiosInstance.put(`${process.env.VUE_APP_API_PREFIX}/catering/events/${eventSuite.event.id}/event-suites/${eventSuite.id}/update-event-day-settings`, requestPayload);
			commit('SET_SELECTED_USER_EVENT_SUITE', data);
		} catch (error) {
			fireGoogleTagError({ name: 'updatingEventSuiteError', error});
			throw error;
		}
	},

	/**
	 * Set the selected event suite and get the minimal event info
	 *
	 * @param {number} userEventSuite
	 * @return {Promise<void>}
	 */
	async setSelectedEventSuiteId({ commit, dispatch }, eventSuiteId: number): Promise<void> {
		commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', {}, { root: true });
		state.suiteCateringOrder = null;
		commit('SET_SELECTED_EVENT_SUITE_ID', eventSuiteId);
		await dispatch('postToGetMinimalEventInfo', eventSuiteId);
	},

	/**
	 * We reset the cart just in case the user started to do some random ordering before.
	 * Set the suite catering order.
	 *
	 * @param {EventSuiteOrder} suiteCateringOrder
	 * @return {void}
	 */
	setSuiteCateringOrder({ commit }, suiteCateringOrder: EventSuiteOrder): void {
		commit('SET_SUITE_CATERING_ORDER', suiteCateringOrder);
	},


	/**
	 * We reset the cart just in case the user started to do some random ordering before.
	 * Set the pre-order cart. Logic is ran here to grab the relevant information and update the
	 * order accordingly for the cart to be setup as an order in progress.
	 *
	 * @return {Promise<void>}
	 */
	async setPreOrderCart({ commit }): Promise<void> {
		commit('cart/RESET_CHECKOUT_PAYMENT_INFO_AND_ITEMS', {}, { root: true });
		if(state.suiteCateringOrder && state.suiteCateringOrder.data) {
			commit('SET_PRE_ORDER_CART', state.suiteCateringOrder);
		}
	},

	/**
	 * Set the pre-ordering flag
	 *
	 * @param {boolean} preOrdering
	 * @return {void}
	 */
	setPreOrderingFlag({ commit }, preOrdering: boolean): void {
		commit('SET_PRE_ORDERING_FLAG', preOrdering);
	},

	/**
	 * Set the event day ordering flag
	 *
	 * @param {boolean} eventDayOrdering
	 * @return {void}
	 */
	setEventDayOrderingFlag({ commit }, eventDayOrdering: boolean): void {
		commit('SET_EVENT_DAY_ORDERING_FLAG', eventDayOrdering);
	},
};

/**
 * Update axios instance for suite location
 *
 * @param {string} tenantId
 * @param {string|any} locale
 * @return {void}
 */
function updateSuitesAxiosInstance(tenantId: string, locale: string|any): void {
	state.suitesAxiosInstance = axios.create({
		headers: locale ? { 'X-RESTAURANT-ID': tenantId, 'Locale': locale } : { 'X-RESTAURANT-ID': tenantId }
	});
}
