
import { Vue, Component, Prop } from 'vue-property-decorator';
import CartItem from '@/components/cart/CartItem.vue';

@Component<CartItemList>({
	components: {
		CartItem
	}
})

export default class CartItemList extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private items!: OrderItem[];
	@Prop({ type: Boolean, required: true, default: false }) private canEditItems!: boolean;
}
