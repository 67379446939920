
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { VueMasonryPlugin } from 'vue-masonry';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
import debounce from 'lodash/debounce';
import Cart from '@/components/Cart.vue';
import MenuDropdown from '@/components/navigation/MenuDropdown.vue';
import LocaleSwitcher from '@/components/navigation/LocaleSwitcher.vue';
import ProfileButton from '@/components/navigation/ProfileButton.vue'
import SearchBar from '@/components/filter/SearchBar.vue';
import FilterIcon from '../../assets/images/icons/filter.svg?inline';
import GroceryIcon from '../../assets/images/icons/grocery.svg?inline';

const namespace: string = 'search';

Vue.use(VueMasonryPlugin);

@Component<Navbar>({
	components: {
		Cart,
		MenuDropdown,
		LocaleSwitcher,
		ProfileButton,
		FilterIcon,
		SearchBar,
		GroceryIcon
	}
})
export default class Navbar extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private menus!: Menu[];
	@Prop({ type: Array, required: true, default: () => [] }) private elements!: Menu[] | MenuGroup[];
	@Prop({ type: String, required: true, default: '' }) private elementSelected!: string;
	@Prop({ type: String, required: false, default: '' }) private searchText!: string;
	@Prop({ type: String, required: false, default: '' }) private restaurantDisplayName!: string;
	@Prop({ type: String, required: false, default: '' }) private restaurantImage!: string;
	@Prop({ type: Number, required: true, default: 0 }) private currentElementIndex!: number;
	@Prop({ type: Boolean, required: false }) private openCartModal!: boolean;
	@Action('updateSearchInput', { namespace }) private updateSearchInput!: (searchText: string) => void;
	@Getter('getSearchInput', { namespace }) private searchInput!: string;
	@Getter('isViewOnly', { namespace: 'cart' }) private isViewOnly!: boolean;
	@Getter('isAnyDietOrAllergen', { namespace: 'filters' }) private isAnyDietOrAllergen!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('isMarketplaceHub', { namespace: 'restaurant' }) private isMarketplaceHub!: boolean;

	private limit: number = 10;
	private menuNavOverflowing: { bool: boolean; width: number } = { bool: false, width: 0 };
	private selected: string = 'Default';

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.restaurantImage}`;
		}
		return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.restaurantImage}`;
	}

	/**
	 * Start to listen for the overflow of menu or menu groups on component
	 * mount
	 *
	 * @return {void}
	 */
	private mounted() {
		this.checkOverflowOnLoad();
	}

	/**
	 * Remove the resize event
	 *
	 * @return {void}
	 */
	private beforeDestroy(): void {
		window.removeEventListener('resize', this.listenToResizeToCheckOverflow);
	}

	/**
	 * Gather the menus or menu groups for the ul
	 *
	 * @return {menu[]}
	 */
	private get elementList(): Menu[] | MenuGroup[] {
		return this.elements.slice(0, this.limit);
	}

	/**
	 * Send event to parent to open the filter side panel
	 *
	 * @return {void}
	 */
	private openFilterSelector(): void {
		this.$emit('open-filter-selector');
	}

	/**
	 * Send event to change the view depending on menu or menu group selected
	 *
	 * @param {number} idx
	 * @param {string} elementName
	 * @return {void}
	 */
	private selectElement(idx: number, elementName: string): void {
		// We empty the search if not already to change menu or menu group
		if(this.searchInput) {
			this.updateSearchInput('');
		}
		this.$emit('select-element', { 'idx': idx, 'elementName': elementName });
		fireGoogleTag({ name: 'menuSelected', detail: elementName });
	}

	/**
	 * Check if numbers of menus or menu groups overflows to crop them into a dropdown
	 * and add an event listener to check on resize if it overflows.
	 *
	 * @return {void}
	 */
	private checkOverflowOnLoad(): void {
		this.checkOverflow();
		this.listenToResizeToCheckOverflow();
	}

	/**
	 * Check overflow every 100ms
	 *
	 * @return {void}
	 */
	private listenToResizeToCheckOverflow(): void {
		window.addEventListener(
			'resize',
			debounce(() => {
				this.checkOverflow();
			}, 100)
		);
	}

	/**
	 * Check if the menu or menu group ul overflows to show the more dropdown
	 *
	 * @return {void}
	 */
	private checkOverflow(): void {
		let menuNavbars = document.getElementsByClassName('menu-nav-list');
		for (let menuNavbar of menuNavbars) {
			if (!this.menuNavOverflowing.bool && menuNavbar.clientWidth >= this.menuNavOverflowing.width) {
				if (menuNavbar.scrollWidth - 1 > menuNavbar.clientWidth) {
					this.limit = 4;
					this.menuNavOverflowing = { bool: true, width: menuNavbar.clientWidth };
				} else {
					this.limit = this.elements.length;
					this.menuNavOverflowing = { bool: false, width: 0 };
				}
			}
		}
	}
}
