
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Modal from '@/components/shared/Modal.vue';
import CartViewer from '@/components/cart/CartViewer.vue';
import Checkout from '@/components/Checkout.vue';
import CartIcon from '../assets/images/icons/add-to-cart.svg?inline';
import BillIcon from '../assets/images/icons/add-to-bill.svg?inline';
const namespace: string = 'cart';

@Component<Cart>({
	components: {
		Modal,
		CartViewer,
		Checkout,
		CartIcon,
		BillIcon
	}
})

export default class Cart extends Vue {
	@Prop({ type: Boolean, required: false }) private openCartModal!: boolean;
	@Getter('getTotalNumberOfItems', { namespace }) private getTotalNumberOfItems!: number;
	@Getter('getSuiteCateringOrder', { namespace: 'suites' }) private suiteCateringOrder!: EventSuiteOrder | null;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Watch('openCartModal', { immediate: true }) private onOpenCartModalChange(): void {
		this.openCartModal ? this.viewCartModal() : this.hideCartModal();
	}

	private cartOpened: boolean = false;
	private checkoutOpened: boolean = false;
	private displayPreOrderSummary: boolean = false;
	private displayChangePreOrderButton: boolean = false;

	/**
	 * If the user is pre-ordering, we open the checkout modal right away
	 * If openCart query param is true, we open the cart modal and remove the query param
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if(this.isPreOrdering && this.suiteCateringOrder) {
			this.showCheckout(true);
		}
		if(this.$route.query.openCart === 'true') {
			this.viewCartModal();
			let query = { ...this.$route.query };
			delete query.openCart;
			this.$router.replace({ query }).catch(() => {});
		}
	}

	/**
	 * Open the cart modal
	 *
	 * @return {void}
	 */
	private viewCartModal(): void {
		this.cartOpened = true;
		document.documentElement.classList.add('modal-open');
	}

	/**
	 * Switch to the cart from checkout view
	 *
	 * @return {void}
	 */
	private switchToCart(fromPreOrderSummary: boolean): void {
		setTimeout(() => {
			if(fromPreOrderSummary) {
				this.displayChangePreOrderButton = true;
			}
			else {
				this.displayChangePreOrderButton = false;
			}
			this.cartOpened = true;
			this.checkoutOpened = false;
		}, 150);
	}

	/**
	 * Close cart modal
	 *
	 * @return {void}
	 */
	private hideCartModal(): void {
		this.displayChangePreOrderButton = false;
		this.cartOpened = false;
		this.checkoutOpened = false;
		document.documentElement.classList.remove('modal-open');
	}

	/**
	 * Switch to checkout from cart view
	 *
	 * @param {boolean} showPreOrderSummary
	 * @return {void}
	 */
	private showCheckout(showPreOrderSummary: boolean): void {
		setTimeout(() => {
			this.checkoutOpened = true;
			this.displayPreOrderSummary = showPreOrderSummary;
			this.cartOpened = false;
			document.documentElement.classList.add('modal-open');
		}, 150);
	}
}
