
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueMasonryPlugin } from 'vue-masonry';
import TextMenu from '@/components/menu/TextMenu.vue';
import SeeMore from '@/components/shared/SeeMore.vue';
Vue.use(VueMasonryPlugin);

@Component<MenuItemResults>({
	components: {
		TextMenu,
		SeeMore
	}
})
export default class MenuItemResults extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private menu!: Menu;
	@Prop({ type: Array, required: true, default: () => [] }) private items!: MenuItem[];
	@Prop({ type: String, required: false, default: 'vertical' }) private cardStyle!: string;


	/**
	 * Open the item viewer
	 *
	 * @param {MenuItem} menuItem
	 * @return {void}
	 */
	private clickHandler(menuItem: MenuItem): void {
		this.$emit('on-click', menuItem);
	}
}
