import { GetterTree } from 'vuex';
import { RootState, SuitesState } from '../types';

export const getters: GetterTree<SuitesState, RootState> = {
	getEventSuiteId(state): number | null {
		return state.eventSuiteId;
	},

	getUserEventSuite(state): UserEvent | null {
		return state.userEventSuite;
	},

	getMinimalEventInfo(state): MinimalEventInfo | null {
		return state.minimalEventInfo;
	},

	getSelectedEvent(state): EventInfo | null {
		return state.selectedEvent;
	},

	getUpcomingEvents(state): UserEvent[] {
		return state.upcomingEvents;
	},

	getSuiteCateringOrder(state): EventSuiteOrder | null {
		return state.suiteCateringOrder;
	},

	getActiveEvents(state): Event[] {
		return state.activeEvents;
	},

	isPreOrdering(state): boolean {
		return state.preOrdering;
	},

	isEventDayOrdering(state): boolean {
		return state.eventDayOrdering;
	}
};