
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import UserEvent from "@/components/profile/UserEvent.vue";
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';

const namespace: string = 'auth';

@Component<UserEvents>({
	components: {
		ArrowLeftIcon,
		UserEvent
	}
})
export default class UserEvents extends Vue {
	@Getter('getUser', { namespace }) private user!: UserInfo;
	@Getter('getUpcomingEvents', { namespace: 'suites' }) private userEvents!: UserEvent[];
	@Getter('showLoginAsBanner', { namespace }) private showLoginAsBanner!: boolean;

	/**
	 * Redirect to the profile page
	 *
	 * @return {void}
	 */
	private toProfile(): void {
		this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {});
	}
}
