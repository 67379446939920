
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import UserEventComponent from '@/components/profile/UserEvent.vue';

const namespace: string = 'suites';

@Component<ConfirmationPreOrder>({
	components: {
		ArrowLeftIcon,
		UserEventComponent
	}
})
export default class ConfirmationPreOrder extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private suiteCateringOrder!: EventSuiteOrder;
	@Action('setPreOrderCart', { namespace }) private setPreOrderCart!: () => Promise<void>;
	@Getter('getUserEventSuite', { namespace }) private userEventSuite!: UserEvent;
	@Getter('isSuiteOperator', { namespace: 'auth' }) private isSuiteOperator!: boolean;
	private loading: boolean = false;
	private canChangePreOrder: boolean = true;

	private mounted(): void {
		// TODO: In the future, everywhere we check for lock dates status, we will be able to remove all of those conditions and simply
		// check for the pre-order lock status. For now, the backend isn't reliable to update those at all time, so for that reason we check
		// that way. Once the backend is reliable, we can remove those conditions and simply check for the pre-order lock status.
		const preOrderLockDateISO: string = DateTime.fromISO(this.userEventSuite.event.start_date).minus({ days: this.userEventSuite.event.order_lock_period }).toISO();
		const diffInDays: number | undefined = DateTime.fromISO(preOrderLockDateISO).diff(DateTime.local(), 'days').toObject().days;
		const statusAllowsModification = this.userEventSuite.status === 'email_scheduled' || this.userEventSuite.status === 'email_sent' || this.userEventSuite.status === 'pending';

		if((diffInDays && diffInDays < 0 && !this.isSuiteOperator) || !statusAllowsModification) {
			this.canChangePreOrder = false;
		}
	}

	private goToUpcomingEvents(): void {
		this.$emit('close');
		this.$router.push({ path: '/profile/events', query: this.$route.query }).catch(() => {});
	}

	/**
	 * This method will build the cart with the user's item from their pre-porder
	 * and redirect them to the cart, where they will be able to edit and submit their
	 * order once more
	 *
	 * @return {Promise<void>}
	 */
	private async changePreOrder(): Promise<void> {
		try {
			this.loading = true;
			await this.setPreOrderCart();
			this.$emit('switchToCart');
		} catch (error) {
			this.$toasted.show(this.$t('checkout.confirmation.suites.error_setting_pre_order_cart'), { type: 'error', position: 'top-center' }).goAway(5000);
		} finally {
			setTimeout(() => {
				fireGoogleTag({ name: 'modifyPreOrder' });
				this.loading = false;
			}, 500);
		}
	}
}
