
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

@Component<SimpleKeyboard>({})
export default class SimpleKeyboard extends Vue {
	@Prop({ type: String, required: false, default: 'simple-keyboard' }) private keyboardClass!: string;
	@Prop({ type: String, required: true, default: '' }) private input!: string;
	@Prop({ type: String, required: true, default: '' }) private inputName!: string;
	@Prop({ type: String, required: true, default: '' }) private inputType!: string;
	@Watch('inputName')
	onInputNameChange(inputName: any) {
		this.keyboard.setOptions({ inputName });
	}
	@Watch('input')
	onInputChange(input: any) {
		this.keyboard.setInput(input);
	}
	@Watch('inputType')
	onInputTypeChanged(inputType: any) {
		switch(inputType){
		case "tel":
			this.keyboard.setOptions({ layoutName: "numbers" });
			break;
		case "text":
		default:
			// keep shift status when switching between text inputs
			if (!["default", "shift"].includes(this.keyboard.options.layoutName)) {
				this.keyboard.setOptions({ layoutName: "default" });
			}
			break;
		}
	}

	private keyboard: any = null;

	/**
	 * Setup the virtual keyboard
	 * 
	 * @return {void}
	 */
	private mounted(): void {
		this.keyboard = new Keyboard({
			onChange: this.onChange,
			onKeyPress: this.onKeyPress,
			inputName: this.inputName,
			layoutName: "default",
			layout: {
				default: [
					"q w e r t y u i o p",
					"a s d f g h j k l",
					"{shift} z x c v b n m {backspace}",
					"{numbers} {space} {ent}"
				],
				shift: [
					"Q W E R T Y U I O P",
					"A S D F G H J K L",
					"{shift} Z X C V B N M {backspace}",
					"{numbers} {space} {ent}"
				],
				numbers: ["1 2 3", "4 5 6", "7 8 9", "{abc} 0 {backspace}"]
			},
			display: {
				"{numbers}": "123",
				"{ent}": "return",
				"{escape}": "esc ⎋",
				"{tab}": "tab ⇥",
				"{backspace}": "⌫",
				"{capslock}": "caps lock ⇪",
				"{shift}": "⇧",
				"{controlleft}": "ctrl ⌃",
				"{controlright}": "ctrl ⌃",
				"{altleft}": "alt ⌥",
				"{altright}": "alt ⌥",
				"{metaleft}": "cmd ⌘",
				"{metaright}": "cmd ⌘",
				"{abc}": "ABC",
				"{space}": " "
			}
		});
	}
	
	private onChange(input: any): void {
		this.$emit('onChange', input);
	}

	private onKeyPress(button: any): void {
		this.$emit("onKeyPress", button);

		switch(button) {
		case "{shift}":
		case "{capslock}":
			this.handleKeyboardLayoutChange("shift");
			break;
		case "{numbers}":
		case "{abc}":
			this.handleKeyboardLayoutChange("numbers");
			break;
		default:
			break;
		}
	}

	private handleKeyboardLayoutChange(layout: any): void {
		this.keyboard.setOptions({ layoutName: this.keyboard.options.layoutName !== layout ? layout : "default" });
	}
}
