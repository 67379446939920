
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { VueMasonryPlugin } from 'vue-masonry';
import { filterItemsWithAllergensAndDiets } from '@/utils/filters';
import { getElementBySkuOrId } from '@/utils/helpers';
import { shuffle } from 'lodash';
import MenuResultsHeader from '@/components/menu/MenuResultsHeader.vue';
import TextMenu from '@/components/menu/TextMenu.vue';
import Sidebar from '@/components/navigation/Sidebar.vue';
import TabBar from '@/components/navigation/TabBar.vue';
import MenuItemViewer from '@/components/menu/MenuItemViewer.vue';
import MenuItemResults from '@/components/menu/MenuItemResults.vue';
import FeaturedItemList from '@/components/menu/FeaturedItemList.vue';
import SearchMenuItemResults from '@/components/menu/SearchMenuItemResults.vue';
import Modal from '@/components/shared/Modal.vue';
import FiltersBar from '@/components/filter/FiltersBar.vue';
import SeeMore from '@/components/shared/SeeMore.vue';

const namespace: string = 'filters';

Vue.use(VueMasonryPlugin);

@Component<MenuResults>({
	components: {
		MenuResultsHeader,
		TextMenu,
		MenuItemViewer,
		MenuItemResults,
		SearchMenuItemResults,
		Modal,
		Sidebar,
		TabBar,
		FiltersBar,
		SeeMore,
		FeaturedItemList
	}
})
export default class MenuResults extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private menus!: Menu[];
	@Prop({ type: Number, required: true, default: 0 }) private currentMenu!: number;
	@Prop({ type: Boolean, required: true, default: false }) private orderPaused!: boolean;
	@Prop({ type: String, required: false, default: '' }) private restaurantDisplayName!: string;
	@Prop({ type: String, required: false, default: '' }) private restaurantImage!: string;
	@Prop({ type: String, required: false, default: 'vertical' }) private cardStyle!: string;
	@Getter('getDiets', { namespace }) private diets!: string;
	@Getter('getAllergens', { namespace }) private allergens!: string;
	@Getter('getSelectedDiets', { namespace }) private selectedDiets!: string[];
	@Getter('getSelectedAllergens', { namespace }) private selectedAllergens!: string[];
	@Getter('getResultItems', { namespace: 'search' }) private items!: MenuItem;
	@Getter('getSearchInput', { namespace: 'search' }) private searchText!: string;
	@Getter('isViewOnly', { namespace: 'cart' }) private isViewOnly!: boolean;
	@Getter('getLastItemsOrdered', { namespace: 'auth' }) private lastItemsOrdered!: LastOrderedItem[] | undefined;
	@Getter('isMarketplaceHub', { namespace: 'restaurant' }) private isMarketplaceHub!: boolean;
	@Watch('items')
	onItemsChanged() {
		this.updateSearchMasonry();
	}
	@Watch('selectedDiets')
	onFiltersChanged() {
		this.updateMasonry();
	}
	@Watch('lastItemsOrderedOfMenu')
	onLastItemsOfMenuChanged() {
		this.$nextTick(() => this.$redrawVueMasonry('orderAgain'));
	}

	private currentMenuItemId: number | null = null;
	private currentItemMenuId: number | null = null;
	private showDetails: boolean = false;
	private featuredItemSelected: boolean = false;
	private orderAgainItem: MenuItem | null = null;

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.restaurantImage}`;
		}
		return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.restaurantImage}`;
	}

	/**
	 * Get the selected menu
	 *
	 * @return {Menu | null}
	 */
	private get menu(): Menu | null {
		return this.menus[this.currentMenu];
	}

	/**
	 * Loop through each last ordered item and find the corresponding menu or section item. Maximum of 3 items.
	 *
	 * @return {MenuItem[]}
	 */
	private get lastItemsOrderedOfMenu(): MenuItem[] {
		if (!this.menu || !this.lastItemsOrdered?.length) {
			return [];
		}
		const output: MenuItem[] = [];

		for (const lastItem of this.lastItemsOrdered) {
			// Menu items
			const menuItem = this.menu?.items.find((item) => getElementBySkuOrId(item, lastItem.id, lastItem.sku));
			if (menuItem && !menuItem.sold_out) {
				output.push({
					...menuItem,
					lastOrderedOptions: lastItem.items
				});
				continue;
			}

			// Section item
			for (const section of this.menu?.sections) {
				const sectionItem = section.items.find((item) => getElementBySkuOrId(item, lastItem.id, lastItem.sku));
				if (sectionItem && !sectionItem.sold_out) {
					output.push({
						...sectionItem,
						lastOrderedOptions: lastItem.items
					});
				}
			};

			// Limit of 3 previously ordered items per menu
			if (output.length >= 3) {
				break;
			}
		};

		return output;
	}

	/**
	 * Add the order again section at the beginning of the sidebar and tab bar lists if applicable
	 *
	 * @return {MenuSection[]}
	 */
	private get menuSectionsWithPrependedSections(): MenuSection[] {
		const prepend = [];

		if (this.menu && this.menu.sections?.length > 1 && this.lastItemsOrderedOfMenu.length) {
			prepend.push({
				name: this.$t('menu.order_again'),
				items: this.lastItemsOrderedOfMenu,
				slug: 'order-again'
			});
		}
		if (this.featuredItems.length) {
			prepend.push({
				name: this.$t('menu.featured_items'),
				items: this.featuredItems,
				slug: 'featured-items'
			});
		}

		return [...prepend, ...(this.menu?.sections || [])];
	}

	/**
	 * Check through each menu and section items to find the featured items
	 *
	 * @return {MenuItem[]}
	 */
	private get featuredItems(): MenuItem[] {
		const allFeaturedItems = this.menus.flatMap(menu => {
			const featuredMenuItems = menu.items.filter(item => item.featured_item && !item.sold_out && !item.unavailable);
			const featuredSectionItems = menu.sections.flatMap(section => section.items.filter(item => item.featured_item && !item.sold_out && !item.unavailable));

			return [...featuredMenuItems, ...featuredSectionItems];
		});

		if (allFeaturedItems.length <= 8) {
			return allFeaturedItems;
		}

		return shuffle(allFeaturedItems).slice(0, 8);
	}

	/**
	 * Get the actual index of the section depending on the presence of order again and/or featured items sections.
	 * TODO: Make featured items compatible with marketplace locations
	 *
	 * @param {number} sectionIndex
	 * @return {number}
	 */
	private getAdjustedSecionIndex(sectionIndex: number): number {
		const hasOrderAgain = this.lastItemsOrderedOfMenu.length > 0;
		const hasFeaturedItems = !this.isMarketplaceHub && this.featuredItems.length > 0;

		if (hasOrderAgain && hasFeaturedItems) {
			return sectionIndex + 2;
		}
		else if (hasOrderAgain || hasFeaturedItems) {
			return sectionIndex + 1;
		}
		return sectionIndex;
	}

	/**
	 * Filter the items based on allergens and diets
	 *
	 * @param {MenuItem[]} items
	 * @return {MenuItem[]}
	 */
	private filtered(items: MenuItem[]): MenuItem[] {
		if (this.selectedDiets.length || this.selectedAllergens.length) {
			this.updateMasonry();
			return filterItemsWithAllergensAndDiets(this.selectedDiets, this.selectedAllergens, items);
		}
		else {
			this.updateMasonry();
			return items;
		}
	}

	/**
	 * Redraw masonry event when there are changes in the display
	 * or items showed for the specific menu chosen.
	 *
	 * @return {void}
	 */
	private updateMasonry(): void {
		this.menus.forEach(() => {
			setTimeout(() => {
				this.$nextTick(() => this.$redrawVueMasonry());
			}, 150);
		});
	}

	/**
	 * Redraw masonry event for search event since the display port
	 * changes.
	 *
	 * @return {void}
	 */
	private updateSearchMasonry(): void {
		this.$nextTick(() => this.$redrawVueMasonry());
	}

	/**
	 * Get the menu_items and section_items from the menu selected
	 *
	 * @param {number} menuId
	 * @return {MenuItem[]}
	 */
	private getMenuItems(menuId: number): MenuItem[] {
		const menu = this.menus.find((menu: Menu) => {
			return menu.id === menuId;
		})!;
		let menuItems: MenuItem[] = menu.items;
		menu.sections.forEach(section => {
			menuItems = menuItems.concat(section.items);
		});
		return menuItems;
	}

	/**
	 * Opens the selected card's menu item viewer
	 *
	 * @param {MenuItem} menuItem - menu item
	 * @param {boolean} [isOrderAgain] - is the selected item from the order again section
	 * @param {boolean} [isFeaturedItem] - is the selected item from the featured items section
	 * @return {void}
	 */
	private viewMenuItem(menuItem: MenuItem, isOrderAgain?: boolean, isFeaturedItem?: boolean): void {
		if (isOrderAgain) {
			this.orderAgainItem = menuItem;
		}
		this.featuredItemSelected = isFeaturedItem || false;
		this.currentMenuItemId = menuItem.id;
		this.currentItemMenuId = menuItem.menu_id;
		this.showDetails = true;
		document.documentElement.classList.add('modal-open');
	}

	/**
	 * Hide the menu viewer modal
	 *
	 * @return {void}
	 */
	private hideMenuItemModal(): void {
		this.showDetails = false;
		this.currentMenuItemId = null;
		this.orderAgainItem = null;
		document.documentElement.classList.remove('modal-open');
	}
}
