
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PaymentMethods from '@/components/auth/PaymentMethods.vue';
import CreditCardInfo from '@/components/auth/CreditCardInfo.vue';
import { handleAllErrors } from '@/utils/errorHandling';

const namespace: string = 'auth';

@Component<UserPaymentMethods>({
	components: {
		PaymentMethods,
		CreditCardInfo
	}
})
export default class UserPaymentMethods extends Vue {
	@Action('addPaymentMethod', { namespace }) private addPaymentMethod!: (payload: AddPaymentMethodPayload) => Promise<void>;
	@Action('updatePaymentMethod', { namespace }) private updatePaymentMethod!: (payload: AddPaymentMethodPayload) => Promise<void>;
	@Action('deletePaymentMethod', { namespace }) private deletePaymentMethod!: (payload: DeletePaymentMethodPayload) => Promise<void>;
	@Getter('getUser', { namespace }) private user!: UserInfo;
	@Getter('getCreditCards', { namespace }) private paymentOptions!: SavedPaymentOption[];

	private steps = ['PaymentMethods', 'CreditCardInfo'];
	private currentStep = 0;
	private transitionName = '';
	private loading = '';

	private paymentMethods: PaymentOption[] = [];

	private creditCardToEdit: PaymentOption = {
		ccNumber: '',
		expiryDate: '',
		cvs: '',
		postalCode: '',
		isDefault: false
	};

	
	private mounted() {
		this.refreshPaymentMethods();
	}

	/**
	 * Refresh payment methods with those in the state, and format them for the component to use
	 * 
	 * @return {void}
	 */
	private refreshPaymentMethods(): void {
		this.paymentMethods = this.paymentOptions.map(paymentOption => {
			return {
				id: paymentOption.paymentCardId,
				ccNumber: paymentOption.cardNum,
				cvs: '•••',
				isDefault: paymentOption.isDefault,
				expiryDate: `${paymentOption.expiryMonth}/${paymentOption.expiryYear}`,
				postalCode: ''
			}
		});
	}

	/**
	 * Handle what to do with the payload depending of the step
	 * 
	 * @param {StepLoginPayload | UserContactInfo | StepPaymentOptionsPayload | StepAddCardPayload} payload
	 * @return {void}
	 */
	private nextHandler(payload: StepLoginPayload | UserContactInfo | StepPaymentOptionsPayload | StepAddCardPayload): void {
		if (this.currentStep === 0) {
			this.stepPaymentOptionsHandler(payload as StepPaymentOptionsPayload);
		}
		else if (this.currentStep === 1) {
			this.stepAddOrModifyCardHandler(payload as StepAddCardPayload)
		}
	}

	/**
	 * Handle what to do in that step depending of the payload type
	 * 1/ The user selects another defualt payment method
	 * 2/ The user want to edit a payment method
	 * 3/ The user want to add a payment method
	 * 
	 * @param {StepPaymentOptionsPayload} payload
	 * @return {Prmise<void>}
	 */
	private async stepPaymentOptionsHandler(payload: StepPaymentOptionsPayload): Promise<void> {
		if (payload.type === 'updateDefault') {
			try {
				this.loading = 'updateDefault';
				await this.updatePaymentMethod({ userId: this.user.id, token: this.user.token, paymentMethod: payload.paymentMethod as PaymentOption });
				this.refreshPaymentMethods();
			} catch (error) {
				handleAllErrors(this.$t('profile.payment_methods.error_prefix', { action: this.$t(`profile.payment_methods.edit`) }), error);
			} finally {
				setTimeout(() => {
					this.loading = '';
				}, 500);
			}
		}
		else if (payload.type === 'edit') {
			this.creditCardToEdit = {
				...payload.paymentMethod as PaymentOption,
				cvs: '•••',
				postalCode: '••••••'
			};
			this.toNextStep();
		}
		else {
			this.creditCardToEdit = {
				ccNumber: '',
				expiryDate: '',
				cvs: '',
				postalCode: '',
				isDefault: false
			}
			this.toNextStep();
		}
	}

	/**
	 * Add, edit or delete a payment option
	 * 
	 * @param {StepAddCardPayload} payload
	 * @return {Promise<void>}
	 */
	private async stepAddOrModifyCardHandler(payload: StepAddCardPayload): Promise<void> {
		try {
			if (payload.type === 'edit') {
				this.loading = 'save';
				await this.updatePaymentMethod({ userId: this.user.id, token: this.user.token, paymentMethod: payload.paymentInfo });
				this.refreshPaymentMethods();
				this.toPreviousStep();
			}
			else if (payload.type === 'delete') {
				this.loading = 'delete';
				await this.deletePaymentMethod({ userId: this.user.id, token: this.user.token, cardId: payload.paymentInfo.id as string });
				this.refreshPaymentMethods();
				this.toPreviousStep();
			}
			else {
				this.loading = 'save';
				await this.addPaymentMethod({ userId: this.user.id, token: this.user.token, paymentMethod: payload.paymentInfo });
				this.refreshPaymentMethods();
				this.toPreviousStep();
			}
		} catch (error) {
			handleAllErrors(
				this.$t('profile.payment_methods.error_prefix', { action: this.$t(`profile.payment_methods.${payload.type}`) }),
				error
			);
		} finally {
			setTimeout(() => {
				this.loading = '';
			}, 500);
		}
	}

	/**
	 * Update transition name and increase step
	 * 
	 * @return {void}
	 */
	private toNextStep(): void {
		this.transitionName = 'slide-next';
		this.currentStep++;
	}

	/**
	 * Update transition name
	 * Decrease step or redirect to profile
	 * 
	 * @return {void}
	 */
	private toPreviousStep(): void {
		if (this.currentStep === 0) {
			this.transitionName = '';
			this.$nextTick(() => this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {}));
		}
		else {
			this.transitionName = 'slide-prev';
			this.currentStep--;
		}
	}
}
