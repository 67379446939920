
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
import Modal from '@/components/shared/Modal.vue';
import AccountIcon from '../../assets/images/icons/manage-account.svg?inline';

const namespace: string = 'cart';

@Component<ProfileButton>({
	components: {
		Modal,
		AccountIcon
	}
})
export default class ProfileButton extends Vue {
	@Getter('getItems', { namespace }) private items!: OrderItem[];
	@Getter('isAuthenticated', { namespace: 'auth' }) private isAuthenticated!: boolean;

	private isModalOpened: boolean = false;

	/**
	 * Open confirmation modal if anonymous user with menu items go to login
	 * Otherwise redirect to profile/login page
	 *
	 * @return {void}
	 */
	private onProfileBtnClick(): void {
		if (!this.isAuthenticated && this.items.length) {
			this.isModalOpened = true;
		}
		else {
			this.redirectToProfileOrLogin();
		}
	}

	/**
	 * Redirect to login or profile page depending if user is authenticated or not
	 *
	 * @return {void}
	 */
	private redirectToProfileOrLogin(): void {
		fireGoogleTag({ name: 'viewProfile', userType: this.isAuthenticated ? 'loggedIn' : 'guest' });
		this.$router.push({ path: this.isAuthenticated ? '/profile' : '/login', query: this.$route.query }).catch(() => {});
	}
}
