
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import '@/validation-rules';
import XIcon from 'vue-feather-icons/icons/XIcon';
import Tooltip from '@/components/shared/Tooltip.vue';

const namespace: string = 'cart';

@Component<MembershipProgramDrawer>({
	components: {
		ValidationObserver,
		ValidationProvider,
		XIcon,
		Tooltip
	}
})

export default class MembershipProgramDrawer extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private membershipCustomization!: MembershipCustomization;
	@Action('updateContactInformation', { namespace }) private updateContactInformation!: (contact: CheckoutContactInfo) => void;
	@Action('checkIfMemberAndApplyPromo', { namespace: 'auth' }) private checkIfMemberAndApplyPromo!: (identifier: string) => void;
	@Getter('getContactInformation', { namespace }) private contactInformation!: CheckoutContactInfo;
	@Watch('contactInformation', { immediate: true, deep: true})
	onContactInformationChanged() {
		if(this.contactInformation) {
			if(this.membershipCustomization.input_type !== 'account') {
				this.identifier = this.contactInformation[this.membershipCustomization.input_type];
			}
		}
	};
	private opened: boolean = true;
	private loading: boolean = false;
	private identifier: string = '';
	private inputType: string = '';
	private inputRules: string = '';
	private inputMaxLength: string = '';
	private inputErrorMsg: string = '';

	$refs!: {observer: Validate}

	/**
	 * Get input type and its rules
	 *
	 * @return {void}
	 */
	private mounted() {
		if(this.membershipCustomization.input_type === 'full_name') {
			this.inputType = 'text'
			this.inputRules = 'required'
			this.inputMaxLength = '40';
			this.inputErrorMsg = this.$t('membership_drawer.error_invalid_name');
		}
		else if(this.membershipCustomization.input_type === 'email') {
			this.inputType = 'email';
			this.inputRules = 'required|email'
			this.inputMaxLength = '40';
			this.inputErrorMsg = this.$t('membership_drawer.error_invalid_email');
		}
		else if(this.membershipCustomization.input_type === 'phone_number') {
			this.inputType = 'tel';
			this.inputRules = 'required|min:12|max:12'
			this.inputMaxLength = '12';
			this.inputErrorMsg = this.$t('membership_drawer.error_invalid_phone_number');
			this.formatInput();
		}
		else {
			this.inputType = 'text';
			this.inputRules = 'required';
			this.inputMaxLength = '50';
			this.inputErrorMsg = this.$t('membership_drawer.error_invalid_account');
		}
	}

	/**
	 * Close the drawer
	 *
	 * @return {void}
	 */
	private closeDrawer(): void {
		this.opened = false;
	}

	/**
	 * Format the phone number - remove non-digital characters
	 * and add dashes to the respective positions
	 *
	 * @return {void}
	 */
	private formatInput(): void {
		if(this.inputType === 'tel' && this.identifier) {
			this.identifier = this.identifier.replace(/\D/g,'');
			if(this.identifier) {
				this.identifier = this.identifier.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-');
			}
		}

		if(this.membershipCustomization.input_type !== 'account') {
			this.updateContactInfo();
		}
	}

	/**
	 * Update the contact information
	 *
	 * @return {void}
	 */
	private updateContactInfo(): void {
		this.updateContactInformation(
			{
				full_name: this.membershipCustomization.input_type === 'full_name' ? this.identifier : this.contactInformation.full_name,
				email: this.membershipCustomization.input_type === 'email' ? this.identifier : this.contactInformation.email,
				phone_number: this.membershipCustomization.input_type === 'phone_number' ? this.identifier : this.contactInformation.phone_number
			}
		);
	}

	/**
	 * Check and apply the membership promo and special menus
	 *
	 * @return {void}
	 */
	private async checkAndApplyMembershipPromo(): Promise<void> {
		try {
			this.loading = true;
			let isValid = await this.$refs.observer.validate();
			if (isValid) {
				await this.checkIfMemberAndApplyPromo(this.inputType === 'tel' ? this.identifier.replace(/[^0-9]/g, '') : this.identifier);
				this.$emit('membershipApplied');
				setTimeout(() => {
					this.opened = false;
				}, 500);
			}

			// Invalid phone number (We do not have room here to display an error message
			// below the field so we show a toast)
			else {
				this.$toasted.show(this.inputErrorMsg, { type: 'error', position: 'top-center' }).goAway(5000);
			}
		} catch(error) {
			this.$toasted.show(this.$t('membership_drawer.error_profile_not_found'), { type: 'error', position: 'top-center' }).goAway(5000);
		} finally {
			setTimeout(() => {
				this.loading = false;
			}, 500);
		}
	}
}
