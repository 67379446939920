
import http from '@/utils/http';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { getMenuStyle } from '@/utils/styling';
import 'vue-select/dist/vue-select.css';

const namespace: string = 'cart';

@Component<RestaurantIndex>({})
export default class RestaurantIndex extends Vue {
	@Prop({ type: String, required: true, default: '' }) private restaurantSlug!: string;
	@Action('processQueryParams', { namespace }) private processQueryParams!: (query: any) => Promise<void>;
	@Action('setRestaurantCharges', { namespace }) private setRestaurantCharges!: (restaurant: Restaurant) => void;
	@Action('getMemberCookie', { namespace: 'auth' }) private getMemberCookie!: () => Promise<void>;
	@Action('setAxiosInstance', { namespace: 'auth' }) private setAxiosInstance!: (Restaurant: Restaurant) => void;
	@Action('getLastItemsOrdered', { namespace: 'auth' }) private getLastItemsOrdered!: (payload: UserIdentifier) => Promise<void>;
	@Action('setInitialMenus', { namespace: 'restaurant' }) private setInitialMenus!: (initialMenus: Menu[]) => void;
	@Action('setInitialMenuGroups', { namespace: 'restaurant' }) private setInitialMenuGroups!: (initialMenuGroups: MenuGroup[]) => void;
	@Action('setRestaurant', { namespace: 'restaurant' }) private setRestaurant!: (Restaurant: Restaurant) => void;
	@Action('setAvailableFilters', { namespace: 'filters' }) private setAvailableFilters!: () => void;
	@Getter('getUser', { namespace: 'auth' }) private user!: UserInfo;
	@Getter('isAuthenticated', { namespace: 'auth' }) private isAuthenticated!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('isOrderItemAgainEnabled', { namespace: 'restaurant' }) private isOrderItemAgainEnabled!: boolean;

	private dataFetched: boolean = false;
	private cssLoaded: boolean = false;

	/**
	 * We fetch the initial menus and the restaurant to gather the configuration like default
	 * tax_rate, orders paused and styling options.
	 * We then process all query parameters.
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		// Already fetched the data
		if(!this.restaurant || !this.restaurant.name) {
			try {
				// Get menus and restaurant info
				const { menuGroups, menus, restaurant } = await http.getData(this.restaurantSlug) as HttpPayload;
				this.setRestaurant(restaurant);
				this.setRestaurantCharges(restaurant);
				this.setAxiosInstance(restaurant);
				this.setInitialMenuGroups(menuGroups);
				this.setInitialMenus(menus);
				this.setAvailableFilters();

				if (this.isAuthenticated && this.isOrderItemAgainEnabled) {
					await this.getLastItemsOrdered({ userId: this.user.id, token: this.user.token });
				}

				// Get member cookie
				await this.getMemberCookie();

				// Get restaurant customization
				await getMenuStyle(restaurant).then(() => setTimeout(() => { this.cssLoaded = true; }, 200));

				// Process the query parameters (tableNum, takeout, etc) to set up the
				// configuration. (Custom error response here)
				try {
					await this.processQueryParams(this.$route.query);
				} catch(error) {
					if(error && error.response && error.response.data) {
						this.$toasted.show(error.response?.data?.message, { type: 'error', position: 'top-center' }).goAway(5000);
					}
					else {
						this.$toasted.show(error, { type: 'error', position: 'top-center' }).goAway(5000);
					}
				}
			} catch (err) {
				this.$router.push({ name: 'PageNotFound' }).catch(() => {});
				throw err;
			} finally {
				this.dataFetched = true;
			}
		}

		// Already fetched the data needed
		else {
			this.dataFetched = true;
			this.cssLoaded = true;
		}
	}
}
