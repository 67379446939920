

import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import BannerContent from './BannerContent.vue';

@Component<BannerContainer>({
	components: {
		BannerContent
	}
})
export default class BannerContainer extends Vue {
	@Getter('isTabBarDisplayed', { namespace: 'layout' }) private isTabBarDisplayed!: boolean;
	@Prop({ type: String, required: false, default: '' }) private containerClass!: string;
	@Prop({ type: Array, required: true }) private banners!: BannerItem[];

	private bannerRemoved(banner: BannerItem): void {
		this.$emit('banner-removed', banner);
	}
}
