
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import ToggleSwitch from '@/components/shared/ToggleSwitch.vue';
import '@/validation-rules';

@Component({
	components: {
		ToggleSwitch,
		ValidationProvider
	}
})
export default class CheckoutTabInformation extends Vue {
	@Prop({ type: Boolean, default: false }) private hideDescription!: boolean;
	@Getter('getSuitesInformation', { namespace: 'cart' }) private suitesInformation!: CheckoutSuitesInfo | null;
	@Getter('getUserEventSuite', { namespace: 'suites' }) private eventSuite!: EventSuite | null;
	@Getter('isEventDayOrdering', { namespace: 'suites' }) private isEventDayOrdering!: boolean;
	@Watch('tabActive')
	private onTabActiveChange(): void {
		if (!this.tabActive) {
			this.tabLimit = null;
			this.$emit('input', null);
		}
		else if (this.tabActive && this.eventSuite?.tab_limit) {
			this.tabLimit = this.eventSuite.tab_limit;
			this.$emit('input', this.tabLimit);
		}
	}

	private tabLimit: number | null = null;
	private tabActive: boolean = false;

	/**
	 * Get the validation rules for the tab limit
	 *
	 * @return {string}
	 */
	private get tabLimitRules(): string {
		if(this.isEventDayOrdering) {
			if (this.eventSuite?.tab_balance) {
				return `integer|min_value:1|required|greater_than:${this.eventSuite.tab_balance},${this.$t('checkout.form.tab.tab_limit')},${this.$t('operator.suite_settings.tab_balance', { balance: this.eventSuite.tab_balance })}`;
			}
			return 'integer|min_value:1|required';
		}
		else {
			return 'integer|min_value:1';
		}
	}

	private created(): void {
		this.setInitialTabLimit(this.eventSuite?.tab_limit || this.suitesInformation?.tabLimit || null);
	}

	private setInitialTabLimit(initialTabLimit: number | null): void {
		this.tabLimit = initialTabLimit;

		if (this.tabLimit) {
			this.tabActive = true;
		}
	}

	/**
	 * Send event to update the suites info to the parent
	 *
	 * @return {void}
	 */
	private updateSuitesInfo(): void {
		if (this.tabLimit && !isNaN(this.tabLimit)) {
			this.$emit('input', +(this.tabLimit.toFixed(0)));
		}
		else {
			this.$emit('input', null);
		}
	}
}
