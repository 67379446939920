
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import '@/validation-rules';

@Component<PurchaseOrderInput>({
	components: {
		ValidationProvider
	}
})
export default class PurchaseOrderInput extends Vue {
	@Prop({ type: String, required: true, default: null }) private purchaseOrder!: string | null;
	@Prop({ type: String, required: true, default: null }) private inputLabel!: string | null;

	private mutablePurchaseOrder: string | null = this.purchaseOrder;

	/**
	 * Emit event to parent to update the payment information
	 *
	 * @return {void}
	 */
	private updatePurchaseOrder(): void {
		this.$emit('set-purchase-order', this.mutablePurchaseOrder);
	}
}
