
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import '@/validation-rules';

@Component<CostCenterInput>({
	components: {
		ValidationProvider
	}
})
export default class CostCenterInput extends Vue {
	@Prop({ type: String, required: true, default: null }) private costCenter!: string | null;
	@Prop({ type: String, required: true, default: null }) private inputLabel!: string | null;

	private mutableCostCenter: string | null = this.costCenter;

	/**
	 * Emit event to parent to update the payment information
	 *
	 * @return {void}
	 */
	private updateCostCenter(): void {
		this.$emit('set-cost-center', this.mutableCostCenter);
	}
}
