
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import LogOutIcon from 'vue-feather-icons/icons/LogOutIcon';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

const namespace: string = 'suites';

@Component({
	components: {
		LoadingSpinner,
		LogOutIcon
	}
})
export default class OperatorEventList extends Vue {
	@Action('setSuitesLocationId', { namespace }) private setSuitesLocationId!: (locationId: string) => void;
	@Action('getLocationGroupEvents', { namespace }) private getLocationGroupEvents!: () => void;
	@Action('logoutOperator', { namespace: 'auth' }) private logoutOperator!: () => void;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('getActiveEvents', { namespace }) private activeEvents!: Event[];

	private isLoading: boolean = false;

	private async created(): Promise<void> {
		if (!this.activeEvents.length) {
			this.isLoading = true;
			try {
				await this.getLocationGroupEvents();
			}
			catch(error) {
				this.$toasted.show(error.response?.data?.message, { type: 'error', position: 'top-center' }).goAway(5000);
			} finally {
				setTimeout(() => {
					this.isLoading = false;
				}, 500);
			}
		}
	}

	/**
	 * Handle selecting an event and navigating to the event page
	 *
	 * @param {EventLocationInfo} event
	 * @return {Promise<void>}
	 */
	private async handleNavigation(event: EventLocationInfo): Promise<void> {
		try {
			this.setSuitesLocationId(event.location_id);
			this.$router.push({ path: `/${this.restaurant.slug}/events/${event.id}`, query: this.$route.query }).catch(() => {});
		} catch (error) {
			this.$toasted.show(error.response?.data?.message, { type: 'error', position: 'top-center' }).goAway(5000);
		}
	}

	/**
	 * Handle logging out from the operator view
	 *
	 * @return {Promise<void>}
	 */
	private async handleLogout(): Promise<void> {
		await this.logoutOperator();
		this.$router.replace({ path: '/login', query: this.$route.query }).catch(() => {});
	}
}
