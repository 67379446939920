import { ActionTree } from 'vuex';
import { GeolocationState, RootState } from '../types';
import { state } from './geolocation';
import { state as authState } from '../auth/auth';

export const actions: ActionTree<GeolocationState, RootState> = {
	/**
	 * Perform a geolocation search and set the results
	 *
	 * @return {Promise<void>}
	 */
	async geolocationSearch({ commit }): Promise<void> {
		try {
			const { data } = await authState.axiosInstance.post<GeolocationSearchResponse>(`${process.env.VUE_APP_API_PREFIX}/restaurants/geolocation-search`, state.geolocationPayload);
			commit('SET_GEOLOCATION_RESULTS', data);
		}
		catch (error) {
			commit('SET_GEOLOCATION_RESULTS', null);
		}
	},

	async autocompleteSearch({ commit }, payload: { place: AutocompletePrediction, sessionToken: {} }): Promise<void> {
		try {
			const { place, sessionToken } = payload;
			const { data } = await authState.axiosInstance.post<GeolocationSearchResponse>(`${process.env.VUE_APP_API_PREFIX}/restaurants/autocomplete-search`, {
				group: state.geolocationPayload.group,
				radius: state.geolocationPayload.radius,
				unit: state.geolocationPayload.unit,
				sessionToken: sessionToken,
				description: place.description,
				gmapPlaceId: place.place_id,
				terms: place.terms,
				types: place.types,
			});
			commit('SET_GEOLOCATION_RESULTS', data);
		}
		catch (error) {
			commit('SET_GEOLOCATION_RESULTS', null);
		}
	},

	/**
	 * Set the geolocation search results
	 *
	 * @param {GeolocationResult[] | null} payload
	 * @return {void}
	 */
	async setGeolocationResults({ commit }, results: GeolocationResult[] | null): Promise<void> {
		commit('SET_GEOLOCATION_RESULTS', results);
	},

	/**
	 * Set the geolocation payload
	 *
	 * @param {GeolocationPayload} payload
	 * @return {void}
	 */
	setGeolocationPayload({ commit }, payload: GeolocationPayload): void {
		commit('SET_GEOLOCATION_PAYLOAD', payload);
	},

	/**
	 * Set the country to search
	 *
	 * @param {string | string[]} country - default ['us', 'ca']
	 * @return {void}
	 */
	setCountry({ commit }, country: string | string[]): void {
		commit('SET_COUNTRY', country);
	},

	/**
	 * Set the radius of the search
	 *
	 * @param {number} radius - default 10
	 * @return {void}
	 */
	setRadius({ commit }, radius: number): void {
		commit('SET_RADIUS', radius);
	},

	/**
	 * Set the unit of the radius
	 *
	 * @param {'km'|'mi'} unit - default 'mi'
	 * @return {void}
	 */
	setUnit({ commit }, unit: string): void {
		commit('SET_UNIT', unit);
	},

	/**
	 * Set the location group to search on
	 *
	 * @param {string} group - default 'bowlero'
	 * @return {void}
	 */
	setGroup({ commit }, group: string): void {
		commit('SET_GROUP', group);
	},

	/**
	 * Set the geolocation permission state
	 *
	 * @param {GeolocationPermissionState} state
	 * @return {void}
	 */
	setGeolocationPermission({ commit }, state: GeolocationPermissionState): void {
		commit('SET_GEOLOCATION_PERMISSION', state);
	},

	/**
	 * Set the show distance state
	 *
	 * @param {string[]} searchTypes
	 * @return {void}
	 */
	setShowDistance({ commit }, searchTypes: string[]): void {
		commit('SET_SHOW_DISTANCE', searchTypes);
	}
};