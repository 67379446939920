import { ActionTree } from 'vuex';
import { RestaurantState, RootState } from '../types';
import { state } from './restaurant';
import { state as authState } from '../auth/auth';

export const actions: ActionTree<RestaurantState, RootState> = {
	setRestaurant({ commit }, restaurant: Restaurant): void {
		commit('SET_RESTAURANT', restaurant);
	},

	/**
	 * Set the initial menu groups
	 *
	 * @param {MenuGroup[]} initialMenus
	 * @return {Promise}
	 */
	setInitialMenuGroups({ commit }, initialMenuGroups: MenuGroup[]): void {
		commit('SET_INITIAL_MENU_GROUPS', initialMenuGroups);
	},

	/**
	 * Set the initial menus (not filtered by order type or any other conditions)
	 *
	 * @param {Menu[]} initialMenus
	 * @return {Promise}
	 */
	setInitialMenus({ commit }, initialMenus: Menu[]): void {
		commit('SET_INITIAL_MENUS', initialMenus);
	},

	/**
	 * Set the final filtered menus by all types (member menus, query params, etc)
	 *
	 * @param {Menu[]} filteredMenus
	 * @return {Promise}
	 */
	setFilteredMenus({ commit }, filteredMenus: Menu[]): void {
		commit('SET_FILTERED_MENUS', filteredMenus);
	},

	/**
	 * Set the member prices of items/options
	 *
	 * @param {Menu[]} menus
	 * @return {Promise}
	 */
	async setMemberPrices({ commit, dispatch }, menus: Menu[]): Promise<Menu[]> {
		return await new Promise((resolve) => {
			commit('SET_MEMBER_PRICES', menus);

			// Calculate cart items member prices
			dispatch('cart/calculateCartItemsMemberPrices', {}, { root: true });
			resolve(state.memberMenus);
		});
	},

	/**
	 * Get the payment config from salata of the restaurant
	 *
	 * @return {Promise}
	 */
	async getSalataRestaurantPaymentConfig({ commit }): Promise<any> {
		const payload = {
			app8RestaurantId: state.restaurant.app8_restaurant
		};

		return await new Promise((resolve, reject) => {
			authState.axiosInstance
				.post(`${process.env.VUE_APP_API_PREFIX}/payment/get-payment-config`, payload)
				.then(response => {
					commit('SET_PAYMENT_CONFIG', response.data);
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},

	resetRestaurantState({ commit }): void {
		commit('RESET_RESTAURANT_STATE');
	}
};