/**
 * Fire google tag manager event
 *
 * @param {GoogleTagEvent} event
 * @return {void}
 */
export function fireGoogleTag(event: GoogleTagEvent): void {
	const tempWindow: any = window as any;
	tempWindow.dataLayer = tempWindow.dataLayer || [];
	tempWindow.dataLayer.push({
		'event' : event.name,
		'eventSpecifier' : event.specifier,
		'eventDetail' : event.detail,
		'eventParameter': event.parameter,
		'userType': event.userType,
		'userId' : event.userId,
		'tempSessionId' : event.tempSessionId
	});
}

/**
 * Fire a google tag event. Here we have to verify which type of error it is
 * before sending the event so we can categorize the events based on the error
 * type. We then send the specifier along with it to know where it happened.
 *
 * TODO: Update when the backend start using error codes with strings, to check error code
 * instead for better compatiblity (API-306)
 *
 * @param {GoogleTagEventError} event
 * @return {void}
 */
export function fireGoogleTagError(event: GoogleTagEventError): void {
	if(event.error && event.error.response && event.error.response.data && event.error.response.data.message) {
		const message: string = event.error.response.data.message;
		const code: number = event.error.response.data.code;

		if(event.name) {
			return fireGoogleTag({ name: event.name, specifier: event.specifier, detail: message });
		}

		// Item status errors
		// ====================================================================================
		if(code === 415) {
			return fireGoogleTag({ name: 'itemSoldOutError', specifier: event.specifier });
		}
		else if(code === 426) {
			return fireGoogleTag({ name: 'itemUnavailableError', specifier: event.specifier });
		}
		else if(code === 428) {
			return fireGoogleTag({ name: 'itemPriceChangedError', specifier: event.specifier });
		}
		else if(code === 1804) {
			return fireGoogleTag({ name: 'itemOutOfStockError', specifier: event.specifier });
		}
		// ====================================================================================

		// Member errors
		// ====================================================================================
		else if(code === 2202) {
			return fireGoogleTag({ name: 'memberAppliedError' });
		}
		// ====================================================================================

		// Availability errors
		// ====================================================================================
		else if(code === 416 || code === 417 || code === 418 || code === 422 || code === 424) {
			return fireGoogleTag({ name: 'availabilityError', specifier: event.specifier });
		}
		else if(code === 419) {
			return fireGoogleTag({ name: 'orderPaused', specifier: event.specifier });
		}
		else if(code === 418) {
			return fireGoogleTag({ name: 'orderPaused', specifier: event.specifier });
		}
		// ====================================================================================

		// Order submission errors
		// ====================================================================================
		else if(code === 410) {
			return fireGoogleTag({ name: 'transactionFailed', detail: event.detail });
		}

		// SALATA ERROR, NO CODES FOR THE MOMENT ON THEIR SIDE
		else if(code === 200 || code === 1803 || code === 1804) {
			return fireGoogleTag({ name: 'unableToMakePayment', detail: event.detail });
		}

		// General errors
		// ====================================================================================
		else {
			return fireGoogleTag({ name: 'generalError', specifier: event.specifier, detail: message });
		}
	}
}