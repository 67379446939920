
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { formatCVD, formatCCNumber, formatExpiryDate } from '@/utils/format';
import { scrollIntoViewIfNeeded } from '@/utils/helpers';
import CreditCardIcon from '@/assets/images/icons/credit-card-icon.svg?inline';
import VisaLogoIcon from '@/assets/images/icons/visa-logo-icon-blue.svg?inline';
import MastercardLogoIcon from '@/assets/images/icons/mastercard-logo-icon.svg?inline';
import AmexLogoIcon from '@/assets/images/icons/amex-logo-icon.svg?inline';
import '@/validation-rules';

const namespace: string = 'cart';

@Component<CreditCardInput>({
	components: {
		ValidationProvider,
		CreditCardIcon,
		VisaLogoIcon,
		MastercardLogoIcon,
		AmexLogoIcon
	}
})
export default class CreditCardInput extends Vue {
	@Prop({ type: Object, required: true, default: {} }) private paymentCard!: CheckoutCardInfo;
	@Prop({ type: Boolean, required: true, default: false }) private savePaymentMethodOnCheckout!: boolean;
	@Action('updateChosenCreditCard', { namespace }) private updateChosenCreditCard!: (chosenCreditCard: SavedPaymentOption) => void;
	@Getter('isGenericCatering', { namespace }) private isGenericCatering!: boolean;
	@Getter('getChosenCreditCard', { namespace }) private chosenCreditCard!: SavedPaymentOption;
	@Getter('getCreditCards', { namespace: 'auth' }) private creditCards!: SavedPaymentOption[];
	@Getter('isAnonymousUser', { namespace: 'auth' }) private isAnonymousUser!: boolean;

	private mutableSavePaymentMethodOnCheckout: boolean = this.savePaymentMethodOnCheckout;
	private mutablePaymentCard: CheckoutCardInfo = this.paymentCard;

	/**
	 * Format the credit card - remove non digital characters
	 *
	 * @param {string} creditCardInfo
	 * @return {string}
	 */
	private formatCreditCard(creditCardInfo: string): string {
		let creditCardInfoFormatted = creditCardInfo.replace(/\D/g,'');
		return creditCardInfoFormatted.slice(creditCardInfoFormatted.length - 4);
	}

	/**
	 * Format the credit card numbers into 4 digits separated by dashes
	 *
	 * @return {void}
	 */
	private formatCCNumberLocal(): void {
		this.mutablePaymentCard.number = formatCCNumber(this.mutablePaymentCard.number);
	}

	/**
	 * Format CVD to respect the format with no digital characters
	 *
	 * @return {void}
	 */
	private formatCVDLocal(): void {
		this.mutablePaymentCard.cvd = formatCVD(this.mutablePaymentCard.cvd);
	}

	/**
	 * Format the expiry date with a slash between month and year
	 *
	 * @return {void}
	 */
	private formatExpiryDateLocal(): void {
		this.mutablePaymentCard.expiry_date = formatExpiryDate(this.mutablePaymentCard.expiry_date);
	}

	/**
	 * Put letters to uppercase and update payment info
	 *
	 * @return {void}
	 */
	private updatePostalCode(): void {
		this.mutablePaymentCard!.postal_code = this.mutablePaymentCard!.postal_code.toUpperCase();
		this.updatePaymentCardInfo();
	}

	/**
	 * Emit event to parent to update the payment information
	 *
	 * @return {void}
	 */
	private updatePaymentCardInfo(): void {
		this.$emit('set-payment-card-info', this.mutablePaymentCard);
	}

	/**
	 * Emit event to parent to update the save payment method data property
	 *
	 * @return {void}
	 */
	private updateSavePaymentMethod(): void {
		this.$emit('set-save-payment-method-flag', this.mutableSavePaymentMethodOnCheckout);
	}

	/**
	 * Scroll to the bottom when opening a selector
	 *
	 * @param {string} elementId
	 * @return {void}
	 */
	private opened(elementId: string): void {
		setTimeout(() => {
			let contentDiv = document.querySelector(`#${elementId} .vs__dropdown-menu`);
			contentDiv && scrollIntoViewIfNeeded(contentDiv, { behavior: 'smooth', block: 'end', inline: 'start' });
		}, 100);
	}

	/**
	 * Go to profile
	 *
	 * @return {void}
	 */
	private goToProfile(): void {
		this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {});
	}
}
