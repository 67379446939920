export function valueSortByNumber (a: any, b: any, property: string, asc: boolean = true): number {
	let x = typeof a === 'object' ? a[property] : a;
	let y = typeof b === 'object' ? b[property] : b;
	x = parseFloat(x);
	y = parseFloat(y);

	return asc
		? x < y ? -1 : (x > y ? 1 : 0)
		: x < y ? (x > y ? 0 : 1) : - 1;
}

export function generateNonce(length: number): string {
	const characters: string = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
	let result: string = '';
	for ( let i = 0; i < length; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return result;
}

/**
 * Re order option groups depending on their order property.
 * We also order their values (options) by their order property if provided, otherwise by price.
 *
 * @param {OrderOptionGroup[]} optionGroups
 * @return {OrderOptionGroup[]}
 */
export function sortOrderOptions(optionGroups: OrderOptionGroup[]): OrderOptionGroup[] {
	const tempOptionGroups: OrderOptionGroup[] = [...optionGroups];
	tempOptionGroups.sort((a: OrderOptionGroup, b: OrderOptionGroup) => {
		if (a.optionGroupType === 'pricing' && b.optionGroupType !== 'pricing') {
			return -1;
		} else if (a.optionGroupType !== 'pricing' && b.optionGroupType === 'pricing') {
			return 1;
		} else {
			return a.order - b.order;
		}
	});

	tempOptionGroups.forEach((orderOptionGroup: OrderOptionGroup) => {
		if (orderOptionGroup.values) {
			if(orderOptionGroup.values.some(option => option.order )) {
				orderOptionGroup.values.sort((a: OrderOption, b: OrderOption) => valueSortByNumber(a, b, 'order'));
			}
			else {
				orderOptionGroup.values.sort((a: OrderOption, b: OrderOption) => valueSortByNumber(a, b, 'price'));
			}
		}
	});

	return tempOptionGroups;
}