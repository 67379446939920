
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<MenuResultsHeader>({})
export default class MenuResultsHeader extends Vue {
	@Prop({ type: Object, required: false, default: () => {} }) private element!: Menu | MenuGroup;
	@Prop({ type: String, required: false, default: '' }) private restaurantDisplayName!: string;
	@Prop({ type: String, required: false, default: '' }) private restaurantImage!: string;
	@Prop({ type: String, required: false, default: '' }) private imageUrl!: string;
}
