
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { setTemporaryOption, formatTemporarySelectedOrderOption } from '@/utils/format';
import { getElementBySkuOrId } from '@/utils/helpers';
import { calculateOptionQuantityThatCanBeAdded } from '@/utils/reordering';
import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
import MinusIcon from 'vue-feather-icons/icons/MinusIcon';
import QuantitySelector from './QuantitySelector.vue';
import SubAddOns from './SubAddOns.vue';

const namespace: string = 'cart';

@Component<AddOns>({
	components: {
		PlusIcon,
		MinusIcon,
		QuantitySelector,
		SubAddOns
	}
})
export default class AddOns extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private menuItem!: MenuItem;
	@Prop({ type: Boolean, required: true, default: false }) private isViewOnly!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private showErrorMessages!: boolean;
	@Action('updateOrderOptions', { namespace }) private updateOrderOptions!: (options: any) => void;
	@Action('updateTemporaryItemPrice', { namespace }) private updateTemporaryItemPrice!: (prices: Prices) => void;
	@Getter('hideItemsSpecialInstructions', { namespace: 'restaurant' }) private hideItemsSpecialInstructions!: boolean;
	@Watch('menuItem')
	onMenuItemChanged() {
		this.options = [];
		this.requiredOptionsFilled();
		let checkboxes: HTMLCollectionOf<HTMLInputElement> = document.getElementsByTagName('input');
		for (let index = 0; index < checkboxes.length; index++) {
			checkboxes[index].checked = checkboxes[index].id === 'checkbox-default-pricing';
		}
		this.resetOptions();
	}

	private specialInstructions: string = '';
	private requiredOptions = new Set();
	private options: any[] = [];
	private areSubOptionsPreselected: boolean = false;

	/**
	 * Set the required options filled to true/false on mounted
	 *
	 * @return {void}
	 */
	private mounted(): void {
		this.requiredOptionsFilled();
		this.resetOptions();
		if (this.menuItem.lastOrderedOptions) {
			this.selectLastOrderedOptions();
		}
	}

	/**
	 * Get the last ordered sub options for a given item's option
	 *
	 * @param {Option} option
	 * @return {LastOrderedItem[] | undefined}
	 */
	private getLastOrderedSubOptions(option: Option): LastOrderedItem[] | undefined {
		const lastOrderedOption = this.menuItem.lastOrderedOptions?.find(({ id, sku }: LastOrderedItem) => getElementBySkuOrId(option, id, sku));
		return lastOrderedOption?.items;
	}

	/**
	 * Reset options checked values.
	 * Select any options that are set as a default selection
	 *
	 * @return {void}
	 */
	private resetOptions(): void {
		if(this.menuItem.options) {
			this.menuItem.options.map((optionGroup: OptionGroup) => {
				if(optionGroup.values && optionGroup.values.length) {
					optionGroup.values.forEach((option: Option) => {
						if(option.checked) {
							Vue.set(option, 'checked', false);
							Vue.set(option, 'quantity', 1);
						}
						if(option.default_selection && !this.menuItem.lastOrderedOptions) {
							Vue.nextTick(() => this.selectOption(optionGroup, option, true));
						}
					});
				}
			});
		}
	}

	/**
	 * Select options that were in the previous order.
	 * Manually check the checkboxes and update the quantity.
	 *
	 * @return {void}
	 */
	private selectLastOrderedOptions(): void {
		if (this.menuItem.options) {
			this.menuItem.options.forEach((optionGroup: OptionGroup) => {
				if (optionGroup.values?.length) {
					let selectedOptionsCounter: number = 0;
					for (const option of optionGroup.values) {
						const lastOrderedOption = this.menuItem.lastOrderedOptions?.find(({ id, sku }: LastOrderedItem) => getElementBySkuOrId(option, id, sku));
						if (lastOrderedOption) {
							// Skip if the last ordered item has a non selected default option (option with 0 quantity)
							if (lastOrderedOption.quantity <= 0) {
								continue;
							}
							this.selectOption(optionGroup, option, true);
							const checkbox = optionGroup.allow_multiple_selection
								? document.getElementById(`${optionGroup.name}-checkbox-${option.id}`) as HTMLInputElement
								: document.getElementById(`${optionGroup.name}-${optionGroup.id}-checkbox-single-${option.id}`) as HTMLInputElement;
							if (checkbox) {
								checkbox.checked = true;
							}

							// Set option quantity while making sure it doesn't exceed the limit and max quantity
							if (lastOrderedOption.quantity > 1) {
								const quantity = calculateOptionQuantityThatCanBeAdded(optionGroup, option, lastOrderedOption.quantity, selectedOptionsCounter);
								this.updateOptionQuantity(optionGroup, option, 'number', quantity);
								selectedOptionsCounter += quantity;
							}
							else {
								selectedOptionsCounter++;
							}
							// Make sure we don't select more options than the limit allows
							if (optionGroup.allow_multiple_selection && typeof optionGroup.limit === 'number' && selectedOptionsCounter >= optionGroup.limit) {
								break;
							}
						}
					};
				}
			});
		}
	}

	/**
	 * Send event to the parent to update the special instructions
	 *
	 * @return {void}
	 */
	private updateSpecialInstructions(event: any): void {
		this.specialInstructions = event.target.value;
		this.$emit('input', this.specialInstructions);
	}

	/**
	 * Update options with the sub options selected
	 *
	 * @param {any} event
	 * @return {void}
	 */
	private updateSubOptions(event: any): void {
		const optionGroupIndex = this.options.findIndex(optionGroup => optionGroup.id === event.optionGroup.id);
		const optionIndex = this.options[optionGroupIndex].values.findIndex((option: Option) => option.id === event.option.id);
		this.options[optionGroupIndex].values[optionIndex].options = event.subOptions;
		this.updateOrderOptions(this.options);
		this.requiredOptionsFilled();
	}

	/**
	 * Change selected state of the options and add to the vuex options selected.
	 * First check the option type if it's pricing/addon. If pricing replace
	 * the price with the value. If addon add to the price and checks if multiple
	 * selections are allowed.
	 *
	 * @param {OptionGroup} optionGroup - the group option
	 * @param {Option} option - the value of chosen of the group option
	 * @param {boolean} checked
	 * @return {void}
	 */
	private selectOption(optionGroup: OptionGroup, option: Option, checked: boolean): void {
		if (!checked) {
			option.quantity = option.default_selection ? 0 : 1;
		}
		if (option.quantity === 0 && checked) {
			option.quantity = 1;
		}
		let tempOptionGroup: OrderOptionGroup = setTemporaryOption(optionGroup, option);

		// Pricing
		if(optionGroup.type === 'pricing') {
			this.selectedPricingOption(tempOptionGroup, optionGroup, checked);
		}

		// Add-ons
		else {
			this.selectedAddOnOption(tempOptionGroup, optionGroup, option, checked);
		}

		// Remove or add required options depending on selected state
		if (this.requiredOptions.size) {
			if (checked) {
				this.requiredOptions.delete(optionGroup.id);
			} else {
				if (!this.options.some(selectedOptionGroup => selectedOptionGroup.id === optionGroup.id)) {
					this.requiredOptions.add(optionGroup.id);
				}
			}
		}
		this.updateOrderOptions(this.options);
		this.requiredOptionsFilled();
	}

	/**
	 * Update the quantity of the option
	 *
	 * @param {OptionGroup} optionGroup
	 * @param {Option} option
	 * @param {string} type - increment, decrement, number
	 * @param {number} [quantity] - the quantity to update to
	 * @return {void}
	 */
	private updateOptionQuantity(optionGroup: OptionGroup, option: Option, type: 'increment' | 'decrement' | 'number', quantity?: number): void {
		if (type === 'number' && quantity) {
			if (quantity <= 0) {
				this.selectOption(optionGroup, option, false);
			}
			// Make sure the quantity is not beyond the option max quantity or option group limit
			else {
				let actualQuantity = quantity;
				if (typeof option.max_quantity === 'number' && actualQuantity > option.max_quantity) {
					actualQuantity = option.max_quantity;
				}
				if (typeof optionGroup.limit === 'number' && optionGroup.limit > 0 && actualQuantity > optionGroup.limit) {
					actualQuantity = optionGroup.limit;
				}
				// TODO: Take into account previous options selected and their quantity in the limit calculation
				option.quantity = actualQuantity;
				let tempOptionGroup: OrderOptionGroup = setTemporaryOption(optionGroup, option);
				this.selectedAddOnOption(tempOptionGroup, optionGroup, option, true);
				this.updateOrderOptions(this.options);
				this.requiredOptionsFilled();
			}
		}
		// When the quantity is 1 and the quantity is decremented, this should be the same as unselecting the option,
		// so we use selectOption to remove the option from the options array
		else if (type === 'decrement' && option.quantity === 1) {
			this.selectOption(optionGroup, option, false);
		}
		else {
			type === 'increment' ? option.quantity++ : option.quantity--;

			let tempOptionGroup: OrderOptionGroup = setTemporaryOption(optionGroup, option);
			this.selectedAddOnOption(tempOptionGroup, optionGroup, option, true);
			this.updateOrderOptions(this.options);
			this.requiredOptionsFilled();
		}
	}

	/**
	 * Default pricing option selected, clearing all pricing options selected.
	 *
	 * @param {OrderOptionGroup} tempOptionGroup
	 * @param {OptionGroup} optionGroup - the group option
	 * @param {boolean} checked
	 * @return {void}
	 */
	private selectDefaultPricing(menuItem: MenuItem, optionGroup: OptionGroup, checked: boolean): void {

		if(checked) {
			this.unselectRadioButton(optionGroup);
			this.updateTemporaryItemPrice({ price: this.menuItem.price, memberPrice: this.menuItem.memberPrice });
			this.options = this.options.filter((opt: OptionGroup) => {
				return opt.id !== optionGroup.id;
			});
		}
		this.updateOrderOptions(this.options);
		this.requiredOptionsFilled();
	}

	/**
	 * Pricing option selected, filter out past values if there was any
	 * and then we add the option to the options array.
	 *
	 * @param {OrderOptionGroup} tempOptionGroup
	 * @param {OptionGroup} optionGroup - the group option
	 * @param {boolean} checked
	 * @return {void}
	 */
	private selectedPricingOption(tempOptionGroup: OrderOptionGroup, optionGroup: OptionGroup, checked: boolean): void {
		if(checked && tempOptionGroup.values) {

			// Remove previous selection if there was any
			if(Number(this.menuItem.price) > 0 && this.menuItem.price_type && this.menuItem.price_type.length) {
				(document.getElementById('checkbox-default-pricing') as HTMLInputElement).checked = false;
			}
			this.unselectRadioButton(optionGroup);
			if(+tempOptionGroup!.values[0].price! > 0) {
				this.updateTemporaryItemPrice({ price: tempOptionGroup!.values[0].price!.toString(), memberPrice: tempOptionGroup!.values[0].memberPrice });
			}
			this.options = this.options.filter((opt: OptionGroup) => {
				return opt.id !== optionGroup.id;
			});
			this.options.push(tempOptionGroup);
		}
		else {
			this.options = this.options.filter((opt: OptionGroup) => {
				return opt.id !== optionGroup.id;
			});
			this.updateTemporaryItemPrice({ price: this.menuItem.price, memberPrice: this.menuItem.memberPrice });
		}
	}

	/**
	 * Unselect radio buttons from the same type if there is already one selected
	 *
	 * @param {OptionGroup} optionGroup - the group option
	 * @return {void}
	 */
	private unselectRadioButton(optionGroup: OptionGroup | OrderOptionGroup): void {
		let prevSelectedOptions = this.options.filter((opt: OptionGroup) => {
			return opt.id === optionGroup.id;
		});
		if(prevSelectedOptions.length) {
			// Value
			let prevElement = prevSelectedOptions[0];
			let prevElementInput: HTMLInputElement = (document.getElementById(`${prevElement.name}-${prevElement.id}-checkbox-single-${prevElement.values[0].id}`) as HTMLInputElement);
			prevElementInput.checked = false;
		}
	}

	/**
	 * Add-ons selected, lots of caveats for those, read other comments.
	 *
	 * @param {OrderOptionGroup} tempOptionGroup
	 * @param {OptionGroup} optionGroup - the group option
	 * @param {Option} option - the value of chosen of the group option
	 * @param {boolean} checked - the value of chosen of the group option
	 * @return {void}
	 */
	private selectedAddOnOption(tempOptionGroup: OrderOptionGroup, optionGroup: OptionGroup, option: Option, checked: boolean): void {
		if(optionGroup.allow_multiple_selection) {
			// Check/Uncheck to show/hide suboptions
			option.checked = checked;
			this.multipleOptionGroupValueSelected(tempOptionGroup, optionGroup, option);
		}
		else {
			// Unchecked all others to hide suboptions
			optionGroup.values!.map((value: Option) => {
				Vue.set(value, 'checked', false);

				// Reset quantity to 1 if the option is not the one selected
				if (value.id !== option.id) {
					Vue.set(value, 'quantity', 1);
				}
			});
			this.singleOptionGroupValueSelected(tempOptionGroup, optionGroup, option, checked);
		}
	}

	/**
	 * Multiple option group value selected
	 *
	 * @param {any} tempOptionGroup
	 * @param {any} optionGroup - the group option
	 * @param {any} option - the value of chosen of the group option
	 * @return {void}
	 */
	private multipleOptionGroupValueSelected(tempOptionGroup: OrderOptionGroup, optionGroup: OptionGroup, option: Option): void {
		const index = this.options.findIndex(item => item.id === optionGroup.id);
		let optionIndex = -1;

		/**
		 * If there are already values for that group, we have to check
		 * if the value was also added, if it was we change its boolean,
		 * if it wasn't we add it to the group array.
		 */
		if(index > -1) {
			let valueExist: boolean = false;
			for (let i = 0; i < this.options[index].values.length; i++) {
				if(this.options[index].values[i].id === option.id) {
					// Remove the option if it was unchecked
					if (!option.checked) {
						if (option.default_selection) {
							this.options[index].values[i].quantity = 0;
						}
						else {
							this.options[index].values.splice(i, 1);
						}
					}
					optionIndex = i;
					valueExist = true;
				}
			}
			// If the option isn't in the options array, add it if the limit isn't reached
			if (!valueExist) {
				if(optionGroup.limit && this.optionGroupAtLimit(index, optionGroup)) {
					// Set the html attribute checked to false, to prevent checkbox from visually being checked
					(document.getElementById(`${optionGroup.name}-checkbox-${option.id}`) as HTMLInputElement).checked = false;

					// Set the checked property in the option object
					const menuItemOptions = this.menuItem.options!.find((menuItemOption: OptionGroup) => menuItemOption.id === optionGroup.id);
					menuItemOptions!.values!.find((menuItemOption: Option) => menuItemOption.id === option.id)!.checked = false;
					alert(this.$t('menu.item_viewer.addons.error_max_exceeded', { optionGroupLimit: optionGroup.limit }));
				}
				else {
					this.options[index].values.push(formatTemporarySelectedOrderOption(option));
				}
			}
			// If the option is in the options array and the quantity is being changed, update the quantity if the option group limit isn't reached
			else if (this.options[index].values[optionIndex]) {
				const prevQuantity = this.options[index].values[optionIndex].quantity;
				if (option.quantity > prevQuantity) {
					if (this.optionGroupAtLimit(index, optionGroup)) {
						alert(this.$t('menu.item_viewer.addons.error_max_exceeded', { optionGroupLimit: optionGroup.limit }));
						option.quantity--;

						// Set the html attribute checked to false, to prevent checkbox from visually being checked
						if (option.default_selection) {
							Vue.nextTick(() => (document.getElementById(`${optionGroup.name}-checkbox-${option.id}`) as HTMLInputElement).checked = false);
						}
					}
					else {
						this.options[index].values[optionIndex].quantity = option.quantity;
					}
				}
				else if (option.quantity < prevQuantity && prevQuantity > 1) {
					this.options[index].values[optionIndex].quantity = option.quantity;
				}
			}

			// Remove the option from this.options if there are no remaining selected values
			if (!this.options[index].values.length) {
				this.options.splice(index, 1);
			}
		}

		// If there are no values for that group we simply add it to the options array
		else if (!this.optionGroupAtLimit(index, optionGroup)){
			this.options.push(tempOptionGroup);
		}
	}

	/**
	 * Check if the option group is at its limit
	 * This checks the number of options selected and the quantity of each option
	 *
	 * @param {number} index - the index of the option group in the options array
	 * @param {OptionGroup} optionGroup
	 * @return {boolean}
	 */
	private optionGroupAtLimit(index: number, optionGroup: OptionGroup): boolean {
		if (this.options[index] && optionGroup.limit > 0) {
			const optionsSelected = this.options[index].values.reduce((accumulator: number, currentValue: Option) => {
				return accumulator + currentValue.quantity;
			}, 0);
			return optionsSelected >= optionGroup.limit;
		}
		return false;
	}

	/**
	 * Single selection, just have to filter out the past values if there
	 * was any and then we add the option to the options array.
	 *
	 * @param {OrderOptionGroup} tempOptionGroup
	 * @param {OrderOptionGroup} optionGroup - the group option
	 * @param {Option} chosenOption - the value of chosen of the group option
	 * @param {boolean} checked
	 * @return {void}
	 */
	private singleOptionGroupValueSelected(tempOptionGroup: OrderOptionGroup, optionGroup: OrderOptionGroup | OptionGroup, chosenOption: Option, checked: boolean): void {
		if(checked && tempOptionGroup.values) {
			// Check flag to show sub options
			Vue.set(chosenOption, 'checked', true);
			const selectedOption = this.options.length ? this.options.find((opt: Option) => opt.id === optionGroup.id)?.values[0] : null;

			// If the option quantity is being updated, don't unselect the radio button and update the quantity
			if(selectedOption && selectedOption.id === chosenOption.id && selectedOption.quantity !== chosenOption.quantity) {
				selectedOption.quantity = chosenOption.quantity;

				// Update tempOptionGroup with the new values with the updated quantity
				tempOptionGroup = { ...tempOptionGroup, values: [selectedOption] }
			}
			else {
				this.unselectRadioButton(optionGroup);
			}
		}
		// Remove the option group from the options (we don't care about the old value since it's single selection)
		this.options = this.options.filter((opt: OptionGroup) => {
			return opt.id !== optionGroup.id;
		});
		this.addDefaultOptionAndPushToTheOptionsArray(tempOptionGroup, checked);
	}

	/**
	 * Add the default option to radio options with quantity of 0 if it's not selected
	 *
	 * @param {OrderOptionGroup} tempOptionGroup
	 * @return {void}
	 */
	private addDefaultOptionAndPushToTheOptionsArray(tempOptionGroup: OrderOptionGroup, checked: boolean): void {
		const menuItemOption = this.menuItem.options!.find((menuItemOption: OptionGroup) => menuItemOption.id === tempOptionGroup.id);
		const defaultSelection = menuItemOption!.values!.find((menuItemOption: Option) => menuItemOption.default_selection);

		// If the user decided to uncheck an option, it means the tempOptionGroup has to be reset before we add the default selections
		if(!checked) {
			tempOptionGroup.values = [];
		}

		// Check for if option group has a default selection. If they are not already in the options array, we need to include them
		// in order to show them as "removed" to the end user (this is why the quantity 0)
		if (defaultSelection) {
			tempOptionGroup.values = tempOptionGroup.values ?? [];
			if (!tempOptionGroup.values.some((value: OrderOption) => value.id === defaultSelection.id)) {
				tempOptionGroup.values.push(formatTemporarySelectedOrderOption({ ...defaultSelection, quantity: 0 }));
			}
		}

		if(tempOptionGroup.values?.length) {
			this.options.push(tempOptionGroup);
		}
	}

	/**
	 * Checks if all the required options were filled.
	 *
	 * @return {boolean}
	 */
	private requiredOptionsFilled(): any {
		this.requiredOptions = new Set();

		// Populate requiredOptions with the names of required option groups that do not have selections
		if(this.menuItem.options!.length) {
			for (let index = 0; index < this.menuItem.options!.length; index++) {
				if(this.menuItem.options![index].required) {
					// Check that the option group has values and that at least one value has a quantity greater than 0 (unselected default options have a quantity of 0 but are still present in the options array)
					if (!this.options.some((option => (option.id === this.menuItem.options![index].id) && option.values.length && option.values.some((value: Option) => value.quantity > 0)))) {

						// Pricing check (gotta check if default was checked at least)
						if (Number(this.menuItem.price) > 0 && this.menuItem.price_type && this.menuItem.price_type.length && this.menuItem.options![index].type === 'pricing') {
							if(!(document.getElementById('checkbox-default-pricing') as HTMLInputElement).checked) {
								this.requiredOptions.add(this.menuItem.options![index].id);
							}
						}
						else {
							this.requiredOptions.add(this.menuItem.options![index].id);
						}
					}
				}

				this.requiredSubOptionsFilled(index);
			}
			return this.$emit('requiredOptionsFilled', !this.requiredOptions.size);
		}
		else {
			return this.$emit('requiredOptionsFilled', true);
		}
	}

	/**
	 * Checks if all the required sub options were filled.
	 *
	 * @return {boolean}
	 */
	private requiredSubOptionsFilled(index: number): void {
		// We check if there are sub options and if they are required, if so, one of the values needs to be checked as well
		const optionGroupIndex = this.options.findIndex(optionGroup => optionGroup.id === this.menuItem.options![index].id);
		this.menuItem.options![index].values!.forEach((option: Option) => {
			// Check if the menu item options contains nested options
			if(option.checked && option.options && option.options.length) {
				option.options.forEach((subOptionGroup: OptionGroup) => {
					if(subOptionGroup.required) {

						// Since the sub option group is required, we have to make sure one of its values were selected
						if(this.options && this.options.length) {
							this.options[optionGroupIndex].values.forEach((orderOption: Option) => {
								if(!orderOption.options) {
									if(orderOption.id === option.id) {

										// Before adding the required option, we double check if the option group
										// has values at least, this protects with weird data.json values
										if(subOptionGroup.values && subOptionGroup.values.length) {
											this.requiredOptions.add(subOptionGroup.id);
										}
									}
								}
								else {
									if(!orderOption.options.length) {
										if(orderOption.id === option.id) {

											// Before adding the required option, we double check if the option group
											// has values at least, this protects with weird data.json values
											if(subOptionGroup.values && subOptionGroup.values.length) {
												this.requiredOptions.add(subOptionGroup.id);
											}
										}
									}
								}
							});
						}
					}
				});
			}
		});
	}
}
