import i18n from '@/i18n';
/**
 * Filter provinces from short_name to long_name because
 * autocomplete return the long_name only in the array object
 * without variables so it's more optimal to have a switch case
 * with the short_name than to loop through the 8 values returned
 *
 * @param {string} provinceShortName
 * @return {string}
 */
export function filterProvince(provinceShortName: string): string {
	switch(provinceShortName.toLowerCase()) {
	case 'ab':
		return i18n.t('utils.province_filters.ab');
	case 'bc':
		return i18n.t('utils.province_filters.bc');
	case 'mb':
		return i18n.t('utils.province_filters.mb');
	case 'nb':
		return i18n.t('utils.province_filters.nb');
	case 'nl':
		return i18n.t('utils.province_filters.nl');
	case 'ns':
		return i18n.t('utils.province_filters.ns');
	case 'nt':
		return i18n.t('utils.province_filters.nt');
	case 'on':
		return i18n.t('utils.province_filters.on');
	case 'pe':
		return i18n.t('utils.province_filters.pe');
	case 'qc':
		return i18n.t('utils.province_filters.qc');
	case 'sk':
		return i18n.t('utils.province_filters.sk');
	case 'yt':
		return i18n.t('utils.province_filters.yt');
	default:
		return '';
	}
}

/**
 * Checks if the item matches the filters selected
 *
 * @param {string} itemsTags
 * @param {string[]} selectedFilters
 * @return {boolean}
 */
export function matches(itemsTags: string, selectedFilters: string[]): boolean {
	if (itemsTags == null) {
		return false;
	}
	const tags = itemsTags.split(',');
	return tags.some((value: any) => selectedFilters.includes(value));
}

/**
 * Filter the items based on diets and allergens selected
 * *Important to note allergens work in the negative
 * TODO REFACTOR
 *
 * @param {string[]} selectedDiets
 * @param {string[]} selectedAllergens
 * @param {MenuItem[]} items
 * @returns {MenuItem[]}
 */
export function filterItemsWithAllergensAndDiets(selectedDiets: string[], selectedAllergens: string[], items: MenuItem[]): MenuItem[] {
	const mutableItems: MenuItem[] = [...items];

	return mutableItems.filter((item: any) => {
		if (selectedDiets.length && selectedAllergens.length) {
			return (matches(item.diets, selectedDiets) && !matches(item.allergens, selectedAllergens));
		} else if (selectedDiets.length) {
			return matches(item.diets, selectedDiets);
		} else {
			return !matches(item.allergens, selectedAllergens);
		}
	});
}