
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { Debounce } from '@/utils/decorators';
import { formatPhoneNumber } from '@/utils/format';
import EyeIcon from 'vue-feather-icons/icons/EyeIcon';
import EyeOffIcon from 'vue-feather-icons/icons/EyeOffIcon';
import ToggleSwitch from '@/components/shared/ToggleSwitch.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import '@/validation-rules';

const namespace: string = 'cart';

@Component<CheckoutContactInformation>({
	components: {
		EyeIcon,
		EyeOffIcon,
		ValidationProvider,
		ToggleSwitch,
		Tooltip
	}
})
export default class CheckoutContactInformation extends Vue {
	@Action('verifyIfUserEnrolledInLoyaltyProgram', { namespace }) private verifyIfUserEnrolledInLoyaltyProgram!: (payload: object) => void;
	@Action('setLoyaltyProgramStatus', { namespace }) private setLoyaltyProgramStatus!: (status: string) => void;
	@Getter('getContactInformation', { namespace }) private contactInformation!: CheckoutContactInfo;
	@Getter('getLoyaltyProgramUserStatus', { namespace }) private loyaltyProgramUserStatus!: string | null;
	@Getter('isTakeOut', { namespace }) private isTakeOut!: boolean;
	@Getter('isAnonymousUser', { namespace: 'auth' }) private isAnonymousUser!: boolean;
	@Getter('isLoyaltyProgramOn', { namespace: 'restaurant' }) private isLoyaltyProgramOn!: string;
	@Getter('getHideGuestInfo', { namespace: 'restaurant' }) private hideGuestInfo!: HideGuestInfo | null;
	@Watch('createAccount')
	onPasswordToggle() {
		if (!this.createAccount) {
			this.contact.password = '';
			this.updateContactInfo();
		}
	}
	@Watch('contact.phone_number')
	onPhoneNumberInput(value: string, prevValue: string): void {
		// If the user typed more than 1 character in 1 input, could be autofill
		// TODO: Need a better way to handle autofill/autocomplete and area codes in the future
		this.formatPhoneNumber((value.length - prevValue.length) > 1);
	}
	private emailVerified: boolean = false;
	private createAccount: boolean = false;
	private passwordVisible: boolean = false;
	private contact: CheckoutContactInfo = {
		full_name: '',
		email: '',
		phone_number: '',
		password: ''
	};

	private get shouldHide(): { [key: string]: boolean } {
		return {
			email: !!(!this.isTakeOut && this.isAnonymousUser && this.hideGuestInfo?.email),
			phone: !!(!this.isTakeOut && this.isAnonymousUser && this.hideGuestInfo?.phone)
		}
	}

	/**
	 * Set the temporary contact information to the
	 * vuex store values if there are any to prevent
	 * refilling the same information again.
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if (this.contactInformation) {
			this.contact = this.contactInformation;

			if(this.contactInformation.email) {
				this.verifyUserIdentityWithEmail(false);
			}

			if (this.contact.password) {
				this.createAccount = true;
			}
		}
	}

	/**
	 * Format the phone number to ###-###-#### format
	 *
	 * @param {boolean} mayBeAutofill - is the input potentially from autofill
	 * @return {void}
	 */
	private formatPhoneNumber(mayBeAutofill: boolean): void {
		this.contact.phone_number = formatPhoneNumber(this.contact.phone_number, mayBeAutofill);
		this.updateContactInfo();
	}

	/**
	 * Verify the user identity. At the moment this is to detect if
	 * the user is part of a loyalty program (TEMPORARY HARDCODED FOR BOWLERO).
	 * We also do not want to send a call if the status was already
	 * saved.
	 *
	 * @param {boolean} emailInput - if the email was changed
	 * @return {void}
	 */
	@Debounce(200)
	private async verifyUserIdentityWithEmail(emailInput: boolean): Promise<void> {
		this.emailVerified = false;
		if(this.isLoyaltyProgramOn) {
			const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(emailRegex.test(this.contact.email.toLowerCase())) {
				await this.verifyIfUserEnrolledInLoyaltyProgram({ email: this.contact.email, emailChanged: emailInput });
				this.emailVerified = true;
			}
		}
	}

	/**
	 * Set the loyalty program status
	 *
	 * @param {boolean} value
	 * @return {void}
	 */
	private updateLoyaltyProgramStatus(value: boolean) {
		this.setLoyaltyProgramStatus(value ? 'new-member' : 'non-member');
	}

	/**
	 * Send event to the parent to update the contact
	 * information in the vuex store
	 *
	 * @return {void}
	 */
	private async updateEmail(): Promise<void> {
		await this.verifyUserIdentityWithEmail(true);
		this.updateContactInfo();
	}

	/**
	 * Send event to the parent to update the contact
	 * information in the vuex store
	 *
	 * @return {void}
	 */
	private updateContactInfo(): void {
		this.$emit('input', this.contact);
	}
}
