
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import { fillCart } from '@/utils/reordering';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';

const namespace = 'cart';

@Component<Receipt>({})
export default class Receipt extends Vue {
	@Action('setTemporaryItem', { namespace }) private setTemporaryItem!: (temporaryItemPayload: { tempItem: MenuItem|{}, quantity?: number }) => void;
	@Getter('getItems', { namespace }) private getCartItems!: OrderItem[];
	@Getter('getMaxItemsPerCart', { namespace: 'restaurant' }) private maxItemsPerCart!: number;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Prop({ type: String, required: true, default: '' }) private id!: string;
	@Prop({ type: Boolean, required: false, default: false }) private isAnyLoading!: boolean;
	@Prop({ type: Object, required: true, default: () => {} }) private receipt!: ReceiptInfo;

	private showDetails: boolean = false;
	private isLoading: boolean = false;

	private receiptAmount: number =
		(this.receipt.subtotal || 0)
		+ (this.receipt.serviceCharge || 0)
		+ (this.receipt.preTaxDiscount || 0)
		+ (this.receipt.posPreTaxDiscount || 0)
		+ (this.receipt.tax || 0)
		+ (this.receipt.deliveryCharge || 0)
		+ (this.receipt.payment || 0);

	private get isCurrentLocation(): boolean {
		if (this.restaurant?.app8_restaurant && this.receipt.restaurantId && this.restaurant?.app8_restaurant === this.receipt.restaurantId ) {
			return true;
		}
		return false;
	}

	/**
	 * Format the date of the order's receipt
	 *
	 * @param {string} utcDate
	 * @return {string}
	 */
	private formatDate(utcDate: string): string {
		const date = DateTime.fromISO(utcDate).toFormat('D');
		const time = DateTime.fromISO(utcDate).toFormat('t');
		return `${date} • ${time}`;
	}

	/**
	 * Format negative numbers
	 *
	 * @param {number} amount
	 * @return {string}
	 */
	private formatNegativeAmount(amount: number): string {
		return Math.abs(amount).toFixed(2);
	}

	/**
	 * Show or hide receipt details
	 * and scroll to the position of that receipt
	 *
	 * @return {void}
	 */
	private toggleShowReceipt(): void {
		this.showDetails = !this.showDetails;
		if (this.showDetails) {
			setTimeout(() => {
				const receiptElement = document.getElementById('receipt-' + this.id);
				receiptElement && receiptElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
			}, 100);
		}
	}

	/**
	 * Add the items from the receipt to the cart if possible.
	 *
	 * @return {Promise<void>}
	 */
	private async reorderItems(): Promise<void> {
		// If cart is already full, stop here
		const itemsInCartBefore: number = this.getCartItems.reduce((sum, item) => sum += item.quantity, 0);
		if (itemsInCartBefore === this.maxItemsPerCart) {
			this.$toasted.show(this.$t('profile.past_receipts.error_cart_full'), { type: 'error', position: 'top-center' }).goAway(5000);
			return;
		}

		this.isLoading = true;
		this.$emit('loading', true);

		const changeWarningFlag: boolean = await fillCart(this.receipt.items, itemsInCartBefore);

		setTimeout(() => {
			this.isLoading = false;
			this.setTemporaryItem({ tempItem: {}});
			this.$emit('loading', false);
		}, 500)

		// Check if we actually added items
		const itemsInCartAfter: number = this.getCartItems.reduce((sum, item) => sum += item.quantity, 0);
		const itemsAddedToCart: number = itemsInCartAfter - itemsInCartBefore;
		const itemsInReceipt: number = this.receipt.items.reduce((sum, item) => sum += item.quantity, 0);
		const itemsNotAddedToCart: number = itemsInReceipt - itemsAddedToCart;

		fireGoogleTag({
			name: 'reorderCart',
			detail: `${itemsAddedToCart} succeed`,
			specifier: `${itemsNotAddedToCart} attempt`
		});
		if (itemsAddedToCart === 0) {
			this.$toasted.show(this.$t('profile.past_receipts.error_reorder_items'), { type: 'error', position: 'top-center' }).goAway(5000);
			return;
		}

		this.redirectToMenu(changeWarningFlag);
	}

	/**
	 * Redirect to the menu with the cart open, and a warning modal about the changes if applicable
	 *
	 * @param {boolean} changeWarning
	 * @return {void}
	 */
	private redirectToMenu(changeWarning: boolean): void {
		const queryParams: { [key: string]: any } = { ...this.$route.query, openCart: 'true' };
		if (changeWarning) {
			queryParams.reorderWarning = 'true';
		}
		this.$router.push({ path: `/${this.restaurant?.slug}`, query: queryParams }).catch(() => {});
	}
}
