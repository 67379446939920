
import VueMeta from 'vue-meta';
import { Vue, Component } from 'vue-property-decorator';

Vue.use(VueMeta);

@Component<Index>({
	metaInfo() {
		return {
		};
	}
})
export default class Index extends Vue {

	/**
	 * If the user is on a-table.ca, redirect to /cantine. This is the expected
	 * behavior that Compass requested. On any other domains, like our default one,
	 * we redirect to page not found.
	 *
	 * @return {void}
	 */
	private mounted(): void {
		const url: string = window.location.href;
		if (url.includes('a-table.ca')) {
			this.$router.replace({ path: '/cantine', query: this.$route.query }).catch(() => {});
		} else {
			this.$router.replace({ name: 'PageNotFound' }).catch(() => {});
		}
	}
}
