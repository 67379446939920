
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import DeliveryDefault from './delivery/DeliveryDefault.vue';
import DeliveryStadium from './delivery/DeliveryStadium.vue';
import DeliverySchool from './delivery/DeliverySchool.vue';
import DeliveryCustom from './delivery/DeliveryCustom.vue';

const namespace: string = 'cart';

@Component<CheckoutDeliveryInformation>({
	components: {
		DeliveryDefault,
		DeliveryStadium,
		DeliverySchool,
		DeliveryCustom
	}
})
export default class CheckoutDeliveryInformation extends Vue {
	@Prop({ type: String, required: false, default: '' }) private coordinatesErrorMsg!: string;
	@Getter('getDeliveryInformation', { namespace }) private deliveryInformation!: CheckoutDeliveryInfo | null;
	@Getter('getRestaurantDeliveryInfo', { namespace: 'restaurant' }) private restaurantDeliveryInfo!: DefaultDelivery | StadiumDelivery | SchoolDelivery | CustomDelivery;

	/**
	 * Send event to the parent to update the delivery
	 * information in the vuex store
	 *
	 * @param {DefaultDelivery | StadiumDelivery | SchoolDelivery | CustomDelivery} delivery
	 * @return {void}
	 */
	private updateDeliveryInfo(delivery: DefaultDelivery | StadiumDelivery | SchoolDelivery | CustomDelivery): void {
		this.$emit('input', delivery);
	}
}
