
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const namespace: string = 'cart';

@Component<ErrorValidationInformation>({})

export default class ErrorValidationInformation extends Vue {
	@Prop({ type: Array, required: false, default: () => [] }) private buttonsText!: string[];
	@Prop({ type: Array, required: false, default: () => [] }) private events!: string[];
	@Prop({ type: Object, required: false, default: () => {} }) private payload!: any;
	@Prop({ type: Boolean, required: false, default: false }) private showSecondButton!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private cart!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private legalCheck!: boolean;
	@Action('setLegal', { namespace }) private setLegal!: (value: boolean) => void;
	@Getter('getErrorValidationInfo', { namespace }) private errorValidation!: ErrorValidation;
	private takeoutUrl: string = window.location.href.split(/[?#]/)[0].replace(/\/$/, '') + '?takeout=true';

	/**
	 * If the item is unavailable we want to display a custom error message
	 *
	 * @param {string} message
	 * @return {void}
	 */
	private isItemUnavailableOrPriceChanged(errorCode: number): boolean {
		if(errorCode === 426 || errorCode === 427 || errorCode === 428) {
			return true;
		}

		return false;
	}

	/**
	 * Refresh the website
	 *
	 * @return {void}
	 */
	private refresh(): void {
		window.location.reload(false);
	}

	/**
	 * Submit whatever event that was passed as a prop that
	 * the parent will listen to.
	 *
	 * @param {string} event - event name to listen to
	 * @param {any} payload - any values needing to be passed with the event
	 * @return {void}
	 */
	private submitEvent(event: string, payload: any): void {
		this.$emit(event, payload);
	}

	/**
	 * Set legal check
	 *
	 * @return {void}
	 */
	private setLegalCheck(value: boolean) {
		this.setLegal(value);
	}
}
