import { MutationTree } from 'vuex';
import { AuthState } from '../types';
import { state as cartState } from '../cart/cart';
import { state as restaurantState } from '../restaurant/restaurant';
import Vue from 'vue';
import Cookies from 'js-cookie';

export const mutations: MutationTree<AuthState> = {

	SET_AUTHENTICATED(state: AuthState, authenticated: boolean): void {
		Vue.set(state, 'authenticated', authenticated);
	},

	SET_WELCOME_BANNER(state: AuthState, show: boolean): void {
		Vue.set(state, 'showWelcomeBanner', show);
	},

	SET_LOGIN_AS_BANNER(state: AuthState, show: boolean): void {
		Vue.set(state, 'showLoginAsBanner', show);
	},

	SET_TOKEN(state: AuthState, token: string): void {
		Vue.set(state.user, 'token', token);
	},

	SET_USER_ID(state: AuthState, userId: string): void {
		Vue.set(state.user, 'id', userId);
	},

	SET_OPERATOR_TOKEN(state: AuthState, token: string): void {
		Vue.set(state.suiteOperator, 'token', token);
	},

	SET_OPERATOR_USER_ID(state: AuthState, userId: string): void {
		Vue.set(state.suiteOperator, 'id', userId);
	},

	SET_LOGIN_AS(state: AuthState, loginAs: boolean): void {
		Vue.set(state, 'loginAs', loginAs);
		Vue.set(state, 'suiteOperatorLogin', loginAs);
	},

	SET_SUITE_OPERATOR_LOGIN(state: AuthState, suiteOperatorLogin: boolean): void {
		Vue.set(state, 'suiteOperatorLogin', suiteOperatorLogin);
	},

	/**
	 * Reset cart checkout info
	 * Set user info in user and checkout
	 * and set the access token and userId in cookies and local storage for silent login
	 *
	 * @param {AuthState} state
	 * @param {UserInfo} userPayload
	 * @return {void}
	 */
	SET_USER(state: AuthState, userPayload: UserInfo): void {
		Vue.set(state, 'user', userPayload);
		Vue.set(cartState.checkout, 'contact', {
			full_name: userPayload.firstName + ' ' + userPayload.lastName,
			phone_number: userPayload.phoneNumber,
			email: userPayload.email
		});

		Cookies.set('accessToken', state.user.token);
		localStorage.setItem('accessToken', state.user.token);
		Cookies.set('userId', state.user.id);
		localStorage.setItem('userId', state.user.id);
	},

	SET_SUITE_OPERATOR(state: AuthState, operatorPayload: UserInfo): void {
		Vue.set(state, 'suiteOperator', operatorPayload);
		Vue.set(state, 'suiteOperatorLogin', operatorPayload.suiteOperator);

		Cookies.set('operatorAccessToken', state.suiteOperator.token);
		localStorage.setItem('operatorAccessToken', state.suiteOperator.token);
		Cookies.set('operatorUserId', state.suiteOperator.id);
		localStorage.setItem('operatorUserId', state.suiteOperator.id);
	},

	/**
	 * Edit user info in user and checkout
	 *
	 * @param {AuthState} state
	 * @param {UserInfo} userPayload
	 * @return {void}
	 */
	EDIT_USER(state: AuthState, userPayload: UserInfo): void {
		Vue.set(state, 'user', {
			...state.user,
			...userPayload
		});
		Vue.set(cartState.checkout, 'contact', {
			...cartState.checkout.contact,
			full_name: userPayload.firstName + ' ' + userPayload.lastName,
			phone_number: userPayload.phoneNumber
		});
	},

	/**
	 * Set the membership program info
	 *
	 * @param {CartState} state
	 * @param {CheckoutMemberInfo} payload
	 * @return {void}
	 */
	SET_MEMBER_INFO(state: AuthState, payload: CheckoutMemberInfo): void {
		Vue.set(cartState.checkout, 'memberInfo', payload);
		Vue.set(cartState.checkout.memberInfo!, 'promosApplied', new Set<string>());

		// Set the contact info from what we can with the ELB hit only if anonymous user.
		if(!state.user.id && cartState.checkout.memberInfo && cartState.checkout.memberInfo.phone && cartState.checkout.contact) {
			if(!cartState.checkout.contact.full_name) {
				cartState.checkout.contact.full_name = cartState.checkout.memberInfo.firstName ? `${cartState.checkout.memberInfo.firstName} ` : '';
				cartState.checkout.contact.full_name += cartState.checkout.memberInfo.lastName ? `${cartState.checkout.memberInfo.lastName}` : '';
			}
			if(!cartState.checkout.contact.email) {
				cartState.checkout.contact.email = cartState.checkout.memberInfo.email ? cartState.checkout.memberInfo.email : '';
			}
		}
	},

	/**
	 * Set the payment methods of the user
	 *
	 * @param {AuthState} state
	 * @param {SavedPaymentOption[]} payload
	 * @return {void}
	 */
	SET_PAYMENT_METHODS(state: AuthState, payload: SavedPaymentOption[]): void {
		Vue.set(state.user, 'paymentMethods', payload);
	},

	/**
	 * Set the last items ordered by the user
	 *
	 * @param {AuthState} state
	 * @param {LastOrderedItem[]} payload
	 * @return {void}
	 */
	SET_LAST_ITEMS_ORDERED(state: AuthState, payload: LastOrderedItem[]): void {
		Vue.set(state.user, 'lastItemsOrdered', payload);
	},

	/**
	 * Clear user info in the auth and cart states
	 * Remove cookies and local storage
	 *
	 * @param {AuthState} state
	 * @return {void}
	 */
	LOG_USER_OUT(state: AuthState): void {
		Cookies.remove('userId');
		Cookies.remove('accessToken');
		Cookies.remove(`${restaurantState.restaurant.slug}-member`);
		Cookies.remove('legal');
		Cookies.remove('name');
		Cookies.remove('email');
		Cookies.remove('phone');
		Cookies.remove('postalCode');

		localStorage.removeItem('userId');
		localStorage.removeItem('accessToken');

		Vue.set(state, 'authenticated', false);
		Vue.set(state, 'showWelcomeBanner', false);
		Vue.set(state, 'user', {
			token: '',
			firstName: '',
			lastName: '',
			email: '',
			id: '',
			paymentMethods: []
		});
	},

	/**
	 * Clear suite operator info in the auth state and remove cookies and local storage
	 *
	 * @param state
	 */
	LOG_OPERATOR_OUT(state: AuthState): void {
		Cookies.remove('operatorUserId');
		Cookies.remove('operatorAccessToken');

		localStorage.removeItem('operatorUserId');
		localStorage.removeItem('operatorAccessToken');

		Vue.set(state, 'authenticated', false);
		Vue.set(state, 'suiteOperator', {
			token: '',
			firstName: '',
			lastName: '',
			email: '',
			id: '',
			paymentMethods: []
		});
	}
};