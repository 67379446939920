
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<MenuDropdownItem>({})
export default class MenuDropdownItem extends Vue {
	@Prop({ type: Object, required: true }) private element!: Menu | MenuGroup;
	@Prop({ type: Boolean, required: false, default: false }) private activeElement!: boolean;
	@Prop({ type: String, required: false, default: 'list' }) private browseMenuStyle!: string;
	@Prop({ type: Number, required: true }) private idx!: number;
	private showImage: boolean = true;

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.element.image}`;
		}
		return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.element.image}`;
	}

	private async created() {
		// Check if image is broken, if it is, hide it.
		if(this.element.image) {
			let img = new Image();
			let vm = this;
			img.onerror = () => {
				vm.showImage = false;
			};
			img.src = this.imageURL;
		}
	}

	/**
	 * Select the correct menu or menu group and send the event to the parent
	 * to make the respective update
	 *
	 * @param {number} idx
	 * @param {string} elementName
	 * @return {void}
	 */
	private selectElement(idx: number, elementName: string): void {
		this.$emit('select-element', { 'idx': idx, 'elementName': elementName });
	}
}

