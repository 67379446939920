import { MutationTree } from 'vuex';
import { LayoutState } from '../types';

export const mutations: MutationTree<LayoutState> = {

	/**
	 * Update tab bar
	 *
	 * @param {LayoutState} state
	 * @param {boolean} isDisplayed
	 * @return {void}
	 */
	SET_TAB_BAR(state: LayoutState, isDisplayed: boolean): void {
		state.tabBar = isDisplayed;
	}
};