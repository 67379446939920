
import { Vue, Component, Prop } from 'vue-property-decorator';
import PlusCircleIcon from '@/assets/images/icons/plus-circle.svg?inline';

@Component<CartUpsellItemCard>({ components: { PlusCircleIcon } })
export default class CartUpsellItemCard extends Vue {
	@Prop({ type: Object, required: true }) private item!: MenuItem;
	@Prop({ type: Number, required: true }) private itemIndex!: number;

	private showImage: boolean = false;

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.item.image}`;
		}
		return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.item.image}`;
	}

	private created(): void {
		if (this.item.image) {
			let img = new Image();
			let vm = this;
			img.onerror = () => {
				vm.showImage = false;
			};
			img.onload = () => {
				vm.showImage = true;
			};
			img.src = this.imageURL;
		}
	}

	/**
	 * Add the item to the cart if it has no options, otherwise show the item modal
	 *
	 * @return {Promise<void>}
	 */
	private async onAddClick(): Promise<void> {
		this.$emit(this.item.options?.length  && this.item.options.some(option => option.values?.length) ? 'show-details' : 'add');
	}
}
