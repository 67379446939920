import store from '../store';
import { formatTemporarySelectedOrderOption, setTemporaryOption } from './format';
import { getElementBySkuOrId } from './helpers';

/**
 * Loop through the sections items and menu items of each menu to find the item with the matching SKU.
 *
 * @param {string} sku
 * @param {number} [id]
 * @return {MenuItem | undefined}
 */
function getItemFromMenu(sku: string, id?: number): MenuItem | undefined {
	let menuItem;
	for (const menu of store.getters['restaurant/getFilteredMenus'] as Menu[]) {
		// Section item
		for (const section of menu.sections) {
			menuItem = section.items.find((item) => getElementBySkuOrId(item, id, sku));
			if (menuItem) {
				return menuItem;
			}
		};

		// Menu item
		menuItem = menu?.items.find((item) => getElementBySkuOrId(item, id, sku));
		if (menuItem) {
			return menuItem;
		}
	}
	return menuItem;
}

/**
 * Calculate quantity of the item we can add, depending of the cart limit and current items in cart
 *
 * @param {number} prevOrderedItemQuantity
 * @param {number} numberOfItemsInCart
 * @return {number}
 */
function calculateItemQuantityThatCanBeAdded(prevOrderedItemQuantity: number, numberOfItemsInCart: number): number {
	const maxItemsPerCart: number = store.getters['restaurant/getMaxItemsPerCart'];
	let itemQuantity: number = prevOrderedItemQuantity;
	if (maxItemsPerCart > 0) {
		if (itemQuantity > maxItemsPerCart) {
			itemQuantity = maxItemsPerCart;
		}
		if (itemQuantity + numberOfItemsInCart > maxItemsPerCart) {
			itemQuantity = maxItemsPerCart - numberOfItemsInCart;
		}
	}
	return itemQuantity;
}

/**
 * Loop through the option groups of an item to set the options of a previously ordered item (receipt or order data).
 * Raise a warning if changes were made to the option.
 * Raise a skip flag if a required option is missing.
 *
 * @param {OptionGroup[]} itemOptions
 * @param {ReceiptSubItem[] | OrderOptionGroup[]} prevOrderedItemOptions
 * @return {{ options: OrderOptionGroup[]; warning: boolean; skip: boolean; }}
 */
function setOptions(itemOptions: OptionGroup[], prevOrderedItemOptions: ReceiptSubItem[]|OrderOptionGroup[]): { options: OrderOptionGroup[]; warning: boolean; skip: boolean; } {
	const options: OrderOptionGroup[]= [];
	let warningFlag: boolean = false;
	let skipFlag: boolean = false;
	for (const optionGroup of itemOptions) {
		if (optionGroup.values?.length) {
			let selectedOptionsCounter: number = 0;
			let tempOptionGroup: OrderOptionGroup | undefined = undefined;
			for (const option of optionGroup.values) {
				const prevOrderedItemOption: OrderOption | undefined = findItemOption(option, prevOrderedItemOptions);
				if (prevOrderedItemOption) {
					// Calculate the quantity of the option
					if ((prevOrderedItemOption.quantity as number) <= 0) {
						continue;
					}
					if ((prevOrderedItemOption.quantity as number) > 1) {
						const quantity: number = calculateOptionQuantityThatCanBeAdded(optionGroup, option, (prevOrderedItemOption.quantity as number), selectedOptionsCounter);
						if (quantity < (prevOrderedItemOption.quantity as number)) {
							warningFlag = true;
						}
						selectedOptionsCounter += quantity;
						option.quantity = quantity;
					}
					else {
						selectedOptionsCounter++;
					}

					// Set option group and its option if it's the first loop and tempOptionGroup is still undefined
					if (!tempOptionGroup) {
						tempOptionGroup = setTemporaryOption(optionGroup, option);
					}

					// Pricing (we don't have to check for undefined values because pricing always have 1 value)
					if (optionGroup.type === 'pricing' && +tempOptionGroup!.values![0].price! > 0) {
						store.dispatch('cart/updateTemporaryItemPrice', { price: tempOptionGroup!.values![0].price!.toString(), memberPrice: tempOptionGroup!.values![0].memberPrice });
					}

					// Add-ons
					else {
						// Multi-selection
						if (optionGroup.allow_multiple_selection && tempOptionGroup.values?.[0].id !== option.id) {
							// Add the option value to the other ones if possible
							tempOptionGroup.values!.push(formatTemporarySelectedOrderOption(option));
						}
						// Sub-modifiers
						if (option.options?.length) {
							const subOptionsResult = setSubOptions(option, prevOrderedItemOption);
							skipFlag = subOptionsResult?.skip || skipFlag;
							warningFlag = subOptionsResult?.warning || warningFlag;
							if (skipFlag) {
								break;
							}
							const optionIndex = tempOptionGroup.values?.findIndex(({ id }: OrderOption) => id === option.id) as number;
							if (tempOptionGroup.values) {
								tempOptionGroup.values[optionIndex].options = subOptionsResult?.subOptions || [];
							}
						}
						// Break if single selection now that we added its sub-options
						if (!optionGroup.allow_multiple_selection) {
							break;
						}
						// Make sure we don't select more options than the limit allows
						if (optionGroup.allow_multiple_selection && typeof optionGroup.limit === 'number' && optionGroup.limit > 0 && selectedOptionsCounter >= optionGroup.limit) {
							break;
						}
					}
				}
			}

			if (skipFlag) {
				break;
			}

			// If a required option is missing, don't add the item to the cart
			if ((!tempOptionGroup || !tempOptionGroup.values || !tempOptionGroup.values.length) && optionGroup.required) {
				return { options: [], warning: true, skip: true };
			}

			// Check if the option group has default options, add them if they're not selected (quantity of 0)
			tempOptionGroup = addNonSelectedDefaultOptions(optionGroup, tempOptionGroup);

			// Add the option group and update the item's order options
			if (tempOptionGroup?.values?.length) {
				options.push(tempOptionGroup);
			}
		}
	}
	return { options, warning: warningFlag, skip: skipFlag}
}

/**
 * Go through the sub-options of an item's option to add/select the ones from the receipt or previous order data if possible.
 * Raise a warning if changes were made to the sub-options.
 * Raise a skip flag if a required sub-option is missing.
 *
 * @param {Option} option
 * @param {ReceiptSubItem | OrderOption} prevOrderedItemOption
 * @return {{ subOptions: OrderOptionGroup[]; warning: boolean; skip: boolean; } | undefined}
 */
function setSubOptions(option: Option, prevOrderedItemOption: OrderOption | ReceiptSubItem): { subOptions: OrderOptionGroup[]; warning: boolean; skip: boolean; } | undefined {
	if (option.options?.length) {
		let warningFlag: boolean = false;
		const subOptions: OrderOptionGroup[] = [];
		for (const subOptionGroup of option.options) {
			if (subOptionGroup.values?.length) {
				let tempSubOptionGroup: OrderOptionGroup | undefined = undefined;
				let selectedOptionsCounter: number = 0;
				for (const subOption of subOptionGroup.values) {
					// Check if the sub-option value was in the item
					if (findItemSubOption(prevOrderedItemOption, subOption)) {
						// Multiple sub-options selection
						if (subOptionGroup.allow_multiple_selection) {
							// Make sure we don't select more subOptions than the limit allows
							if (typeof subOptionGroup.limit === 'number' && subOptionGroup.limit > 0 && selectedOptionsCounter >= subOptionGroup.limit) {
								break;
							}
							// Add the sub-option value to the other ones if possible
							if (tempSubOptionGroup?.values?.length) {
								tempSubOptionGroup.values.push(formatTemporarySelectedOrderOption(subOption));
							}
							// Set the sub-option group with that sub-option value
							else {
								tempSubOptionGroup = setTemporaryOption(subOptionGroup, subOption);
							}
							selectedOptionsCounter++;
						}
						// Single selection
						else {
							tempSubOptionGroup = setTemporaryOption(subOptionGroup, subOption);
							break
						}
					}
				}

				// Raise skip item flag if missing required sub-option
				if ((!tempSubOptionGroup || !tempSubOptionGroup.values || !tempSubOptionGroup.values.length) && subOptionGroup.required) {
					return { subOptions: [], warning: true, skip: true };
				}

				tempSubOptionGroup = addNonSelectedDefaultOptions(subOptionGroup, tempSubOptionGroup);

				// Add the sub-optionGroup to the array
				if (tempSubOptionGroup) {
					subOptions.push(tempSubOptionGroup)
				}
			}
		}
		// Check if we were able to find all the options from the item or not before updating the order options
		if (subOptions.length) {
			const optionGroupValueTotal: number = subOptions.reduce((sum, optGroup) => sum + (optGroup.values?.length || 0), 0);
			let optionsCountInReceiptItems: number;

			// Logic for receipts
			if ('items' in prevOrderedItemOption) {
				optionsCountInReceiptItems = prevOrderedItemOption.items.length;
			}
			else {
				optionsCountInReceiptItems = prevOrderedItemOption.options?.reduce((sum, optGroup) => sum + (optGroup.values?.length || 0), 0) || 0;
			}

			warningFlag = optionsCountInReceiptItems !== optionGroupValueTotal;
		}
		return { subOptions, warning: warningFlag, skip: false };
	}
}

/**
 * Check if the option value was in the item's options,
 * while handling the different of structure between the receipts and the order data items.
 *
 * @param {Option} option
 * @param {OrderOptionGroup[] | ReceiptSubItem[]} itemOptions
 * @return {OrderOption | ReceiptSubItem | undefined}
 */
function findItemOption(option: Option, itemOptions: OrderOptionGroup[] | ReceiptSubItem[]): OrderOption | ReceiptSubItem | undefined {
	// Logic for receipts
	if ('items' in itemOptions[0]) {
		return (itemOptions as ReceiptSubItem[]).find(({ sku }: ReceiptSubItem) => getElementBySkuOrId(option, undefined, sku));
	}
	// Logic for order data
	const prevOrderedItemOptionGroup = (itemOptions as OrderOptionGroup[]).find(optionGroup =>
		optionGroup.values?.some(({ id, sku }: OrderOption) => getElementBySkuOrId(option, id, sku))
	);
	return prevOrderedItemOptionGroup?.values?.find(({ id, sku }: OrderOption) => getElementBySkuOrId(option, id, sku));
}

/**
 * Check if the sub-option value was in the item's option,
 * while handling the different of structure between the receipts and the order data items.
 *
 * @param {OrderOption | ReceiptSubItem} itemOption
 * @param {Option} subOption
 * @return {boolean}
 */
function findItemSubOption(itemOption: OrderOption | ReceiptSubItem, subOption: Option): boolean {
	// Logic for receipts
	if ('items' in itemOption) {
		return itemOption.items.some(({ sku }: ReceiptSubItem) => getElementBySkuOrId(subOption, undefined, sku));
	}
	// Logic for order data
	return itemOption.options?.some(optionGroup =>
		optionGroup.values?.some(({ id, sku }: OrderOption) => getElementBySkuOrId(subOption, id, sku))
	) || false;
}

/**
 * Add the non selected default option to the group
 *
 * @param {OptionGroup} optionGroup - The original option group to check if there are default selection options
 * @param {OrderOptionGroup|undefined} tempOptionGroup - The order option group being built, can be undefined if no option was selected
 * @return {OrderOptionGroup|undefined} Can be undefined if no option was selected, and the option group has no default selection
 */
function addNonSelectedDefaultOptions(optionGroup: OptionGroup, tempOptionGroup: OrderOptionGroup|undefined): OrderOptionGroup | undefined {
	let tempOptionGroupCopy: OrderOptionGroup|undefined = structuredClone(tempOptionGroup);
	const defaultSelections: Option[] = optionGroup.values!.filter((subOptionValue: Option) => subOptionValue.default_selection);

	if (defaultSelections.length) {
		defaultSelections.forEach((defaultOption: Option) => {
			if (tempOptionGroupCopy) {
				if (!tempOptionGroupCopy.values!.some((optionValue: OrderOption) => optionValue.id === defaultOption.id)) {
					tempOptionGroupCopy.values!.push(formatTemporarySelectedOrderOption({ ...defaultOption, quantity: 0 }));
				}
			}
			else {
				tempOptionGroupCopy = setTemporaryOption(optionGroup, { ...defaultOption, quantity: 0 });
			}
		})
	}
	return tempOptionGroupCopy;
}

/**
 * Calculate quantity of the option we can add, depending of the option's max quantity and the option group's limit
 *
 * @param {OptionGroup} optionGroup
 * @param {Option} option
 * @param {number} prevOrderedItemOptionQuantity
 * @param {number} numberOfOptionsAlreadySelected
 * @return {number}
 */
export function calculateOptionQuantityThatCanBeAdded(optionGroup: OptionGroup, option: Option, prevOrderedItemOptionQuantity: number, numberOfOptionsAlreadySelected: number): number {
	let quantity = prevOrderedItemOptionQuantity;
	if (typeof option.max_quantity === 'number' && quantity > option.max_quantity) {
		quantity = option.max_quantity;
	}
	if (typeof optionGroup.limit === 'number' && optionGroup.limit > 0 && quantity > optionGroup.limit) {
		quantity = optionGroup.limit;
	}
	// If the quantity and the qty of the previous selected options are greater than the limit
	if (typeof optionGroup.limit === 'number' && optionGroup.limit > 0 && (quantity + numberOfOptionsAlreadySelected) > optionGroup.limit) {
		quantity = optionGroup.limit - numberOfOptionsAlreadySelected;
	}
	return quantity;
}

/**
 * Check if all the options from the previously oredered item were found or not.
 * Filter out the unselected default options and compare the length of items.
 *
 * @param {OrderOptionGroup[]} options
 * @param {ReceiptSubItem[] | OrderOptionGroup[]} prevOrderedItemSubItems
 * @return {boolean}
 */
function checkIfAllOptionsAdded(options: OrderOptionGroup[], prevOrderedItemSubItems: ReceiptSubItem[] | OrderOptionGroup[]): boolean {
	const optionGroupValueTotal: number = options.reduce((sum, optGroup) => {
		const validValues = optGroup.values?.filter(value => value.quantity && value.quantity >= 0) ?? [];
		return sum + validValues.length;
	}, 0)

	let optionsCountInReceiptItems: number = 0;

	// Logic for order data
	if (isOrderDataItems(prevOrderedItemSubItems)) {
		optionsCountInReceiptItems = prevOrderedItemSubItems.reduce((sum, subItem) => {
			const optGroup = subItem as OrderOptionGroup;
			if (!optGroup.memo) {
				const validValues = optGroup.values?.filter(value => value.quantity && value.quantity > 0) ?? [];
				return sum + validValues.length;
			}
			return sum;
		}, 0);
	}
	// Logic for receipts
	else {
		optionsCountInReceiptItems = prevOrderedItemSubItems.reduce((sum: number, subItem: ReceiptSubItem | OrderOptionGroup) => {
			const receiptSubItem = subItem as ReceiptSubItem;
			return !receiptSubItem.memo && receiptSubItem.quantity! > 0 ? sum + 1 : sum;
		}, 0);
	}

	return optionsCountInReceiptItems === optionGroupValueTotal;
}

/**
 * Loop through the previously ordered items (and options/sub-options) and add them to the cart if possible.
 *
 * @param {OrderDataItem[] | ReceiptItem[]} previouslyOrderedItems
 * @param {number} [itemsAlreadyInCart=0]
 * @return {Promise<boolean>}
 */
export async function fillCart(previouslyOrderedItems: OrderDataItem[] | ReceiptItem[], itemsAlreadyInCart: number = 0): Promise<boolean> {
	let itemsInCart: number = itemsAlreadyInCart;
	let changeWarningFlag: boolean = false;

	// Loop through each item and find them in the section/menu
	for (const prevItem of previouslyOrderedItems) {
		if (
			('id' in prevItem && !prevItem.sku && !prevItem.id)
			|| (!('id' in prevItem) && !prevItem.sku)
			|| prevItem.quantity <= 0
		) {
			changeWarningFlag = true;
			continue;
		}
		const menuItem: MenuItem | undefined = getItemFromMenu(prevItem.sku!, 'id' in prevItem ? prevItem.id : undefined);
		if (!menuItem || menuItem.sold_out) {
			changeWarningFlag = true;
			continue;
		}

		const itemQuantity: number = calculateItemQuantityThatCanBeAdded(prevItem.quantity, itemsInCart);
		itemsInCart += itemQuantity;
		if (itemQuantity < prevItem.quantity) {
			changeWarningFlag = true;
		}

		store.dispatch('cart/setTemporaryItem', { tempItem: menuItem, quantity: itemQuantity });

		let skipItemFlag: boolean = false;

		// Modifiers
		if (menuItem.options) {
			const optionsResult = setOptions(menuItem.options, isOrderDataItem(prevItem) ? prevItem.orderOptions : prevItem.items);
			const options: OrderOptionGroup[]= optionsResult.options;
			skipItemFlag = optionsResult.skip || skipItemFlag;
			changeWarningFlag = optionsResult.warning || changeWarningFlag;

			// Skip the item if a required option is missing and remove its quantity from the cart
			if (skipItemFlag) {
				itemsInCart -= itemQuantity;
				continue;
			}

			// Check if we were able to find all the options from the item or not before updating the order options
			if (options.length) {
				if (!changeWarningFlag) {
					if (!isOrderDataItem(prevItem)) {
						changeWarningFlag = !checkIfAllOptionsAdded(options, (prevItem.items) as ReceiptSubItem[]);
					}
					else {
						changeWarningFlag = !checkIfAllOptionsAdded(options, prevItem.orderOptions);
					}
				}
				store.dispatch('cart/updateOrderOptions', options);
			}
		}

		// Add item to cart
		const extraData = {
			originalPrice: menuItem.price,
			type: isOrderDataItem(prevItem) ? 'anotherRound' : 'reorder',
			special_instructions: isOrderDataItem(prevItem)
				? prevItem.orderOptions.find((option: OrderOptionGroup) => option.memo)?.values?.[0]?.name || ''
				: prevItem.items.find((option: ReceiptSubItem) => option.memo)?.name || ''
		};

		try {
			await store.dispatch('cart/addToCart', extraData);
		}
		// Skip the item if error from server (menu unavailable, etc.)
		catch (error) {
			changeWarningFlag = true;
			itemsInCart -= itemQuantity;
			continue;
		}

		// Stop if we reach the cart limit
		if (itemsInCart === store.getters['restaurant/getMaxItemsPerCart']) {
			break;
		}
	}

	return changeWarningFlag;
}

/**
 * Type guard to check if the previously ordered item is an OrderDataItem or ReceiptItem
 *
 * @param {ReceiptItem | OrderDataItem} item
 * @return {boolean}
 */
function isOrderDataItem(item: ReceiptItem | OrderDataItem): item is OrderDataItem {
	return 'orderOptions' in item && Array.isArray(item.orderOptions);
}

/**
 * Type guard to check if the array is an array of OrderOptionGroup or ReceiptSubItem.
 *
 * @param {ReceiptSubItem[] | OrderOptionGroup[]} arr
 * @return {boolean}
 */
function isOrderDataItems(arr: ReceiptSubItem[] | OrderOptionGroup[]): arr is OrderOptionGroup[] {
	return arr.length > 0 && 'values' in arr[0];
}