import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { GeolocationState, RootState } from '../types';
const namespaced: boolean = true;

export const state: GeolocationState = {
	geolocationResults: null,
	geolocationPayload: {
		lat: 0,
		lng: 0,
		radius: 10,
		group: 'bowlero', // only bowlero uses geo search for now
		unit: 'mi',
		name: '',
		address: '',
		gmapPlaceId: ''
	},
	country: ['us', 'ca'],
	geolocationPermission: 'prompt',
	showDistance: true
};

export const Menus: Module<GeolocationState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Menus;