
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import XIcon from 'vue-feather-icons/icons/XIcon';

const namespace: string = 'filters';

@Component<FiltersBar>({
	components: {
		XIcon
	}
})
export default class FiltersBar extends Vue {
	@Action('toggleDiet', { namespace }) private toggleDiet!: (index: number) => void;
	@Action('toggleAllergens', { namespace }) private toggleAllergens!: (index: number) => void;
	@Action('clearFilters', { namespace }) private clear!: () => void;
	@Getter('getDiets', { namespace }) private dietOptions!: any[];
	@Getter('getAllergens', { namespace }) private allergenOptions!: any[];

	/**
	 * Return selected diets/allergens filters
	 *
	 * @param {any} filters
	 * @return {boolean}
	 */
	private selected(filters: any): boolean {
		return filters.filter((filter: any) => {
			if (filter.selected) {
				return true;
			}
		});
	}

	/**
	 * Update diet filters
	 *
	 * @param {any} filter
	 * @return {void}
	 */
	private changeDiet(filter: any): void {
		for (let index = 0; index < this.dietOptions.length; index++) {
			if (filter.value == this.dietOptions[index].value) {
				this.toggleDiet(index);
			}
		}
	}

	/**
	 * Update allergen filters
	 *
	 * @param {any} filter
	 * @return {void}
	 */
	private changeAllergens(filter: any): void {
		for (let index = 0; index < this.allergenOptions.length; index++) {
			if (filter.value == this.allergenOptions[index].value) {
				this.toggleAllergens(index);
			}
		}
	}

	/**
	 * Clear filters
	 *
	 * @param {any} filter
	 * @return {void}
	 */
	private clearFilters(): void {
		this.clear();
	}
}
