
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import '@/validation-rules';
const namespace: string = 'cart';

@Component<CartDiscount>({
	components: {
		ValidationObserver,
		ValidationProvider
	}
})

export default class CartDiscount extends Vue {
	@Action('validateDiscountCodeAndGetValue', { namespace }) private validateDiscountCodeAndGetValue!: (object: any) => void;
	@Getter('getPromoDiscountInfo', { namespace }) private promoDiscountInfo!: any;

	$refs!: {observer: Validate}

	private discountCode: string = '';
	private discountMessage: string = '';
	private validatingCode: boolean = false;

	/**
	 * Get discount code if it was applied before
	 *
	 * @return {void}
	 */
	private created(): void {
		if(this.promoDiscountInfo && this.promoDiscountInfo.code) {
			this.discountCode = this.promoDiscountInfo.code;
			this.discountMessage = this.$t('cart.discount.code_applied');
		}
	}

	/**
	 * Validate and apply discount to the subtotal
	 *
	 * @return {void}
	 */
	private async applyDiscount(): Promise<void> {
		let isValid = await this.$refs.observer.validate();

		if(isValid) {
			this.discountMessage = '';
			try {
				this.validatingCode = true;
				await this.validateDiscountCodeAndGetValue(this.discountCode);
				this.discountMessage = this.$t('cart.discount.code_applied');
			} catch (err) {
				this.discountMessage = this.$t('cart.discount.code_failed');
			} finally {

				// Give some time to the loader to prevent flashing (response coming back too fast)
				setTimeout(() => {
					this.validatingCode = false;
				}, 500);
				this.discountCode = this.promoDiscountInfo.code;
			}
		}
	}
}
