
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { formatTakeoutDateTime, formatDateTime } from '@/utils/format';
import { DateTime } from 'luxon';
import Modal from '@/components/shared/Modal.vue';
import InputModalContainer from '@/components/shared/InputModalContainer.vue';
import InfoBanner from '@/components/shared/InfoBanner.vue';
import GooglePayIcon from '@/assets/images/icons/google-pay-icon.svg?inline';
import ApplePayIcon from '@/assets/images/icons/apple-pay-icon.svg?inline';
import EditIcon from '@/assets/images/icons/edit.svg?inline';
const namespace: string = 'cart';

@Component<CheckoutReviewUserDetails>({
	components: {
		Modal,
		InputModalContainer,
		InfoBanner,
		GooglePayIcon,
		ApplePayIcon,
		EditIcon
	}
})
export default class CheckoutReviewUserDetails extends Vue {
	@Getter('getPaymentMethod', { namespace }) private paymentMethod!: string;
	@Getter('getPickupInformation', { namespace }) private pickupInformation!: CheckoutPickupInfo | null;
	@Getter('getSuitesInformation', { namespace }) private suitesInformation!: CheckoutSuitesInfo | null;
	@Getter('getDeliveryInformation', { namespace }) private deliveryInformation!: CheckoutDeliveryInfo | null;
	@Getter('getContactInformation', { namespace }) private contactInformation!: CheckoutContactInfo;
	@Getter('getCardInformation', { namespace }) private cardInformation!: CheckoutCardInfo;
	@Getter('getInvoiceInformation', { namespace }) private invoiceInformation!: boolean;
	@Getter('getCostCenterInformation', { namespace }) private costCenterInformation!: string | null;
	@Getter('getPurchaseOrderInformation', { namespace }) private purchaseOrderInformation!: string | null;
	@Getter('isTakeOut', { namespace }) private isTakeOut!: boolean;
	@Getter('isDelivery', { namespace }) private isDelivery!: boolean;
	@Getter('isGenericCatering', { namespace }) private isGenericCatering!: boolean;
	@Getter('getChosenCreditCard', { namespace }) private chosenCreditCard!: SavedPaymentOption;
	@Getter('getCreditCards', { namespace: 'auth' }) private creditCards!: SavedPaymentOption[];
	@Getter('isAnonymousUser', { namespace: 'auth' }) private isAnonymousUser!: boolean;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Getter('noTakeoutTimeslot', { namespace: 'restaurant' }) private noTakeoutTimeslot!: boolean;
	@Getter('getHideGuestInfo', { namespace: 'restaurant' }) private hideGuestInfo!: HideGuestInfo | null;

	private cardModalOpened: boolean = false;

	private get isPreOrderDeliveryTimeValidISOString(): boolean {
		return !!this.suitesInformation?.deliveryTime && DateTime.fromISO(this.suitesInformation?.deliveryTime!).isValid;
	}

	private get shouldHide(): { [key: string]: boolean } {
		return {
			email: !!(!this.isTakeOut && this.isAnonymousUser && this.hideGuestInfo?.email),
			phone: !!(!this.isTakeOut && this.isAnonymousUser && this.hideGuestInfo?.phone)
		}
	}

	/**
	 * Format the pickup/pre-order date&time to human value
	 *
	 * @param {boolean} takeout
	 * @param {string} date
	 * @return {string}
	 */
	private formatLocalDate(takeout: boolean, date?: string): string {
		if(takeout) {
			return formatTakeoutDateTime(this.pickupInformation!);
		}
		return formatDateTime(date!);
	}

	/**
	 * Format credit card to just have the last 4 digits
	 *
	 * @param {string} creditCardInfo
	 * @return {string}
	 */
	private formatCreditCard(creditCardInfo: string): string {
		let creditCardInfoFormatted = creditCardInfo.replace(/.(?=.{4})/g, '');
		return creditCardInfoFormatted;
	}

	/**
	 * Format phone number with dashes in respective areas
	 *
	 * @param {string} phoneNumber
	 * @return {string}
	 */
	private formatPhoneNumber(phoneNumber: string): string {
		return phoneNumber.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-');
	}

	/**
	 * Show the change payment method button or not
	 *
	 * @return {void}
	 */
	private showPaymentMethodChangeButton(): boolean {
		if(this.paymentMethod) {
			if(!this.isAnonymousUser && this.creditCards.length <= 1) {
				return false;
			}
			return true;
		}

		return false;
	}

	/**
	 * Send event to the parent to switch to corresponding screen
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private switchTo(index: number): void {
		this.$emit('switchTo', index);
	}
}
