
import { Vue, Component, Prop } from 'vue-property-decorator';
import MenuCardHorizontal from '@/components/menu/cards/MenuCardHorizontal.vue';
import MenuCardVertical from '@/components/menu/cards/MenuCardVertical.vue';
import capitalize from 'lodash/capitalize';

@Component<TextMenu>({
	components: {
		MenuCardHorizontal,
		MenuCardVertical
	}
})
export default class TextMenu extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private menuItem!: MenuItem;
	@Prop({ type: String, required: false, default: 'vertical' }) private cardStyle!: string;

	private showImage: boolean = true;
	private cardComponent: string = `MenuCard${capitalize(this.cardStyle)}`;

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.menuItem.image}`;
		}
		return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.menuItem.image}`;
	}

	private async created() {
		// Check if image is broken, if it is, hide it.
		if(this.menuItem.image) {
			let img = new Image();
			let vm = this;
			img.onerror = () => {
				vm.showImage = false;
			};
			img.src = this.imageURL;
		}
	}

	/**
	 * Open the item viewer
	 *
	 * @return {void}
	 */
	private clickHandler(): void {
		this.$emit('on-click', this.menuItem);
	}
}
