
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import Modal from '@/components/shared/Modal.vue';
import ErrorValidationInformation from '@/components/shared/ErrorValidationInformation.vue';
import CheckoutForm from './checkout/Form.vue';
import CheckoutReview from './checkout/Review.vue';
import CheckoutConfirmation from './checkout/Confirmation.vue';
import BannerContent from '@/components/shared/BannerContent.vue';
import { formatCheckoutCardBeforeSave } from '@/utils/format';

interface Step {
	name: Steps;
	title: string;
	next?: Step;
};
type Steps = 'CheckoutForm' | 'CheckoutReview' | 'CheckoutConfirmation';
const namespace: string = 'cart';

@Component<Checkout>({
	components: {
		ArrowLeftIcon,
		BannerContent,
		Modal,
		ErrorValidationInformation,
		CheckoutForm,
		CheckoutReview,
		CheckoutConfirmation
	}
})
export default class Checkout extends Vue {
	@Prop({ type: Boolean, required: true, default: false }) private displayPreOrderSummary!: UserEvent;
	@Action('submitOrder', { namespace }) private submitOrder!: () => Promise<void>;
	@Action('updateValidationError', { namespace }) private updateValidationError!: (error: object) => void;
	@Action('getApplePaySession', { namespace }) private getApplePaySession!: (applePaySessionPayload: CheckoutApplePaySessionPayload) => Promise<void>;
	@Action('setApplePayPaymentInfo', { namespace }) private setApplePayPaymentInfo!: (applePayPaymentPayload: ApplePayPaymentPayload) => void;
	@Action('setGooglePayPaymentPayload', { namespace }) private setGooglePayPaymentPayload!: (googlePayPaymentPayload: CheckoutGooglePayPayload | null) => void;
	@Action('clearItemsAndCardInfoAndSetCookies', { namespace }) private clearItemsAndCardInfoAndSetCookies!: () => void;
	@Action('addPaymentMethod', { namespace: 'auth' }) private addPaymentMethod!: (payload: AddPaymentMethodPayload) => Promise<void>;
	@Action('registerUser', { namespace: 'auth' }) private registerUser!: (payload: RegisterUserPayload) => Promise<void>;
	@Action('doesUserExist', { namespace: 'auth' }) private doesUserExist!: (email: string) => Promise<boolean>;
	@Action('postToGetMinimalEventInfo', { namespace: 'suites' }) private postToGetMinimalEventInfo!: (suiteId: number) => void;
	@Getter('getPaymentMethod', { namespace }) private paymentMethod!: string;
	@Getter('getTotalWithTip', { namespace }) private totalWithTip!: string;
	@Getter('getContactInformation', { namespace }) private contactInformation!: CheckoutContactInfo;
	@Getter('getCardInformation', { namespace }) private creditCard!: CheckoutCardInfo;
	@Getter('isUserSavingPaymentMethodOnCheckout', { namespace }) private isUserSavingPaymentMethodOnCheckout!: boolean;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Getter('isEventDayOrdering', { namespace: 'suites' }) private isEventDayOrdering!: boolean;
	@Getter('getEventSuiteId', { namespace: 'suites' }) private eventSuiteId!: number;
	@Getter('getRestaurantPaymentConfig', { namespace: 'restaurant' }) private paymentConfig!: PaymentConfig;
	@Getter('getRestaurantName', { namespace: 'restaurant' }) private restaurantName!: string;
	@Getter('getUser', { namespace: 'auth' }) private user!: UserInfo;
	@Getter('isLoginAs', { namespace: 'auth' }) private isLoginAs!: boolean;
	@Getter('getFullName', { namespace: 'auth' }) private fullName!: string;
	@Watch('stepIndex', { immediate: true }) private onStepChange(): void {
		if(this.stepIndex === 0) {
			this.checkoutHeader = this.$t('checkout.title');
		}
		else if(this.stepIndex === 2) {
			if(this.isPreOrdering) {
				this.checkoutHeader = this.$t('checkout.confirmation.suites.header');
			}
			else {
				this.checkoutHeader = '';
			}
		}
		else {
			this.checkoutHeader = this.$t('checkout.btn_back_to_order');
		}
	}

	private fetchingUserInfo: boolean = false;
	private stepIndex: number = 0;
	private steps: Step[] = [{
		title: 'Payment Information',
		name: 'CheckoutForm'
	}, {
		title: 'Review',
		name: 'CheckoutReview'
	}, {
		title: 'Confirmation',
		name: 'CheckoutConfirmation'
	}];

	private submitFailed: boolean = false;
	private errorModalOpened: boolean = false;
	private errorModalEvents: string[] = ['close'];
	private errorModalButtonsText: string = this.$t('cart.viewer.error_validation_dialog_buttons');
	private errorShowSecondButton: boolean = false;

	private initComplete: boolean = false;
	private checkoutHeader: string = '';

	// =================================================================
	// Google Pay variables
	// =================================================================
	private gPayReady: boolean = false;
	private gBaseRequest: GPayBaseRequest = {
		apiVersion: 2,
		apiVersionMinor: 0
	};
	private gBaseCardPaymentMethod: GPayBaseCardPaymentMethod = {
		type: 'CARD',
		parameters: {
			allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
			allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
		}
	};
	private gPaymentsClient: any;
	private gIsReadyToPayRequest: any = Object.assign({}, this.gBaseRequest);
	private gPaymentDataRequest: any = Object.assign({}, this.gBaseRequest);

	// =================================================================
	// Apple Pay variables
	// =================================================================
	private aPayReady: boolean = false;
	private aSession: ApplePaySession | any;

	/**
	 * Current step
	 *
	 * @return {Step}
	 */
	private get currentStep(): Step {
		return this.steps[this.stepIndex];
	}

	/**
	 * Logged in as banner properties
	 *
	 * @return {BannerItem}
	 */
	private get loggedInAsBanner(): BannerItem {
		return {
			name: 'login-as',
			type: 'info',
			show: this.isLoginAs,
			message: this.$t('menu.logged_in_as_banner'),
			action: {
				text : this.fullName,
				onClick : () => this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {})
			}
		}
	}

	/**
	 * On component creation, check if google pay and apple
	 * pay is supported. Also check the order type to show respective
	 * inputs and get the user info if logged in
	 *
	 * @return {void}
	 */
	private async created(): Promise<void> {
		this.fetchingUserInfo = true;
		await this.readyPaymentMethods();

		if(this.displayPreOrderSummary) {
			this.switchScreen(2);
		}

		setTimeout(() => {
			this.fetchingUserInfo = false;
			this.initComplete = true;
			window.scroll(0, 0);
		}, 300);
	}

	/**
	 * Ready payment methods if supported (GPay, APay)
	 *
	 * @return {Promise<void>}
	 */
	private async readyPaymentMethods(): Promise<void> {
		if(typeof google !== 'undefined') {
			await this.readyGooglePay();
		}
		if((window as any).ApplePaySession) {
			await this.readyApplePay();
		}
	}

	/**
	 * Check if google pay is supported and get it ready for payments
	 *
	 * @return {Promise<void>}
	 */
	private async readyGooglePay(): Promise<void> {
		if(this.paymentConfig && this.paymentConfig.googlePay) {
			this.gIsReadyToPayRequest.allowedPaymentMethods = [this.gBaseCardPaymentMethod];
			this.gPaymentsClient = new google.payments.api.PaymentsClient({ environment: this.paymentConfig.googlePay.environment });
			await this.gPaymentsClient.isReadyToPay(this.gIsReadyToPayRequest)
				.then((response: any) => {
					// Set the google pay flag to true to allow gpay payment
					if (response.result) {
						this.gPayReady = true;
					}
				})
				.catch(() => {
					this.gPayReady = false;
				});
		}
	}

	/**
	 * Check if apple pay is supported and get it ready for payments
	 *
	 * @return {Promise<void>}
	 */
	private async readyApplePay(): Promise<void> {
		if(this.paymentConfig && this.paymentConfig.applePay) {
			await ApplePaySession.canMakePaymentsWithActiveCard(this.paymentConfig.applePay.merchant)
				.then((canMakePayments: boolean) => {
					if(canMakePayments) {
						this.aPayReady = true;
					}
				})
				.catch(() => {
					this.aPayReady = false;
				});
		}
	}

	/**
	 * Handle back return click
	 *
	 * @return {void}
	 */
	private handleReturnClick(): void {
		if(this.stepIndex === 1) {
			this.showPreviousStep();
		}
		else if(this.stepIndex === 2 && this.isPreOrdering) {
			this.closeModal();
		}
		else {
			this.switchScreen(-1);
		}
	}

	/**
	 * Go to the next step
	 *
	 * @return {void}
	 */
	private showNextStep(): void {
		setTimeout(() => {
			this.stepIndex++;
			this.scrollToTop();
		}, 150);
	}

	/**
	 * Go to the previous step
	 *
	 * @return {void}
	 */
	private showPreviousStep(): void {
		setTimeout(() => {
			this.stepIndex = this.stepIndex > 0 ? this.stepIndex - 1 : this.stepIndex;
			this.scrollToTop();
		}, 150);
	}

	/**
	 * Scroll to the top of the container
	 *
	 * @return {void}
	 */
	private scrollToTop(): void {
		let checkoutDiv = (document.getElementById('checkout-content') as HTMLElement);
		if(checkoutDiv) {
			checkoutDiv.scrollTop = 0;
		}
	}

	/**
	 * Switch to the chosen screen
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private switchScreen(index: number): void {
		// Switch to cart from pre order summary, we will show an extra button
		if(index === -2) {
			this.$emit('switchToCart', true);
		}
		if(index === -1) {
			this.$emit('switchToCart', false);
		}
		else {
			setTimeout(() => {
				this.stepIndex = index;
				this.scrollToTop();
			}, 150);
		}
	}

	/**
	 * Send event to parent to close the modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
		document.documentElement.classList.remove('modal-open');
	}

	/**
	 * Close the modal
	 *
	 * @return {void}
	 */
	private closeErrorModal(): void {
		this.errorModalOpened = false;
	}

	/**
	 * Go to payment profile page
	 *
	 * @return {void}
	 */
	private goToPaymentProfile(): void {
		this.$router.push({ path: '/profile/user-payment-methods', query: this.$route.query }).catch(() => {});
	}

	/**
	 * Submit the order to Numenu API for pre-auth OR to add to the user's tab
	 * depending on if the restaurant allows open tab
	 *
	 * @return {Promise<void>}
	 */
	private async confirm(): Promise<void> {
		const isApplePay: boolean = this.paymentMethod === 'apple_pay';
		this.submitFailed = false;
		this.fetchingUserInfo = true;

		await this.checkPaymentMethod()
			.then(async () => {
				// If the user is paying with Apple Pay, the payment needs to happen
				// in the event handler and not here. Also verify that the payment did not
				// fail on GPay handler
				if(!isApplePay && !this.submitFailed) {
					await this.submitOrder()
						.then(async () => {
							if (this.isUserSavingPaymentMethodOnCheckout) {
								await this.savePaymentMethod();
							}
							// Need to get the minimal event info so tab balance is updated for the user
							if (this.isEventDayOrdering && this.eventSuiteId) {
								this.postToGetMinimalEventInfo(this.eventSuiteId);
							}
						});
				}
			})
			.then(() => {
				// The payment is happening on the event handler for Apple Pay, so the handler
				// will handle the screen change on success. Also verify that the payment did not
				// fail on GPay handler
				if(!isApplePay && !this.submitFailed) {
					this.switchScreen(2);
				}
			})
			.catch((error: any) => {
				this.submitFailed = true;
				this.displayErrorMessage(error);
			})
			.finally(async () => {
				if (!isApplePay && !this.submitFailed) {
					await this.registerUserAfterCheckout();
					this.clearItemsAndCardInfoAndSetCookies();
				}
			});

		// Stop spinner
		setTimeout(() => {
			this.fetchingUserInfo = false;
		}, 150);
	}

	/**
	 * Display the backend's error message to the user
	 *
	 * @param {any} error
	 * @return {void}
	 */
	private displayErrorMessage(error: any): void {
		let errorPrefix = this.$t('checkout.error_order_submission');

		// Specific error cases
		// 202 - Credit card not supported with payment processor
		if (error.response && error.response.data && error.response.data.code === 202) {
			setTimeout(() => {
				this.errorModalOpened = true;
				this.errorModalEvents = ['close', 'go-to-payment-profile'];
				this.errorModalButtonsText = this.$t('checkout.error_credit_card_not_supported_with_payment_processor_dialog_buttons');
				this.errorShowSecondButton = true;
				this.updateValidationError({
					code: error.response.data.code,
					message: error.response.data.message
				});
			}, 150);
		}

		// 500s
		else if (typeof error === 'string') {
			this.$toasted.show(errorPrefix + error, { type: 'error', position: 'top-center' }).goAway(5000);
		}

		// 400s (error we can show to users)
		else if (error.response && error.response.data && error.response.data.message) {
			const errorCode = error.response.data.code;
			const tempMessage = error.response.data.message;

			// Item unavailable or price changed modal (need a user action there hence why we don't use the toast)
			if(errorCode === 426 || errorCode === 427 || errorCode === 428) {
				setTimeout(() => {
					this.errorModalOpened = true;
					this.errorModalEvents = ['close'];
					this.errorModalButtonsText = this.$t('cart.viewer.error_validation_dialog_buttons');
					this.errorShowSecondButton = false;
					this.updateValidationError({
						code: errorCode,
						message: tempMessage
					});
				}, 150);
			}

			// We don't want to show the dto validation errors here. Maybe in the future when we display those in a more user-friendlyish
			else if(Array.isArray(tempMessage)) {
				this.$toasted.show(errorPrefix, { type: 'error', position: 'top-center' }).goAway(5000);
			}

			// Other errors
			else {
				this.$toasted.show(errorPrefix + error.response.data.message, { type: 'error', position: 'top-center' }).goAway(5000);
			}
		}

		// 500s
		else {
			this.$toasted.show(errorPrefix, { type: 'error', position: 'top-center' }).goAway(5000);
		}
	}

	/**
	 * Save user's payment method
	 *
	 * TODO: To revisit eventually because of no CC postal code during checkout
	 *
	 * @return {Promise<void>}
	 */
	private async savePaymentMethod(): Promise<void> {
		try {
			await this.addPaymentMethod({ userId: this.user.id, token: this.user.token, paymentMethod: formatCheckoutCardBeforeSave(this.creditCard), savingFromCheckout: true });
		} catch (error) {
			this.$toasted.show('checkout.error_save_payment_method', { type: 'error', position: 'top-center' }).goAway(5000);
		}
	}

	/**
	 * Check the payment method and process respectively
	 *
	 * @return {Promise<void>}
	 */
	private async checkPaymentMethod(): Promise<void> {
		if(this.paymentMethod === 'google_pay') {
			await this.checkAndProcessGooglePay();
		}
		else if(this.paymentMethod === 'apple_pay') {
			await this.checkAndProcessApplePay();
		}

		// Default payment nothing extra to do other then
		// submitting the order with the cc details entered
		else {
			return;
		}
	}

	// =================================================================
	// Google Pay payment process
	// =================================================================

	/**
	 * Process Google Pay
	 *
	 * @return {void}
	 */
	private async checkAndProcessGooglePay(): Promise<void> {
		const gCardPaymentMethod: Object = Object.assign(
			{
				tokenizationSpecification: {
					type: 'PAYMENT_GATEWAY',
					parameters: {
						gateway: this.paymentConfig.googlePay.gateway,
						gatewayMerchantId: this.paymentConfig.googlePay.merchantId
					}
				}
			},
			this.gBaseCardPaymentMethod
		);
		this.gPaymentDataRequest.allowedPaymentMethods = [gCardPaymentMethod];
		this.gPaymentDataRequest.transactionInfo = {
			totalPriceStatus: 'FINAL',
			totalPrice: this.totalWithTip.toString(),
			currencyCode: this.paymentConfig.currencyCode,
			countryCode: this.paymentConfig.countryCode
		};
		this.gPaymentDataRequest.merchantInfo = {
			merchantName: this.paymentConfig.googlePay.merchantName,
			merchantId: this.paymentConfig.googlePay.googleMerchantId
		};

		// Grab the token and card info from the GPay
		// payment to send to Salata for payment confirmation.
		await this.gPaymentsClient.loadPaymentData(this.gPaymentDataRequest)
			.then((paymentData: any) => {
				this.setGooglePayPaymentPayload({
					token: paymentData.paymentMethodData.tokenizationData.token,
					lastFour: paymentData.paymentMethodData.info.cardDetails,
					cardType: paymentData.paymentMethodData.info.cardNetwork
				});
			})
			.catch(() => {
				this.submitFailed = true;
			});
	}

	// =================================================================
	// Apple Pay payment process
	// =================================================================

	/**
	 * Process Apple Pay payment
	 *
	 * @return {void}
	 */
	private async checkAndProcessApplePay(): Promise<void> {
		const ver = 3;
		const payment = {
			currencyCode: this.paymentConfig.currencyCode,
			countryCode: this.paymentConfig.countryCode,
			merchantCapabilities: ['supports3DS', 'supportsDebit', 'supportsCredit'],
			supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
			requiredBillingContactFields: ['name'],
			total: {
				label: this.restaurantName,
				amount: this.totalWithTip,
				type: 'final'
			}
		};

		// Create the Apple Pay session
		this.aSession = new ApplePaySession(ver, payment);

		// Set the event handler of the APay session
		this.aSession.onvalidatemerchant = await this.onValidateMerchant;
		this.aSession.onpaymentmethodselected = this.onPaymentMethodSelected;
		this.aSession.onpaymentauthorized = await this.onPaymentAuthorized;

		// Start the APay session.
		this.aSession.begin();
	}

	/**
	 * On merchant validation event, we send a call to the backend which
	 * redirect to Salata to check if the restaurant supports Apple Pay.
	 * If it does we get a payload so the Apple Pay local session can
	 * validate it.
	 *
	 * @param {ApplePayValidateMerchantEvent} event
	 * @return {Promise<void>}
	 */
	private async onValidateMerchant(event: ApplePayValidateMerchantEvent): Promise<void> {
		this.getApplePaySession({
			validationUrl: event.validationURL,
			processor: this.paymentConfig.processor,
			domain: window.location.hostname
		})
			.then((data: any) => {
				this.aSession.completeMerchantValidation(data);
			})
			.catch(() => {
				throw(this.$t('checkout.error_apple_pay_unsupported'));
			});
	}

	/**
	 * When the payment method selected change, we have
	 * to update it with the right total
	 *
	 * @return {void}
	 */
	private onPaymentMethodSelected(): void {
		this.aSession.completePaymentMethodSelection({
			newTotal: {
				label: this.restaurantName,
				amount: this.totalWithTip,
				type: 'final'
			}
		});
	}

	/**
	 * When the payment is authorized, we get a token from Apple Pay
	 * that will be used by Salata to complete the payment. We submit the order
	 * and then notify Apple Pay if the payment was successful or not.
	 *
	 * @param {ApplePayPaymentAuthorizedEvent} event
	 * @return {void}
	 */
	private async onPaymentAuthorized(event: ApplePayPaymentAuthorizedEvent): Promise<void> {
		await this.setApplePayPaymentInfo({ applePayPaymentToken: btoa(JSON.stringify(event.payment.token.paymentData)), applePayDetails: event.payment });

		await this.submitOrder()
			.then(() => {
				this.aSession.completePayment({ status: 0 });
				this.switchScreen(2);
			})
			.catch((error: any) => {
				this.displayErrorMessage(error);
				this.aSession.completePayment({ status: 1 });
				this.submitFailed = true;
				throw error;
			})
			.finally(async () => {
				if (!this.submitFailed) {
					await this.registerUserAfterCheckout();
					this.clearItemsAndCardInfoAndSetCookies();
				}
			});
	}

	/**
	 * Register the user after checkout if a password was entered
	 *
	 * @return {Promise<void>}
	 */
	private async registerUserAfterCheckout(): Promise<void> {
		if (this.contactInformation.password) {
			const firstName = this.contactInformation.full_name.substring(0, this.contactInformation.full_name.indexOf(' '));
			const lastName = this.contactInformation.full_name.substring(this.contactInformation.full_name.indexOf(' ') + 1);
			try {
				await this.registerUser({
					credentials: {
						email: this.contactInformation.email,
						password: this.contactInformation.password,
					},
					userInfo: {
						firstName,
						lastName,
						phoneNumber: this.contactInformation.phone_number,
					},
					paymentMethods: this.paymentMethod !== 'credit_card'
						? []
						: [{
							ccNumber: this.creditCard.number,
							expiryDate: this.creditCard.expiry_date,
							cvs: this.creditCard.cvd,
							postalCode: this.creditCard.postal_code,
							isDefault: true
						}],
					register: true,
					registerAtCheckout: true
				});
			}
			catch (error) {
				if (!this.doesUserExist(this.contactInformation.email)) {
					this.$toasted.show(this.$t('auth.login.form.error_register'), { type: 'error', position: 'top-center' }).goAway(5000);
				}
				else {
					this.$toasted.show(this.$t('auth.login.form.error_logging_in'), { type: 'error', position: 'top-center' }).goAway(5000);
				}
			}
		}
	}
}
