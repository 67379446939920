import { ActionTree } from 'vuex';
import { CartState, RootState } from '../types';
import { state } from './cart';
import { state as restaurantState } from '../restaurant/restaurant';
import { state as authState } from '../auth/auth';
import { state as suitesState } from '../suites/suites';
import { formatOrder } from '@/utils/format';
import { expiredTokenErrorHandler } from '@/utils/errorHandling';
import { fireGoogleTag, fireGoogleTagError } from '@/utils/google-tag-manager-helpers';
import { fillCart } from '@/utils/reordering';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import i18n from '@/i18n';
import router from '@/router';

export const actions: ActionTree<CartState, RootState> = {

	/**
	 * Add to cart action, before we add the item to the cart array,
	 * we validate it, resulting in either sending an error message
	 * if something goes wrong or adding the item to the cart.
	 *
	 * @param {any} extraData - item to add
	 * @return {Promise}
	 */
	async addToCart({ getters, commit, dispatch }, extraData: any): Promise<any> {
		dispatch('verifyConfig');
		const itemToValidate = Object.assign({}, state.temporarySelectedItem);
		itemToValidate.price = extraData.originalPrice;
		const itemsPayload = {
			itemsInCart: state.items,
			itemToAdd: itemToValidate,
			type: getters.getOrderType(false),
			date: DateTime.local().toISO()
		};

		return await new Promise((resolve, reject) => {
			authState.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/orders/item`, itemsPayload)
				.then(async (response) => {

					// We do not want to allow pre-ordering without a saved payment method
					if(suitesState.preOrdering && authState.user && !authState.user.paymentMethods.length) {
						const tempError = { response: { data: { message: i18n.t('vuex_store.cart.suites.pre_ordering_without_payment_method') }}};
						fireGoogleTagError({ name: 'preOrderingWithoutPaymentMethod', error: tempError });
						throw(tempError);
					}

					commit('ADD_TO_CART', extraData);
					commit('SET_TEMPORARY_ITEM', { tempItem: {}});

					// User added item to cart
					fireGoogleTag({
						name: 'itemAddedToCart',
						detail: extraData && extraData.special_instructions ? 'withInstructions' : 'withoutInstructions',
						specifier: extraData?.type ?? undefined
					});
					resolve(response);
				})
				.catch((error) => {
					// User added item to cart error
					fireGoogleTagError({ specifier: 'itemAddedToCart', error });
					commit('UPDATE_VALIDATION_ERROR', { code: error.response.data.code, message: error.response.data.message });
					reject(error);
				});
		});
	},

	/**
	 * Set costs from the backend calculations and items
	 *
	 * @param {string} [specifier]
	 * @return {Promise<void>}
	 */
	async setCosts({ commit }, specifier?: string): Promise<void> {
		const payload = {
			tableNum: state.config.tableNum,
			items: state.items,
			costs: state.costs,
			checkout: {
				...state.checkout,
				memberInfo: state.checkout.memberInfo && state.checkout.memberInfo.id
					? { id: state.checkout.memberInfo.id, identifier: state.checkout.memberInfo.identifier, promosApplied: state.checkout.memberInfo.promosApplied ? Array.from(state.checkout.memberInfo.promosApplied) : null, promos: state.checkout.memberInfo.promos }
					: null
			},
			date: DateTime.local().toISO()
		};
		try {
			const response = await authState.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/orders/set-costs`, payload);
			commit('SET_COSTS', response.data);
			// User updated takeout type, we wanna pass the takeout type in the details
			if(specifier === 'updateTakeoutType') {
				fireGoogleTag({ name: specifier, detail: state.checkout.pickup && state.checkout.pickup.delivery ? 'delivery' : 'pickup' });
			}
			else if (specifier) {
				fireGoogleTag({ name: specifier });
			}
		} catch (error) {
			const errorObj: any = error;

			// User opened cart error (cartOpened, removeFromCart, updateTakeoutType, updateTip, updateQuantity, promoApplied, giftCardApplied)
			fireGoogleTagError({ specifier: specifier, error });
			if(errorObj.response && errorObj.response && errorObj.response.data && errorObj.response.data.message) {
				throw errorObj.response.data.message;
			}
			else {
				throw errorObj;
			}
		}
	},

	async fetchSchedulingRestrictions({}, payload: any): Promise<any> {
		try {
			const response = await authState.axiosInstance.post<ScheduleRestrictions>(`${process.env.VUE_APP_API_PREFIX}/orders/scheduling`, payload);
			return response.data;

		} catch (error) {
			const errorObj: any = error;
			throw errorObj;
		}
	},

	addTemporaryItem({ commit }): void {
		commit('ADD_TEMPORARY_ITEM');
	},

	removeTemporaryItem({ commit }): void {
		commit('REMOVE_TEMPORARY_ITEM');
	},

	setTemporaryItem({ commit }, temporaryItemPayload: { tempItem: MenuItem, quantity?: number }): void {
		commit('SET_TEMPORARY_ITEM', temporaryItemPayload);
	},

	updateTemporaryItemPrice({ commit }, prices: Prices): void {
		commit('UPDATE_TEMPORARY_ITEM_PRICE', prices);
	},

	calculateCartItemsMemberPrices({ commit }): void {
		commit('CALCULATE_CART_ITEMS_MEMBER_PRICES');
	},

	async removeFullItemFromCart({ commit, dispatch }, item: OrderItem): Promise<void> {
		commit('REMOVE_FULL_ITEM_FROM_CART', item);
		await dispatch('setCosts', 'removeFromCart');
	},

	setRestaurantCharges({ commit }, restaurant: Restaurant): void {
		commit('SET_RESTAURANT_CHARGES', restaurant);
	},

	async getCookies({ commit }): Promise<void> {
		commit('GET_COOKIES');
	},

	updateOrderOptions({ commit }, options: any): void {
		commit('UPDATE_ORDER_OPTIONS', options);
	},

	updateTip({ commit }, tipObject: any): void {
		commit('UPDATE_TIP', tipObject);
	},

	async updateQuantity({ commit, dispatch }, quantityObject: any): Promise<void> {
		commit('UPDATE_QUANTITY', quantityObject);
		await dispatch('setCosts', 'updateQuantity');
	},

	reviewButtonClicked({ }): void {
		fireGoogleTag({ name: 'review' });
	},

	updatePickupInformation({ commit }, pickup: CheckoutPickupInfo): void {
		commit('UPDATE_PICKUP_INFORMATION', pickup);
		if(state.checkout.pickup && state.checkout.pickup.dueByDate) {
			fireGoogleTag({ name: state.config.genericCatering ? 'genericCateringInformationFilled' : 'takeoutInformationFilled', detail: state.checkout.pickup && state.checkout.pickup.dueByDate ? state.checkout.pickup.dueByDate : '' });
		}
	},

	updateSuitesInformation({ commit }, suitesInfo: CheckoutSuitesInfo): void {
		commit('UPDATE_SUITES_INFORMATION', suitesInfo);
	},

	updateDeliveryInformation({ commit }, delivery: CheckoutDeliveryInfo): void {
		commit('UPDATE_DELIVERY_INFORMATION', delivery);
	},

	updateContactInformation({ commit }, contact: CheckoutContactInfo): void {
		commit('UPDATE_CONTACT_INFORMATION', contact);
	},

	updateCardInformation({ commit }, card: CheckoutCardInfo): void {
		commit('UPDATE_CARD_INFORMATION', card);
	},

	updateInvoice({ commit }, invoice: boolean): void {
		commit('UPDATE_INVOICE', invoice);
	},

	updateCostCenter({ commit }, costCenter: string): void {
		commit('UPDATE_COST_CENTER', costCenter);
	},

	updatePurchaseOrder({ commit }, purchaseOrder: string): void {
		commit('UPDATE_PURCHASE_ORDER', purchaseOrder);
	},

	updateCustomQuestions({ commit }, questions: CheckoutCustomQuestion[]): void {
		commit('UPDATE_CUSTOM_QUESTIONS', questions);
	},

	setIsUserSavingPaymentMethodOnCheckout({ commit }, savePaymentMethodOnCheckout: boolean): void {
		commit('SET_IS_USER_SAVING_PAYMENT_METHOD_ON_CHECKOUT', savePaymentMethodOnCheckout);
	},

	async updateCheckoutPaymentMethod({ commit }, paymentMethod: string): Promise<void> {
		commit('UPDATE_PAYMENT_METHOD', paymentMethod);
	},

	updateChosenCreditCard({ commit }, chosenCreditCard: SavedPaymentOption | null): void {
		commit('UPDATE_CHOSEN_CREDIT_CARD', chosenCreditCard);
	},

	updateValidationError({ commit }, errorValidation: ErrorValidation): void {
		commit('UPDATE_VALIDATION_ERROR', errorValidation);
	},

	updateDeliveryStatus({ commit }, value: CheckoutDeliveryInfo): void {
		commit('UPDATE_DELIVERY_STATUS', value);
	},

	setTodayDate({ commit }, value: string): void {
		commit('SET_TODAY_DATE', value);
	},

	setDateSelectedByUserFlag({ commit }, value: boolean): void {
		commit('SET_DATE_SELECTED_BY_USER_FLAG', value);
	},

	setTipSelected({ commit }, value: boolean): void {
		commit('SET_TIP_SELECTED', value);
	},

	setLegal({ commit }, value: boolean): void {
		Cookies.set('legal', `${value}`, { expires: 1 });
		commit('SET_LEGAL', value);
	},

	setGooglePayPaymentPayload({ commit }, googlePayPaymentPayload: CheckoutGooglePayPayload | null): void {
		commit('SET_GOOGLE_PAY_PAYMENT_PAYLOAD', googlePayPaymentPayload);
	},

	async setApplePayPaymentInfo({ commit }, applePayPaymentPayload: ApplePayPaymentPayload): Promise<void> {
		commit('SET_APPLE_PAY_PAYMENT_PAYLOAD', applePayPaymentPayload);
	},

	setMembershipBanner({ commit }, showBanner: boolean): void {
		commit('SHOW_MEMBERSHIP_BANNER', showBanner);
	},

	/**
	 * Validate the discount code and get its value
	 *
	 * @param {string} discountCode
	 * @return {Promise<void>}
	 */
	async validateDiscountCodeAndGetValue({ commit, dispatch }, discountCode: string): Promise<void> {
		try {
			const response = await authState.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/orders/get-discount-info`, { code: discountCode });
			commit('SET_DISCOUNT', response.data);
			await dispatch('setCosts', 'promoApplied');
		} catch (e) {
			commit('SET_DISCOUNT', null);
			await dispatch('setCosts', 'promoAppliedError');
			throw e;
		}
	},

	/**
	 * Validate the discount code and get its value
	 *
	 * @param {string} discountCode
	 * @return {Promise<void>}
	 */
	async validateVoucherCodeAndGetValue({ commit, dispatch }, voucherCode: string): Promise<void> {
		try {
			const response = await authState.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/orders/get-gift-card`, { restaurantId: restaurantState.restaurant.app8_restaurant, giftCardNumber: voucherCode });
			commit('SET_VOUCHER', response.data);

			// Set costs
			await dispatch('setCosts', 'giftCardApplied');
		} catch (error) {
			const errorObj: any = error;
			commit('SET_VOUCHER', null);

			// Set costs to reset voucher amount
			await dispatch('setCosts', 'giftCardAppliedError');

			const errorMessage: string = 'The gift card does not have any funds left';
			if (errorObj && errorObj.response && errorObj.response.data && errorObj.response.data.message === errorMessage) {
				throw i18n.t('vuex_store.cart.error_gift_card_no_funds_left');
			}
			throw error;
		}
	},


	/**
	 * Get a token from App8 in development mode for user auth,
	 * otherwise simply process the query params from the URL.
	 *
	 * @return {Promise}
	 */
	async processQueryParams({ commit, dispatch }, queryParams: any): Promise<void> {
		// Process query params
		commit('PROCESS_QUERY_PARAMS', queryParams);
		// Get the payment config from Salata (this is required for payment methods like GPay/APay)
		await dispatch('restaurant/getSalataRestaurantPaymentConfig', {}, { root: true });

		// Fetch minimal event information if there is a suiteId passed (pre-ordering/event day ordering)
		if(suitesState.eventSuiteId) {
			if (!suitesState.eventDayOrdering) {
				commit('suites/SET_PRE_ORDERING_FLAG', true, { root: true })
			}
			const suitesLocationId = !suitesState.preOrdering && Cookies.get('suitesLocationId');
			dispatch('suites/setSuitesLocationId', suitesLocationId, { root: true });
			await dispatch('suites/postToGetMinimalEventInfo', suitesState.eventSuiteId, { root: true });
		}

		// Fire user landed event with setup information
		fireGoogleTag({
			name: 'userLandedEvent',
			specifier: suitesState.preOrdering
				? 'catering' : state.config.tableNum
					? 'dineIn' : state.config.genericCatering
						? 'genericCatering' : 'takeout',
			detail: restaurantState.restaurant.pos_integrated ? 'integrated' : 'standalone',
			parameter: state.config.genericTableLocation ? 'genericLocation' : 'standard',
			userType: authState.user.id ? 'loggedIn' : 'guest',
			userId: authState.user.id ? authState.user.id : undefined,
			tempSessionId: authState.user.id ? undefined : uuidv4()
		});
	},

	/**
	 * Set table number for the generic location to allow dine-in orders
	 *
	 * @param {GenericTableLocation} payload
	 * @return {Promise}
	 */
	setSelectedTable({ commit }, payload: GenericTableLocation): any {
		commit('SET_SELECTED_TABLE', payload);
	},

	/**
	 * TEMPORARY HARDCODED FOR BOWLERO (At the moment, as soon as we have other clients needing something similar,
	 * this will become much more dynamic)
	 *
	 * @return {Promise}
	 */
	async verifyIfUserEnrolledInLoyaltyProgram({ commit }, tempPayload: any): Promise<any> {
		const payload = {
			email: tempPayload.email,
			app8RestaurantId: restaurantState.restaurant.app8_restaurant
		};
		return await new Promise((resolve, reject) => {
			authState.axiosInstance
				.post(`${process.env.VUE_APP_API_PREFIX}/auth/get-user-enrolled-program-status`, payload)
				.then(response => {
					// If the email wasn't changed, it means the user got back to the contact info page, we do not want
					// to change the user status
					if(!tempPayload.emailChanged && state.checkout.loyaltyProgram && state.checkout.loyaltyProgram.userStatus) {
						response.data.userStatus = state.checkout.loyaltyProgram.userStatus;
					}
					commit('SET_LOYALTY_PROGRAM', response.data);
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},

	/**
	 * Set the loyalty program status
	 * member: already signed up
	 * new-member: wants to sign up
	 * non-member: do not want to sign up
	 *
	 * @return {Promise}
	 */
	setLoyaltyProgramStatus({ commit }, status: string): any {
		commit('SET_USER_LOYALTY_PROGRAM_STATUS', status);
	},

	/**
	 * Validate the cart before going to the checkout proces
	 *
	 * @return {Promise}
	 */
	async validateCart({ getters, commit, dispatch }): Promise<any> {
		dispatch('verifyConfig');
		if (state.items) {
			const cartRequest = {
				itemsInCart: state.items,
				type: getters.getOrderType(false),
				date: DateTime.local().toISO()
			};

			return await new Promise((resolve, reject) => {
				authState.axiosInstance
					.post(`${process.env.VUE_APP_API_PREFIX}/orders/validate`, cartRequest)
					.then(response => {

						// We do not want to allow pre-ordering without a saved payment method
						if(suitesState.preOrdering && authState.user && !authState.user.paymentMethods.length) {
							const tempError = { response: { data: { message: i18n.t('vuex_store.cart.suites.pre_ordering_without_payment_method') }}};
							fireGoogleTagError({ name: 'preOrderingWithoutPaymentMethod', error: tempError });
							throw(tempError);
						}

						fireGoogleTag({
							name: 'checkout',
							detail: getters.getOrderType(true)
						});
						resolve(response);
					})
					.catch(error => {
						// User went to checkout error
						fireGoogleTagError({ specifier: 'checkout', error });
						commit('UPDATE_VALIDATION_ERROR', { code: error.response.data.code, message: error.response.data.message });
						reject(error);
					});
			});
		}
	},

	/**
	 * Get the apple pay session with the validationURL from the Apple Pay API
	 *
	 * @return {Promise}
	 */
	async getApplePaySession({ }, applePayPayload: any): Promise<any> {
		const payload = {
			validationUrl: applePayPayload.validationUrl,
			processor: applePayPayload.processor,
			domain: applePayPayload.domain,
			app8RestaurantId: restaurantState.restaurant.app8_restaurant
		};

		return await new Promise((resolve, reject) => {
			authState.axiosInstance
				.post(`${process.env.VUE_APP_API_PREFIX}/payment/get-apple-pay-session`, payload)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},

	/**
	 * Submit the order to the API
	 * TODO: Refactor the payload sent, we should be reducing the payload size of about 3/4 since a lot of it is unnecessary.
	 *
	 * @return {Promise}
	 */
	async submitOrder({ getters, dispatch }): Promise<void> {
		dispatch('verifyConfig');
		try {
			const ordersRequest: any = {
				type: getters.getOrderType(false),
				data: formatOrder(state, restaurantState, authState, suitesState),
				id: suitesState.preOrdering && suitesState.suiteCateringOrder ? suitesState.suiteCateringOrder.id : undefined,
				operatorToken: authState.loginAs && authState.suiteOperator ? authState.suiteOperator.token : undefined,
			};

			if (!suitesState.preOrdering && state.checkout.pickup && state.checkout.pickup.dueByDate) {

				const dueByDate: DateTime = DateTime.fromISO(state.checkout.pickup!.dueByDate);
				const dueByTime: DateTime | null = state.checkout.pickup.dueByTime ? DateTime.fromFormat(state.checkout.pickup.dueByTime!, i18n.t('checkout.confirmation.time_format')) : null;

				// JS Dates have months range from 0 - 11 instead of 1 - 12 like luxon
				ordersRequest.pickup_time = DateTime.fromObject({
					year: dueByDate.year,
					month: dueByDate.month,
					day: dueByDate.day,
					hour: dueByTime ? dueByTime.hour : 12,
					minute: dueByTime ? dueByTime.minute : undefined
				});

				// We add prep time to the pickup_time if ASAP
				if(!state.checkout.pickup.scheduled && state.checkout.pickup.prepTime) {
					ordersRequest.pickup_time = ordersRequest.pickup_time.plus({ minutes: state.checkout.pickup.prepTime }).toUTC().toISO();
				}

				// Scheduled is already adjusted with the correct time
				else {
					ordersRequest.pickup_time = ordersRequest.pickup_time.toUTC().toISO();
				}
			}

			// Pre ordering (suite catering order type)
			else if (suitesState.preOrdering && suitesState.minimalEventInfo) {
				// Set the pickup time for suite catering orders as the delivery time if the user selected a delivery time (datetime based selection)
				if(state.checkout.suitesInfo?.deliveryTime && DateTime.fromISO(state.checkout.suitesInfo.deliveryTime!).isValid) {
					ordersRequest.pickup_time = DateTime.fromISO(state.checkout.suitesInfo.deliveryTime!).toUTC().toISO();
				}
			}

			// Marketplace order
			if(restaurantState.restaurant && restaurantState.restaurant.marketplaceHub) {
				await authState.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/orders/create-marketplace-order`, ordersRequest);
			}

			// Any other order types
			else {
				await authState.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/orders`, ordersRequest);
			}

			// Re-fetch event information after pre-ordering to get the updated pre-order
			if(suitesState.preOrdering) {
				if (!suitesState.userEventSuite?.tab_limit && state.checkout.suitesInfo?.tabLimit) {
					fireGoogleTag({ name: 'tabLimitSet' })
				}
				else if (suitesState.userEventSuite?.tab_limit !== state.checkout.suitesInfo?.tabLimit) {
					fireGoogleTag({ name: 'tabLimitChanged' })
				}
				await dispatch('suites/postToGetMinimalEventInfo', suitesState.eventSuiteId, { root: true });
			}
		} catch (e) {
			expiredTokenErrorHandler(e);
			fireGoogleTagError({ specifier: 'submitOrder', error: e, detail: state.checkout.paymentMethod });
			throw e;
		}
	},

	/**
	 * Submit the notification order to the API
	 *
	 * @return {Promise}
	 */
	async submitOrderNotification({}, payload: OrderNotificationPayload): Promise<void> {
		try {
			const orderRequest: any = {
				type: 'notification',
				status: "in-progress",
				data: {
					config: {
						"restaurantId": restaurantState.restaurant.app8_restaurant,
						checkNum: payload.orderId
					},
					checkout: {
						contact: {
							phone_number: payload.phoneNumber
						}
					},
					date: DateTime.local().toISO()
				}
			};

			await authState.axiosInstance.post(`${process.env.VUE_APP_API_PREFIX}/orders/notification`, orderRequest);
		} catch (e) {
			// User submits the order notification
			fireGoogleTagError({ specifier: 'submitOrderNotification', error: e });
			throw e;
		}
	},


	/**
	 * Clear the cart info (items/card) when the order is submitted.
	 * Also set the cookies.
	 *
	 * @return {void}
	 */
	clearItemsAndCardInfoAndSetCookies({ commit }): void {
		fireGoogleTag({
			name: 'submitOrder',
			specifier: suitesState.preOrdering && suitesState.suiteCateringOrder && suitesState.suiteCateringOrder.id ? 'modifiedOrder' : 'createdOrder',
			detail: state.checkout.paymentMethod,
			userType: authState.user.id ? 'loggedIn' : 'guest'
		});
		commit('CLEAR_ITEMS_AND_CARD_INFO');
		commit('SET_COOKIES');
	},

	/**
	 * Verify if there are at least a table number or a takeout flag
	 * Updates the modal error message to no config as the modal component
	 * will handle the error message in a different manner than the submit order
	 * error thrown
	 *
	 * @return {void}
	 */
	verifyConfig({ commit }): void {
		const isAnotherRoundActive = router.currentRoute.query.anotherRoundId && restaurantState.restaurant?.another_round;
		if(state.config.takeout !== 'true' && state.config.genericCatering !== 'true' && !restaurantState.restaurant.k12 && !state.config.tableNum && !isAnotherRoundActive) {
			commit('UPDATE_VALIDATION_ERROR', { message: 'No config' });
			throw i18n.t('vuex_store.cart.error_no_flags_provided');
		}
	},

	/**
	 * Get the alcoholic items of a specific order and add the items to the cart
	 *
	 * @param {string} orderId
	 * @return {Promise<boolean>}
	 */
	async getAnotherRound({ commit }, orderId: string): Promise<boolean> {
		try {
			const { data: { items, tableInfo } } = await authState.axiosInstance.get<{ items: OrderDataItem[], tableInfo: GenericTableLocationConfig }>(`${process.env.VUE_APP_API_PREFIX}/orders/another-round/${orderId}`);
			if (tableInfo) {
				commit('SET_SELECTED_TABLE', {
					tableNum: '',
					location: tableInfo.tableLocation,
					area: tableInfo.tableArea
				});
			}
			return await fillCart(items);
		}
		catch (error) {
			throw error;
		}
	}
};