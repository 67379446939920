import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { AuthState, RootState } from '../types';
import axios from 'axios';
const namespaced: boolean = true;

// TODO: Refactor this store into sub stores (esp for configs)
export const state: AuthState = {
	authenticated: false,
	user: {
		token: '',
		firstName: '',
		lastName: '',
		email: '',
		id: '',
		paymentMethods: [],
		suiteOperator: false
	},
	loginAs: false,
	suiteOperatorLogin: false,
	suiteOperator: {
		token: '',
		firstName: '',
		lastName: '',
		email: '',
		id: '',
		paymentMethods: [],
		suiteOperator: false
	},
	showWelcomeBanner: false,
	showLoginAsBanner: false,
	axiosInstance: axios.create()
};

export const auth: Module<AuthState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default auth;