
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
import ChevronLeftIcon from 'vue-feather-icons/icons/ChevronLeftIcon';
import XIcon from 'vue-feather-icons/icons/XIcon';

const clickaway = require('vue-clickaway').mixin;
const namespace: string = 'filters';

@Component<Filters>({
	components: {
		ChevronDownIcon,
		ChevronLeftIcon,
		XIcon
	},
	mixins: [clickaway]
})
export default class Filters extends Vue {
	@Action('toggleDiet', { namespace }) private toggleDiet!: (index: number) => void;
	@Action('toggleAllergens', { namespace }) private toggleAllergens!: (index: number) => void;
	@Action('clearFilters', { namespace }) private clear!: () => void;
	@Getter('getDiets', { namespace }) private dietOptions!: any[];
	@Getter('getAllergens', { namespace }) private allergenOptions!: any[];
	@Getter('getSelectedDiets', { namespace }) private selectedDiets!: any[];
	@Getter('getSelectedAllergens', { namespace }) private selectedAllergens!: any[];

	/**
	 * Send event to parent to close the filter panel
	 *
	 * @return {void}
	 */
	private closeFilter(): void {
		this.$emit('close-filter');
	}

	/**
	 * Update the diets filters when one was selected or
	 * unselected.
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private changeDiet(index: number): void {
		this.toggleDiet(index);
	}

	/**
	 * Update the allergens filters when one was selected or
	 * unselected.
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private changeAllergens(index: number): void {
		this.toggleAllergens(index);
	}

	/**
	 * Clear all filters selected
	 *
	 * @return {void}
	 */
	private clearFilters(): void {
		this.clear();
	}
}
