
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<MenuCardHorizontal>({})

export default class MenuCardHorizontal extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private menuItem!: MenuItem;
	@Prop({ type: Boolean, required: false, default: false }) private showImage!: Boolean;
	@Prop({ type: String, required: false, default: null }) private imageURL!: String;
	@Prop({ type: String, required: false, default: null }) private formattedDescription!: string;

	$refs!: {
		menuItemName: HTMLHeadingElement
	}

	private numLines: number = 3;

	/**
	 * Update numbers of lines for description and add the resize event
	 *
	 * @return {void}
	 */
	private mounted(): void {
		const el = this.$refs.menuItemName;

		if (el && el.offsetHeight > 32) {
			this.numLines = 2;
		}
	}
}
