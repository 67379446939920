
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/validation-rules';
import { Validate } from '@/types';

@Component<CartTipCustomAmount>({
	components: {
		ValidationObserver,
		ValidationProvider
	}
})

export default class CartTipCustomAmount extends Vue {
	@Prop({ type: Boolean, required: true }) private isMarketplaceHub!: boolean;
	@Prop({ type: [Number, String], required: true }) private inputAmount!: number | string;

	$refs!: {observer: Validate}

	private tipType: string = '';
	private tip: number = 0;

	/**
	 * Set the tip to the tip amount from the vuex store
	 *
	 * @return {void}
	 */
	private mounted(): void {
		this.tip = +this.inputAmount;

		// Dollar amount is not supported with marketplace at the moment
		if(this.isMarketplaceHub) {
			this.setTipType('percentage');
		}
	}

	/**
	 * Set the clicked tip type
	 *
	 * @param {string} type - dollar || percentage
	 * @return {void}
	 */
	private setTipType(type: string): void {
		this.tipType = type;
	}

	/**
	 * Validate the input value and then set the new tip value
	 *
	 * @return {Promise<void>}
	 */
	private async setTip(): Promise<void> {
  	const isValid = await this.$refs.observer.validate();
		if (isValid) {

			// If there is no type selected, we force it as dollar
			if(!this.tipType) {
				this.tipType = 'dollar';
			}
			this.$emit('save', { tip: +(+this.tip).toFixed(2), type: this.tipType });
			this.$emit('close');
		}
	}

	/**
	 * Send the event to the parent to close the modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
