
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ToggleSwitch extends Vue {
	@Prop({ type: Array, required: false }) private labels!: string[];
	@Prop({ type: Boolean, required: false, default: false }) private disabled!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private value!: boolean;

	private handleClick(): void {
		if (!this.disabled) {
			this.$emit('input', !this.value);
		}
	}
}
