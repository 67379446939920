import { GetterTree } from 'vuex';
import { SearchState, RootState } from '../types';

export const getters: GetterTree<SearchState, RootState> = {
	getSearchInput(state: SearchState): String {
		return state.searchInput;
	},

	getResultItems(state: SearchState): Array <MenuItem> {
		return state.items;
	}
};