import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import cart from './cart/cart';
import search from './search/search';
import filters from './filters/filters';
import restaurant from './restaurant/restaurant';
import auth from './auth/auth'
import suites from './suites/suites'
import layout from './layout/layout'
import geolocation from './geolocation/geolocation';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
	modules: {
		cart,
		search,
		filters,
		restaurant,
		auth,
		suites,
		layout,
		geolocation
	}
};

export default new Vuex.Store<RootState>(store);