import { MutationTree } from 'vuex';
import { SearchState } from '../types';

export const mutations: MutationTree<SearchState> = {

	/**
	 * Update search input
	 *
	 * @param {SearchState} state
	 * @param {string} searchText
	 * @return {void}
	 */
	UPDATE_SEARCH_INPUT(state: SearchState, searchText: string): void {
		if(searchText.length > 0) {
			state.searchInput = searchText;
		}
		else {
			state.searchInput = '';
		}
	},

	/**
	 * Update items returned as a result
	 *
	 * @param {SearchState} state
	 * @param {MenuItem[]} updatedItems
	 * @return {void}
	 */
	UPDATE_RESULT_ITEMS(state: SearchState, updatedItems: MenuItem[]): void {
		state.items = updatedItems;
	}
};