
import { Vue, Component, Prop } from 'vue-property-decorator';
import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
import MinusIcon from 'vue-feather-icons/icons/MinusIcon';

@Component<QuantitySelector>({
	components: {
		PlusIcon,
		MinusIcon
	}
})
export default class QuantitySelector extends Vue {
	@Prop({ type: String, required: true, default: '' }) private id!: string;
	@Prop({ type: Number, required: true, default: 0 }) private quantity!: number;
	@Prop({ type: Boolean, required: false, default: false }) private disableDecrement!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private disableIncrement!: boolean;

	private handleIncrement(): void {
		this.$emit('increment');
	}

	private handleDecrement(): void {
		this.$emit('decrement');
	}
}
