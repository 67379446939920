
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { generateNonce } from '@/utils/sort';
import { handleAllErrors } from '@/utils/errorHandling';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import LoginForm from '@/components/auth/LoginForm.vue';
import PoweredByApp8 from '@/components/shared/PoweredByApp8.vue';
import GoogleLoginIcon from '../../assets/images/icons/google-login-icon.svg?inline';
import AppleLoginIcon from '../../assets/images/icons/apple-login-icon.svg?inline';
import CryptoJS from 'crypto-js';
import jwt_decode from 'jwt-decode';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';

const namespace: string = 'auth';

@Component<LoginSignup>({
	components: {
		ArrowLeftIcon,
		LoginForm,
		GoogleLoginIcon,
		AppleLoginIcon,
		PoweredByApp8
	}
})
export default class LoginSignup extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private credentials!: LoginCredentials;
	@Action('doesUserExist', { namespace }) private doesUserExist!: (email: string) => Promise<boolean>;
	@Action('loginWithCredentials', { namespace }) private loginWithCredentials!: (credentials: LoginCredentials) => Promise<void>;
	@Action('loginAs', { namespace }) private loginAs!: (credentials: LoginCredentials) => Promise<void>;
	@Action('operatorLogin', { namespace }) private operatorLogin!: (payload: { credentials: LoginCredentials }) => Promise<void>;
	@Action('getGoogleUserInfo', { namespace }) private getGoogleUserInfo!: (accessToken: string) => Promise<any>;
	@Action('socialLoginOrRegister', { namespace }) private socialLoginOrRegister!: (socialPayload: SocialLoginOrRegisterPayload) => Promise<void>;
	@Action('forgotPassword', { namespace }) private forgotPassword!: (email: string) => Promise<void>;
	@Getter('getUser', { namespace }) private user!: UserInfo;
	@Getter('getSuiteOperator', { namespace }) private suiteOperator!: UserInfo;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Getter('isLoginAs', { namespace }) private isLoginAs!: boolean;
	@Getter('isSuiteOperatorLogin', { namespace }) private isSuiteOperatorLogin!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;

	private loading = {
		native: false,
		apple: false,
		facebook: false,
		google: false
	};
	private forgotPasswordToggle: boolean = false;

	// Google
	private googleClient: any = {};

	// Apple
	private appleClient: any = {};
	private appleNonce: string = generateNonce(32);

	/**
	 * Check if any of the loading properties is true
	 * 
	 * @return {void}
	 */
	private get isAnyLoading() {
		return Object.values(this.loading).includes(true);
	}

	// TODO: Content for suite operator login will be changed for gameday ordering
	private get headerContent(): string {
		if (this.isLoginAs || this.isSuiteOperatorLogin) {
			return this.$t('auth.login.suite_operator.header');
		}
		else if (this.isPreOrdering) {
			return this.$t('auth.login.pre_ordering.header');
		}
		else {
			return this.$t('auth.login.header');
		}
	}

	// TODO: Content for suite operator login will be changed for gameday ordering
	private get descriptionContent(): string {
		if (this.isLoginAs || this.isSuiteOperatorLogin) {
			return this.$t('auth.login.suite_operator.description');
		}
		else if (this.isPreOrdering) {
			return this.$t('auth.login.pre_ordering.description');
		}
		else {
			return this.$t('auth.login.description');
		}
	}

	/**
	 * Setup social logins
	 *
	 * @return {void}
	 */
	private mounted(): void {
		this.setupGoogleClient();
		this.setupFacebookClient();
		this.setupAppleClient();
	}

	/**
	 * Setup Google client (with the correct GSI scope
	 * to bypass the authorization request and the callback)
	 *
	 * @return {Promise<void>}
	 */
	private async setupGoogleClient(): Promise<void> {
		this.googleClient = google.accounts.oauth2.initTokenClient({
			client_id: process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID,
			scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
			callback: await this.onGoogleLogin
		});
	}

	/**
	 * Setup Facebook sign in
	 *
	 * @return {void}
	 */
	private setupFacebookClient(): void {
		FB.init({
			appId: process.env.VUE_APP_FACEBOOK_LOGIN_APP_ID,
			cookie: true,
			xfbml: true,
			version: 'v14.0'
		})
	}

	/**
	 * Setup Apple sign in (need to be in https to test)
	 * the nonce is also a random generated string that will
	 * be hashed later by the server
	 *
	 * @return {void}
	 */
	private setupAppleClient(): void {
		const hashedNonce: string = CryptoJS.SHA256(this.appleNonce).toString(CryptoJS.enc.Hex);
		window.AppleID.auth.init({
			clientId: process.env.VUE_APP_APPLE_LOGIN_CLIENT_ID,
			scope: 'name email',
			redirectURI: process.env.VUE_APP_APPLE_REDIRECT_URI,
			state: 'origin:web',
			nonce: hashedNonce,
			usePopup: true
		});

		// Debug failure
		document.addEventListener('AppleIDSignInOnFailure', () => {
			// console.error('Apple login error', event);
		});
	}

	/**
	 * Attempt a Google login
	 *
	 * @return {void}
	 */
	private googleLoginAttempt(): void {
		this.googleClient.requestAccessToken();
	}

	/**
	 * Google sign in completed
	 *
	 * @param {GoogleLoginResponse} response
	 * @return {Promise<void>}
	 */
	private async onGoogleLogin(response: GoogleLoginResponse): Promise<void> {
		try {
			this.loading.google = true;
			const { given_name: firstName, family_name: lastName, email }: GoogleUserInfo = await this.getGoogleUserInfo(response.access_token);

			const userExists: boolean = await this.doesUserExist(email);
			if (userExists) {
				await this.socialLoginOrRegister({ type: 'google', payload: { accessToken: response.access_token, register: false }});
				this.redirectUser();
			}
			else {
				this.$emit('next', { type: 'google', data: { social: response, user: { firstName, lastName }, email }});
			}
		} catch (error) {
			this.$toasted.show(this.$t('auth.login.form.error_google_login'), { type: 'error', position: 'top-center' }).goAway(5000);
		} finally {
			setTimeout(() => {
				this.loading.google = false;
			}, 500);
		}
	}

	/**
	 * Attempt a Facebook login and handle its response
	 *
	 * @return {void}
	 */
	private facebookLoginAttempt(): void {
		FB.login(async (response: fb.StatusResponse) => {
			if (response.authResponse && response.authResponse.accessToken) {
				try {
					this.loading.facebook = true;
					const { first_name: firstName, last_name: lastName, email }: FacebookUserInfo = await this.getFacebookUserInfo();

					const userExists: boolean = await this.doesUserExist(email);
					if (userExists) {
						await this.socialLoginOrRegister({ type: 'facebook', payload: { accessToken: response.authResponse.accessToken, register: false } });
						this.redirectUser();
					}
					else {
						this.$emit('next', { type: 'facebook', data: { social: response, user: { firstName, lastName }, email } });
					}
				} catch (error) {
					this.$toasted.show(this.$t('auth.login.form.error_facebook_login'), { type: 'error', position: 'top-center' }).goAway(5000);
				} finally {
					setTimeout(() => {
						this.loading.facebook = false;
					}, 500);
				}
			}
			else {
				this.$toasted.show(this.$t('auth.login.form.error_facebook_login'), { type: 'error', position: 'top-center' }).goAway(5000);
			}
		}, { scope: 'email' });
	}

	/**
	 * Get the info of the Facebook user
	 *
	 * @return {Promise<FacebookUserInfo>}
	 */
	private getFacebookUserInfo(): Promise<FacebookUserInfo> {
		return new Promise((resolve) => {
			FB.api('/me', { fields: 'last_name,first_name,email' }, async (response: FacebookUserInfo) => {
				resolve(response);
			})
		})
	}

	/**
	 * Attempt an Apple login and handle its response
	 *
	 * @return {Promise<void>}
	 */
	private async appleLoginAttempt(): Promise<void> {
		const response: AppleLoginResponse = await window.AppleID.auth.signIn();

		// Make sure that the response contains the info needed. We cannot rely on the
		// listener since it's very unstable, maybe only for testing but not taking any chances
		if (response.authorization && response.authorization.id_token) {
			try {
				this.loading.apple = true;
				const decodedToken: DecodedAppleJWTToken = jwt_decode(response.authorization.id_token);

				const userExists: boolean = await this.doesUserExist(decodedToken.email);
				if (userExists) {
					await this.socialLoginOrRegister({ type: 'apple', payload: { socialAuthData : { ...response, nonce: this.appleNonce }, register: false }});
					this.redirectUser();
				}
				else {
					this.$emit('next', { type: 'apple', data: {
						social: { ...response, nonce: this.appleNonce },
						user: response.user && response.user.name ? { firstName: response.user.name.firstName, lastName: response.user.name.lastName } : null,
						email: decodedToken.email
					}});
				}
			} catch (error) {
				this.$toasted.show(this.$t('auth.login.form.error_apple_login'), { type: 'error', position: 'top-center' }).goAway(5000);
			} finally {
				setTimeout(() => {
					this.loading.apple = false;
				}, 500);
			}
		} else {
			this.$toasted.show(this.$t('auth.login.form.error_apple_login'), { type: 'error', position: 'top-center' }).goAway(5000);
		}
	}

	/**
	 * Attempt a native login
	 *
	 * @param {LoginCredentials} loginCredentials
	 * @return {Promise<void>}
	 */
	private async nativeLogin(loginCredentials: LoginCredentials): Promise<void> {
		try {
			this.loading.native = true;
			const userExists: boolean = await this.doesUserExist(loginCredentials.email);
			if (userExists) {
				if (this.isLoginAs) {
					await this.loginAs(loginCredentials)
				}
				else if (this.isSuiteOperatorLogin) {
					await this.operatorLogin({ credentials: loginCredentials });
				}
				else {
					await this.loginWithCredentials(loginCredentials);
				}
				this.redirectUser();
			}
			else {
				this.$emit('next', { type: 'native', data: loginCredentials });
				fireGoogleTag({ name: 'loginSignup', specifier: 'email', detail: 'signup' });
			}
		} catch (error) {
			if (typeof error === 'string') {
				handleAllErrors('', error);
			}
			else {
				handleAllErrors(this.$t('auth.login.form.error_native_login'), error);
			}
		} finally {
			setTimeout(() => {
				this.loading.native = false;
			}, 500);
		}
	}

	/**
	 * Make sure login is successful and redirect user to the correct route
	 *
	 * @return {void}
	 */
	private redirectUser(): void {
		if (!this.user.token && !(this.isSuiteOperatorLogin && this.suiteOperator.token)) {
			return;
		}

		const isGameDayOrdering = this.isSuiteOperatorLogin && this.suiteOperator.token && !this.isPreOrdering;

		// If contact information missing for non suite operators
		if (!isGameDayOrdering && (!this.user.firstName || !this.user.lastName || !this.user.phoneNumber)) {
			this.$router.push({ path: '/profile/user-information', query: this.$route.query }).catch(() => {});
		}
		// If payment method missing for preordering related logins
		else if (this.isPreOrdering && !this.user.paymentMethods.length) {
			this.$router.push({ path: '/profile/user-payment-methods', query: this.$route.query }).catch(() => {});
		}
		// Game day ordering login
		else if (isGameDayOrdering) {
			this.$router.push({ path: `/${this.restaurant.slug}/events`, query: { suiteOperator: 'true', ...this.$route.query } }).catch(() => {});
		}
		else {
			this.$router.push({ path: `/${this.restaurant.slug}`, query: this.$route.query }).catch(() => {});
		}
	}

	/**
	 * Forgot password submit
	 *
	 * @param {string} email
	 * @return {Promise<void>}
	 */
	private async forgotPasswordSubmit(email: string): Promise<void> {
		try {
			this.loading.native = true;
			await this.forgotPassword(email);
			setTimeout(() => {
				this.forgotPasswordToggle = false;
				this.$toasted.show(this.$t('auth.login.form.success_forgot_password'), { position: 'top-center', className: 'success-toast' }).goAway(5000);
			}, 500);
		} catch (error) {
			this.$toasted.show(this.$t('auth.login.form.error_general'), { type: 'error', position: 'top-center' }).goAway(5000);
		} finally {
			setTimeout(() => {
				this.loading.native = false;
			}, 500);
		}
	}

	/**
	 * Emit previous step event to the parent component
	 * to go to the menu or landing page
	 *
	 * @return {void}
	 */
	private prev(): void {
		this.$emit('prev');
	}
}
