import { render, staticRenderFns } from "./MenuResultsHeader.vue?vue&type=template&id=8678a002"
import script from "./MenuResultsHeader.vue?vue&type=script&lang=ts"
export * from "./MenuResultsHeader.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports