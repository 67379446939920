import { GetterTree } from 'vuex';
import { AuthState, RootState } from '../types';

export const getters: GetterTree<AuthState, RootState> = {
	isAuthenticated(state: AuthState): boolean {
		return state.authenticated;
	},

	showWelcomeBanner(state: AuthState): boolean {
		return state.showWelcomeBanner;
	},

	showLoginAsBanner(state: AuthState): boolean {
		return state.showLoginAsBanner;
	},

	getUser(state: AuthState): UserInfo {
		return state.user;
	},

	getSuiteOperator(state: AuthState): UserInfo {
		return state.suiteOperator;
	},

	getFullName(state): string | null {
		if (state.user.firstName && state.user.lastName) {
			return state.user.firstName + ' ' + state.user.lastName;
		}
		else if (state.user.firstName) {
			return state.user.firstName;
		}
		else if (state.user.lastName) {
			return state.user.lastName;
		}
		return null;
	},

	getCreditCards(state): SavedPaymentOption[] {
		return state.user && state.user.paymentMethods;
	},

	isAnonymousUser(state): boolean {
		return state.user && !state.user.id;
	},

	isLoginAs(state): boolean {
		return state.loginAs;
	},

	isSuiteOperatorLogin(state): boolean {
		return state.suiteOperatorLogin;
	},

	isSuiteOperator(state): boolean {
		return !!state.suiteOperator.suiteOperator;
	},

	getLastItemsOrdered(state): LastOrderedItem[] | undefined {
		return state.user?.lastItemsOrdered;
	}
};