
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Debounce } from '@/utils/decorators';
import { getDynamicFieldValue } from '@/utils/helpers';
import SearchIcon from '../../assets/images/icons/search.svg?inline';
import XIcon from 'vue-feather-icons/icons/XIcon';

const namespace: string = 'search';

@Component<SearchBar>({
	components: {
		SearchIcon,
		XIcon
	}
})
export default class SearchBar extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private menus!: Menu[];
	@Action('updateSearchInput', { namespace }) private updateSearchInput!: (searchText: string) => void;
	@Action('updateResultItems', { namespace }) private updateResultItems!: (result: any) => void;
	@Getter('getSearchInput', { namespace }) private searchInput!: string;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Watch('searchInput')
	onSearchInputChange() {
		this.searchText = this.searchInput;
	}

	private searchText: string = '';
	private allItems: MenuItem[] = [];
	private openSearch: boolean = false;

	/**
	 * Add all menu items to an array for the search
	 *
	 * @return {Promise<void}
	 */
	private async created(): Promise<void> {
		this.menus.forEach(menu => {
			if (menu.items) {
				menu.items.forEach(item => {
					this.allItems.push(item);
				});
			}
			if (menu.sections) {
				menu.sections.forEach(section => {
					section.items.forEach(item => {
						this.allItems.push(item);
					});
				});
			}
		});
	}

	/**
	 * Close search
	 *
	 * @return {void}
	 */
	private closeSearch(): void {
		this.searchText = '';
		this.openSearch = false;
		this.setSearchInput();
	}

	/**
	 * Clear search
	 *
	 * @return {void}
	 */
	private clearSearch(): void {
		this.searchText = '';
		this.setSearchInput();
	}

	/**
	 * Update search input
	 *
	 * @return {void}
	 */
	@Debounce(300)
	private setSearchInput(): void {
		this.updateSearchInput(this.searchText);
		this.setResultItems();
	}

	/**
	 * Update result items
	 *
	 * @return {void}
	 */
	private setResultItems(): void {
		let result;
		const itemKeys = ['name', 'description'];
		if (this.searchText != '') {
			result = this.allItems.filter((item: any) =>
				itemKeys.some(k => {
					const field = getDynamicFieldValue(item, item.localization, k, this.restaurant.locales);
					return field != null && field.toString().toLowerCase().includes(this.searchText.toLowerCase())
				})
			);
		}
		this.updateResultItems(result);
	}
}
