
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component<FeaturedItemCard>({})

export default class FeaturedItemCard extends Vue {
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Prop({ type: Object, required: true, default: () => {} }) private item!: MenuItem;
	@Prop({ type: Boolean, required: true, default: false }) private showImage!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private hasImage!: boolean;
	@Prop({ type: String, required: false, default: '' }) private imageUrl!: string;

	$refs!: { featuredItemName: HTMLHeadingElement };

	private numLines: number = 3;

	private mounted(): void {
		this.updateNumLines();
		window.addEventListener('resize', this.updateNumLines);
	}

	private beforeDestroy(): void {
		window.removeEventListener('resize', this.updateNumLines);
	}

	/**
	 * Updates the number of lines for the description based on the title length and screen width
	 *
	 * @return {void}
	 */
	 private updateNumLines(): void {
		const el = this.$refs.featuredItemName;

		if (window.innerWidth > 1024) {
			this.numLines = 4;
		}
		else if (el) {
			this.numLines = el.offsetHeight > 32 ? 2 : 3;
		}
	}

	/**
	 * Open the item viewer
	 *
	 * @return {void}
	 */
	 private clickHandler(): void {
		this.$emit('click');
	}
}
