
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
const namespace: string = 'cart';

@Component<CartTakeoutOptions>({})

export default class CartTakeoutOptions extends Vue {
	@Action('updateDeliveryStatus', { namespace }) private updateDeliveryStatus!: (object: any) => Promise<void>;
	@Getter('getPickupInformation', { namespace }) private pickupInformation!: CheckoutPickupInfo | null;

	private delivery: boolean = false;

	/**
	 * Set up the initial value which is either pickup or
	 * delivery. Pickup is default.
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if(this.pickupInformation && this.pickupInformation.delivery) {
			this.delivery = true;
		}
		else {
			this.delivery = false;
		}
	}

	/**
	 * Set delivery boolean, if false it means the user
	 * wants pickup
	 *
	 * @param {boolean} value
	 * @return {void}
	 */
	private async setDelivery(value: boolean): Promise<void> {
		this.delivery = value;
		await this.updateDeliveryStatus({ delivery: value });
		this.$emit('takeoutTypeChanged');
	}
}
