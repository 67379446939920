import debounce from 'lodash/debounce';

/**
 * Debounce decorator for functions inside a vue component.
 *
 * @param {number} delay - delay in milliseconds to wait
 */
export function Debounce(delay: number) {
	return (target: any, prop: string) => {
		return {
			configurable: true,
			enumerable: false,
			value: debounce(target[prop], delay)
		};
	};
}