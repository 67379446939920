
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { scrollIntoViewIfNeeded } from '@/utils/helpers';
import { findSupportedLocale } from '@/utils/i18n-helpers';
import '@/validation-rules';

const DEFAULT_DELIVERY_INFORMATION = {
	type: 'school',
	extra: ''
};

@Component<DeliverySchool>({
	components: {
		ValidationProvider
	}
})
export default class DeliverySchool extends Vue {
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Prop({ type: Object, required: true, default: () => {} }) private restaurantDeliveryInfo!: SchoolDelivery;
	@Prop({ type: Object, required: true, default: () => {} }) private deliveryInformation!: CheckoutDeliveryInfo;

	private delivery: CheckoutDeliveryInfo = { ...DEFAULT_DELIVERY_INFORMATION };
	private grades: string[] = [];
	private classrooms: string[] = [];

	/**
	* Set grades and classrooms in the selectors
	*
	* @return {void}
	*/
	private created(): void {
		this.grades = this.restaurantDeliveryInfo.grades.split(',');
		this.classrooms = this.restaurantDeliveryInfo.classrooms.split(',');
	}

	private mounted(): void {
		if(this.deliveryInformation) {
			this.delivery = this.deliveryInformation;
			this.delivery.type = 'school';
		}
	}

	/**
	* Send event to the parent to update the delivery
	* information in the vuex store
	*
	* @return {void}
	*/
	private updateDeliveryInfo(): void {
		// If the restaurant has locales, set address with localization
		if (this.restaurant.locales?.length && this.restaurant.locales.length > 1) {
			this.restaurant.locales.forEach((locale: RestaurantLocale) => {
				const supportedLocale: Locale | undefined = findSupportedLocale('', locale.locale_short);
				// Set the default locale as the address
				if (supportedLocale && locale.is_default) {
					this.delivery.address = this.$t('checkout.form.delivery.school.formatted_address', supportedLocale.code, {
						grade: this.delivery.grade,
						classroom: this.delivery.classroom,
						student: this.delivery.student
					});
				}
				// Set the address for the other supported locales
				else if (supportedLocale) {
					this.delivery.localization = {
						...this.delivery.localization,
						[locale.locale_short]: {
							address: this.$t('checkout.form.delivery.school.formatted_address', supportedLocale.code, {
								grade: this.delivery.grade,
								classroom: this.delivery.classroom,
								student: this.delivery.student
							})
						}
					}
				}
			});
		}
		// If no locale, just set the address
		else {
			this.delivery.address = this.$t('checkout.form.delivery.school.formatted_address', {
				grade: this.delivery.grade,
				classroom: this.delivery.classroom,
				student: this.delivery.student
			});
		}

		this.$emit('input', this.delivery);
	}

	/**
	 * Scroll to the bottom when opening a selector
	 *
	 * @param {string} elementId
	 * @return {void}
	 */
	private opened(elementId: string): void {
		setTimeout(() => {
			let contentDiv = document.querySelector(`#${elementId} .vs__dropdown-menu`);
			contentDiv && scrollIntoViewIfNeeded(contentDiv, { behavior: 'smooth', block: 'end', inline: 'start' });
		}, 100);
	}
}
