import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState, SuitesState } from '../types';
import axios from 'axios';
const namespaced: boolean = true;

export const state: SuitesState = {
	eventSuiteId: null,
	userEventSuite: null,
	preOrdering: false,
	minimalEventInfo: null,
	upcomingEvents: [],
	suiteCateringOrder: null,
	eventDayOrdering: false,
	activeEvents: [],
	selectedEvent: null,
	suitesLocationId: null,
	suitesAxiosInstance: axios.create(),
};

export const suites: Module<SuitesState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default suites;