
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { findSupportedLocale } from '@/utils/i18n-helpers';
import StarIcon from '../../../assets/images/icons/star.svg?inline';

const namespace: string = 'cart';

@Component<CustomQuestions>({
	components: {
		StarIcon,
		ValidationProvider
	}
})
export default class CustomQuestions extends Vue {
	@Getter('getCheckoutAnsweredQuestions', { namespace }) private checkoutAnsweredQuestions!: CheckoutCustomQuestion[] | null;
	@Getter('getTableNum', { namespace }) private tableNum!: string | null;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Prop({ type: Array, required: true, default: false }) private questions!: CustomQuestion[];

	private mutableQuestions: CustomQuestion[] = [];

	private get answeredQuestions(): CheckoutCustomQuestion[] {
		return this.mutableQuestions
			.filter((questionObj: CustomQuestion) => {
				if (questionObj.question_type === 'checkbox') {
					return 'checked' in questionObj;
				}
				return questionObj.answerObj && Object.keys(questionObj.answerObj).length
			})
			.map(this.formatAnsweredQuestionObj);
	}

	private mounted(): void {
		this.mutableQuestions = this.questions.map((questionObj: CustomQuestion) => {
			const answeredQuestion: CheckoutCustomQuestion | undefined = this.checkoutAnsweredQuestions?.find((answeredQuestionObj) => answeredQuestionObj.question === questionObj.question);

			// If checkbox question, set the checked value whether it has been answered or not
			if (questionObj.question_type === 'checkbox') {
				return {
					...questionObj,
					checked: answeredQuestion?.answer === this.$t('checkout.form.custom_questions.checked')
				}
			}

			// Else (dropdown question), if the question has been already answered, set the value o that answer
			else if (answeredQuestion) {
				const index = this.checkoutAnsweredQuestions?.findIndex((answeredQuestionObj: CheckoutCustomQuestion) => answeredQuestionObj.question === questionObj.question) as number;
				return {
					...questionObj,
					answerObj: {
						index,
						label: this.$options.filters?.localize(answeredQuestion, 'answer')
					}
				}
			}
			return { ...questionObj };
		});
	}

	/**
	 * Return the localized options in an array of objects containing their indexes.
	 * This index is needed to get each localized answer when building the answeredQuestions array.
	 *
	 * @param {QuestionWithOptions} question
	 * @return {{ index: number; label: string; }[]}
	 */
	private formatDropdownOptions(question: QuestionWithOptions): { index: number; label: string; }[] {
		const localizedOptions: string[] | undefined = this.$options.filters?.localize(question, 'options');
		if (localizedOptions && localizedOptions.length) {
			const output = localizedOptions.map((questionOption: string, index: number) => {
				return {
					index,
					label: questionOption
				};
			});
			return output;
		}
		return question.options.map((questionOption: string, index: number) => {
			return {
				index,
				label: questionOption
			};
		});
	}

	/**
	 * Format the answered questions object depending of the question's type
	 *
	 * @param {CustomQuestion} questionObj
	 * @return {CheckoutCustomQuestion}
	 */
	private formatAnsweredQuestionObj(questionObj: CustomQuestion): CheckoutCustomQuestion {
		// Format basic non-localized question/answer
		let answer: string;
		if (questionObj.question_type === 'checkbox') {
			answer = this.$t(`checkout.form.custom_questions.${(questionObj as QuestionWithCheckbox).checked ? 'checked' : 'unchecked'}`);
		}
		else {
			const index = (questionObj as QuestionWithOptions).answerObj?.index as number;
			answer = (questionObj as QuestionWithOptions).options[index];
		}

		const answeredQuestion: CheckoutCustomQuestion = {
			question: questionObj.question,
			answer
		};

		// If there is localization, add the question and answer for each locale
		const localization: Localization = {};
		if (questionObj.localization && Object.keys(questionObj.localization).length) {
			for (const locale in questionObj.localization) {
				if (questionObj.question_type === 'checkbox') {
					const supportedLocale = findSupportedLocale('', locale);
					localization[locale] = {
						question: questionObj.localization[locale].question,
						answer: this.$i18n.t(`checkout.form.custom_questions.${(questionObj as QuestionWithCheckbox).checked ? 'checked' : 'unchecked'}`, supportedLocale!.code)
					};
				}
				else {
					const index = (questionObj as QuestionWithOptions).answerObj?.index as number;
					localization[locale] = {
						question: questionObj.localization[locale].question,
						answer: questionObj.localization[locale].options![index]
					};
				}
			}
			answeredQuestion.localization = localization;
		}

		return answeredQuestion;
	}

	/**
	 * Send event to update the questions' answers to the parent
	 *
	 * @return {void}
	 */
	private updateQuestions(): void {
		this.$emit('input', this.answeredQuestions);
	}
}
