
import { Vue, Component } from 'vue-property-decorator';
import { QrcodeStream } from 'vue-qrcode-reader';
import QrcodeIcon from '../../assets/images/icons/qrcode.svg?inline';
import '@/validation-rules';

@Component<QrcodeReader>({
	components: {
		QrcodeStream,
		QrcodeIcon
	}
})

export default class QrcodeReader extends Vue {

	private qrCodeLoading: boolean = false;
	private camera: string = 'auto';

	/**
	 * Make sure on creation that the camera is on
	 *
	 * @return {void}
	 */
	private created(): void {
		this.camera = 'auto';
	}

	/**
	 * Outline qrcode when putting on camera
	 *
	 * @param {Object} detectedCodes
	 * @param {Object} ctx
	 * @return {void}
	 */
	private paintOutline(detectedCodes: any, ctx: any): void {
		for (const detectedCode of detectedCodes) {
			const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints;
			ctx.strokeStyle = 'red';
			ctx.beginPath();
			ctx.moveTo(firstPoint.x, firstPoint.y);
			for (const { x, y } of otherPoints) {
				ctx.lineTo(x, y);
			}
			ctx.lineTo(firstPoint.x, firstPoint.y);
			ctx.closePath();
			ctx.stroke();
		}
	}

	/**
	 * On qrcode reader initialization we display a spinner and check for errors
	 *
	 * @param {Promise} promise
	 * @return {void}
	 */
	private async onInit(promise: any): Promise<void> {
		this.qrCodeLoading = true;
		try {
			await promise;
		} catch (error) {
			// Access denied
			if (error.name === 'NotAllowedError') {
				this.$toasted.show(this.$t('shared.qrcode_reader.error_camera_permission_required'), { type: 'error', position: 'top-center' }).goAway(5000);
			}

			// No camera
			else if (error.name === 'NotFoundError') {
				this.$toasted.show(this.$t('shared.qrcode_reader.error_camera_incompatible'), { type: 'error', position: 'top-center' }).goAway(5000);
			}

			// Not supported
			else if (error.name === 'NotSupportedError') {
				this.$toasted.show(this.$t('shared.qrcode_reader.error_internal_camera'), { type: 'error', position: 'top-center' }).goAway(5000);
			}

			// Camera might be in use
			else if (error.name === 'NotReadableError') {
				this.$toasted.show(this.$t('shared.qrcode_reader.error_camera_already_in_used'), { type: 'error', position: 'top-center' }).goAway(5000);
			}

			// Front camera disabled
			else if (error.name === 'OverconstrainedError') {
				this.$toasted.show(this.$t('shared.qrcode_reader.error_no_front_camera_access'), { type: 'error', position: 'top-center' }).goAway(5000);
			}

			// Browser no support
			else if (error.name === 'StreamApiNotSupportedError') {
				this.$toasted.show(this.$t('shared.qrcode_reader.error_no_camera_provided'), { type: 'error', position: 'top-center' }).goAway(5000);
			}

			else {
				this.$toasted.show(this.$t('shared.qrcode_reader.error_browser'), { type: 'error', position: 'top-center' }).goAway(5000);
			}
		} finally {
			this.qrCodeLoading = false;
		}
	}

	/**
	 * Decoding the qr code, send an event for the API
	 *
	 * @param {string} code
	 * @return {void}
	 */
	private async onDecode(code: any): Promise<void> {
		// Make sure the code is plain text
		if(typeof code === 'string') {
			this.$emit('decodeQrcode', code);
		}
	}
}
