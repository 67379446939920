
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import EditIcon from '../../assets/images/icons/edit.svg?inline';

@Component<CartSuiteTab>({
	components: {
		EditIcon,
	},
})
export default class CartSuiteTab extends Vue {
	@Getter('getUserEventSuite', { namespace: 'suites' }) private userEventSuite!: UserEvent;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;

	/**
	 * Get content and css classes for tab display
	 */
	private get tab(): { classList: string[]; content: string } {
		if (this.userEventSuite && this.userEventSuite.tab_limit){
			const balance: string = this.userEventSuite.tab_balance ? Number(this.userEventSuite.tab_balance).toFixed(2) : '0.00';
			const percentUsed: number = (+balance / this.userEventSuite.tab_limit) * 100;

			let classList: string[] = ['tab-limit-content', 'label-md'];

			switch(true) {
			case percentUsed >= 95:
				classList = [...classList, 'danger']
				break;
			case percentUsed >= 80:
				classList = [...classList, 'warning']
				break;
			default:
				classList = [...classList, 'success']
			}

			return {
				classList,
				content: this.$t('cart.viewer.suites.tab', { balance, limit: this.userEventSuite.tab_limit })
			}
		}
		else {
			return {
				classList: ['tab-limit-content', 'label-md', 'info'],
				content: this.$t('cart.viewer.suites.tab_not_active')
			}
		}
	}

	private navigateToSuiteSettings(): void {
		this.$router.push({
			path: `${this.restaurant.slug}/suite-settings`,
			query: {
				eventDayOrdering: this.$route.query.eventDayOrdering,
				eventSuite:this.$route.query.eventSuite,
				suiteOperator: this.$route.query.suiteOperator,
				menuList: this.$route.query.menuList,
				...this.$route.query
			}
		}).catch(() => {});
	}
}
