
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { DateTime } from 'luxon';
import { formatDateTime } from '@/utils/format';
import CartItem from '@/components/cart/CartItem.vue';
import LockIcon from '../../assets/images/icons/lock.svg?inline';
import EditIcon from '../../assets/images/icons/edit.svg?inline';
import CartIcon from '../../assets/images/icons/add-to-cart.svg?inline';

const namespace: string = 'suites';

@Component<UserEventComponent>({
	components: {
		CartItem,
		LockIcon,
		EditIcon,
		CartIcon
	}
})
export default class UserEventComponent extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private userEvent!: UserEvent;
	@Prop({ type: Boolean, required: true, default: false }) private displayItems!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private preOrderSummary!: boolean;
	@Action('setSelectedEventSuiteId', { namespace }) private setSelectedEventSuiteId!: (eventSuiteId: number) => Promise<void>;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('isSuiteOperator', { namespace: 'auth' }) private isSuiteOperator!: boolean;

	private startDate: DateTime = DateTime.fromISO(this.userEvent.event.start_date).setLocale(this.$i18n.locale);
	private preOrderLockDateISO: string = DateTime.fromISO(this.userEvent.event.start_date).minus({ days: this.userEvent.event.order_lock_period }).toISO();
	private preOrderLockDate: string = DateTime.fromISO(this.userEvent.event.start_date).minus({ days: this.userEvent.event.order_lock_period }).toFormat(this.$t('profile.user_events.pre_order_lock_full_date_format'));
	private diffInDays: number | undefined = DateTime.fromISO(this.preOrderLockDateISO).diff(DateTime.local(), 'days').toObject().days;
	private statusAllowsModification: boolean = this.userEvent.status === 'email_scheduled' || this.userEvent.status === 'email_sent' || this.userEvent.status === 'pending';

	private bannerStatusColor: string = '';
	private bannerStatusText: string = '';
	private isPreOrderLocked: boolean = false;

	private suiteCateringOrder: EventSuiteOrder | null = null;
	private suiteCateringOrderCosts = null;

	private get formattedStartTime(): string {
		return this.startDate.toFormat(this.$t('profile.user_events.time_format'));
	}

	private get formattedStartDate(): string {
		return this.startDate.toFormat(this.$t('profile.user_events.date_format'));
	}

	private get isPreOrderDeliveryTimeValidISOString(): boolean {
		return !!this.suiteCateringOrder && !!this.userEvent?.pre_order_delivery_time && DateTime.fromISO(this.userEvent.pre_order_delivery_time).isValid;
	}

	private created(): void {
		this.getSuiteCateringOrder();
	}

	/**
	 * Check if there is orders, if there is we grab the first order
	 * as it will always be the suite catering order.
	 *
	 * @return {void}
	 */
	private getSuiteCateringOrder(): void {
		if(this.userEvent.orders.length) {
			this.suiteCateringOrder = this.userEvent.orders[0];
		}
		this.setBannerStatusText();
		this.setBannerStatusColor();
	}

	/**
	 * Set the banner text
	 *
	 * @return {void}
	 */
	private setBannerStatusText(): void {
		if(this.suiteCateringOrder) {
			this.bannerStatusText = this.$t('profile.user_events.pre_ordering_change');
		}
		else {
			this.bannerStatusText = this.$t('profile.user_events.pre_ordering');
		}
	}

	/**
	 * Set the banner status color.
	 * If the pre order is locked, we overwrite the text as well.
	 *
	 * @return {void}
	 */
	private setBannerStatusColor(): void {
		if(this.diffInDays && this.diffInDays > 1) {
			this.bannerStatusColor = 'status-green';
		}
		else if(this.diffInDays && this.diffInDays < 1 && this.diffInDays > 0) {
			this.bannerStatusColor = 'status-yellow';
		}
		else {
			this.bannerStatusColor = 'status-blue';
			this.bannerStatusText = this.$t('profile.user_events.pre_ordering_locked');
			this.isPreOrderLocked = this.statusAllowsModification && this.isSuiteOperator ? false : true;
		}
	}

	/**
	 * Format the pre order datetime
	 *
	 * @param {string} date
	 * @return {string}
	 */
	private formatLocalDate(date: string): string {
		return formatDateTime(date!);
	}

	/**
	 * Here we handle the pre-order button click. There are 2 cases that will happen in the store:
	 * 1. If the user has already submitted a pre-order, we need to run some logic
	 * to get the previous items and add them to the cart.
	 * 2. If the user has not submitted a pre-order, we simply redirect them to the menu so
	 * they can submit their pre-order.
	 *
	 * @return {Promise<void>}
	 */
	private async goToPreOrder(): Promise<void> {
		if(this.preOrderSummary) {
			this.$emit('setPreOrderCart');
		}
		else {
			await this.setSelectedEventSuiteId(this.userEvent.id);
			this.$router.push({ path: `/${this.restaurant.slug}`, query: { ...this.$router.currentRoute.query, eventSuite: this.userEvent.id.toString() } });
		}
	}
}
