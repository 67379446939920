
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { VueMasonryPlugin } from 'vue-masonry';
import { filterItemsWithAllergensAndDiets } from '@/utils/filters';
import MenuResultsHeader from '@/components/menu/MenuResultsHeader.vue';
import TextMenu from '@/components/menu/TextMenu.vue';
import Sidebar from '@/components/navigation/Sidebar.vue';
import TabBar from '@/components/navigation/TabBar.vue';
import MenuItemViewer from '@/components/menu/MenuItemViewer.vue';
import MenuItemResults from '@/components/menu/MenuItemResults.vue';
import SearchMenuItemResults from '@/components/menu/SearchMenuItemResults.vue';
import Modal from '@/components/shared/Modal.vue';
import FiltersBar from '@/components/filter/FiltersBar.vue';
import SeeMore from '@/components/shared/SeeMore.vue';
const namespace: string = 'filters';

Vue.use(VueMasonryPlugin);

@Component<MenuGroupResults>({
	components: {
		MenuResultsHeader,
		TextMenu,
		MenuItemViewer,
		MenuItemResults,
		SearchMenuItemResults,
		Modal,
		Sidebar,
		TabBar,
		FiltersBar,
		SeeMore
	}
})
export default class MenuGroupResults extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private menuGroups!: MenuGroup[];
	@Prop({ type: Number, required: true, default: 0 }) private currentMenuGroup!: number;
	@Prop({ type: Array, required: true, default: () => [] }) private menus!: Menu[];
	@Prop({ type: Boolean, required: true, default: false }) private orderPaused!: boolean;
	@Prop({ type: String, required: false, default: '' }) private restaurantDisplayName!: string;
	@Prop({ type: String, required: false, default: '' }) private restaurantImage!: string;
	@Prop({ type: String, required: false, default: 'vertical' }) private cardStyle!: string;
	@Getter('getDiets', { namespace }) private diets!: string;
	@Getter('getAllergens', { namespace }) private allergens!: string;
	@Getter('getSelectedDiets', { namespace }) private selectedDiets!: string[];
	@Getter('getSelectedAllergens', { namespace }) private selectedAllergens!: string[];
	@Getter('getResultItems', { namespace: 'search' }) private items!: MenuItem;
	@Getter('getSearchInput', { namespace: 'search' }) private searchText!: string;
	@Getter('isViewOnly', { namespace: 'cart' }) private isViewOnly!: boolean;
	@Watch('menuGroup', { immediate: true })
	onMenuGroupChange() {
		this.filteredMenus = this.menus.filter((menu: Menu) => {
			return menu.menu_group_id === this.menuGroup!.id;
		});
	}
	@Watch('items')
	onItemsChanged() {
		this.updateSearchMasonry();
	}
	@Watch('selectedDiets')
	onFiltersChanged() {
		this.updateMasonry();
	}

	private filteredMenus: Menu[] = [];
	private currentMenuItemId: number | null = null;
	private currentItemMenuId: number | null = null;
	private showDetails: boolean = false;

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.restaurantImage}`;
		}
		return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.restaurantImage}`;
	}

	/**
	 * Get the selected menu group
	 *
	 * @return {MenuGroup | null}
	 */
	private get menuGroup(): MenuGroup | null {
		return this.menuGroups[this.currentMenuGroup];
	}

	/**
	 * Filter the items based on allergens and diets
	 *
	 * @param {MenuItem[]} items
	 * @return {MenuItem[]}
	 */
	private filtered(items: MenuItem[]): MenuItem[] {
		if (this.selectedDiets.length || this.selectedAllergens.length) {
			this.updateMasonry();
			return filterItemsWithAllergensAndDiets(this.selectedDiets, this.selectedAllergens, items);
		}
		else {
			this.updateMasonry();
			return items;
		}
	}

	/**
	 * Redraw masonry event when there are changes in the display
	 * or items showed for the specific menu group chosen.
	 *
	 * @return {void}
	 */
	private updateMasonry(): void {
		this.menuGroups.forEach(() => {
			setTimeout(() => {
				this.$nextTick(() => this.$redrawVueMasonry());
			}, 150);
		});
	}

	/**
	 * Redraw masonry event for search event since the display port
	 * changes.
	 *
	 * @return {void}
	 */
	private updateSearchMasonry(): void {
		this.$nextTick(() => this.$redrawVueMasonry());
	}

	/**
	 * Get the menu_items and section_items from the menus of the menu group
	 *
	 * @return {MenuItem[]}
	 */
	private getMenuGroupItems(): MenuItem[] {
		let menuItems: MenuItem[] = [];
		this.filteredMenus.forEach(menu => {
			menuItems = menuItems.concat(menu.items);
			menu.sections.forEach(section => {
				menuItems = menuItems.concat(section.items);
			});
		});
		return menuItems;
	}

	/**
	 * Get the menu_items and section_items from the menu selected
	 *
	 * @param {number} menuId
	 * @return {MenuItem[]}
	 */
	private menuAcceptsTakeout(menuId: number): boolean {
		const menu = this.filteredMenus.find((menu: Menu) => {
			return menu.id === menuId;
		})!;
		return menu.on_demand || menu.scheduled;
	}

	/**
	 * Opens the selected card's menu item viewer
	 *
	 * @param {MenuItem} menuItem - menu item
	 * @return {void}
	 */
	private viewMenuItem(menuItem: MenuItem): void {
		this.currentMenuItemId = menuItem.id;
		this.currentItemMenuId = menuItem.menu_id;
		this.showDetails = true;
		document.documentElement.classList.add('modal-open');
	}

	/**
	 * Hide the menu viewer modal
	 *
	 * @return {void}
	 */
	private hideMenuItemModal(): void {
		this.showDetails = false;
		this.currentMenuItemId = null;
		document.documentElement.classList.remove('modal-open');
	}
}
