import i18n from '@/i18n';

export const SUPPORTED_LOCALES = [
	{
		label: i18n.t('navbar.locale_switcher.english'),
		value: 'en-CA'
	},
	{
		label: i18n.t('navbar.locale_switcher.french'),
		value: 'fr-CA'
	}
];

export const VIEWS = {
	webUserOnly: ['ProfileSettings', 'UserInformation', 'UserPaymentMethods', 'PastReceipts'],
	suiteOperatorOnly: ['OperatorEventList', 'OperatorSuiteList', 'SuiteSettings']
}