import { extend } from 'vee-validate';
import { required, email, min_value, numeric, integer, regex } from 'vee-validate/dist/rules';
import i18n from '@/i18n';

extend('regex', {
	...regex,
	message: i18n.t('validator.regex')
});

extend('required', {
	...required,
	message: i18n.t('validator.required')
});

extend('email', {
	...email,
	message: i18n.t('validator.email')
});

extend('min', {
	validate(value, args) {
		return value.length >= args.length;
	},
	params: ['length'],
	message: i18n.t('validator.min')
});

extend('max', {
	validate(value, args) {
		return value.length <= args.length;
	},
	params: ['length'],
	message: i18n.t('validator.max')
});

extend('min_value', {
	...min_value,
	message: i18n.t('validator.min_value')
});

extend('numeric', {
	...numeric,
	message: i18n.t('validator.numeric')
});

extend('integer', {
	...integer,
	message: i18n.t('validator.integer')
});

extend('decimal', {
	//@ts-ignore
	validate: (value, { decimals = '*', separator = '.' } = {}) => {
		if (value === null || value === undefined || value === '') {
			return {
				valid: false
			};
		}
		if (Number(decimals) === 0) {
			return {
				valid: /^-?\d*$/.test(value)
			};
		}
		const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
		const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.decimal')
});

extend('greater_than', {
	params: ['target', 'field1-name', 'field2-name'],
	// TO DO: FIX ts error
	//@ts-ignore
	validate(value, { target }) {
		return value > target;
	},
	message: i18n.t('validator.greater_than', { field1: '{field1-name}', field2: '{field2-name}' })
});

// TO DO: FIX ts error
//@ts-ignore
extend('expiry_date', {
	//@ts-ignore
	validate: (date) => {
		if(date.length === 5) {
			const d = new Date();
			const currentYear = d.getFullYear();
			const currentMonth = d.getMonth() + 1;
			const parts = date.split('/');
			const year = parseInt(parts[1], 10) + 2000;
			const month = parseInt(parts[0], 10);
			if (year < currentYear || month > 12 || year > currentYear + 6 || (year == currentYear && month < currentMonth)) {
				return {
					valid: false
				};
			}
			return {
				valid: true
			};
		}
	},
	message: i18n.t('validator.expiry_date')
});

extend('valid_credit_card', {
	validate: (cardNumber) => {
		cardNumber = cardNumber.replace(/\D/g,'');
		const len = cardNumber.length;
		const parity = len % 2;
		let sum = 0;
		for (let i = len - 1; i >= 0; i--) {
			let d = parseInt(cardNumber.charAt(i));
			if(i % 2 == parity) {
				d *= 2;
			}
			if(d > 9) {
				d -= 9;
			}
			sum += d;
		}
		if((sum % 10) === 0) {
			return {
				valid: true
			};
		}
		return {
			valid: false
		};
	},
	message: i18n.t('validator.valid_credit_card')
});

extend('password_contains_general', {
	validate: (value) => {
		if (value === null || value === undefined || value === '') {
			return {
				valid: false
			};
		}
		const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$`);

		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.password_contains_general')
})

extend('password_contains_uppercase', {
	validate: (value) => {
		if (value === null || value === undefined || value === '') {
			return {
				valid: false
			};
		}
		const regex = new RegExp(`(?=.*[A-Z])`);

		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.password_contains_uppercase')
});

extend('password_contains_lowercase', {
	validate: (value) => {
		if (value === null || value === undefined || value === '') {
			return {
				valid: false
			};
		}
		const regex = new RegExp(`(?=.*[a-z])`);

		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.password_contains_lowercase')
});

extend('password_contains_number', {
	validate: (value) => {
		if (value === null || value === undefined || value === '') {
			return {
				valid: false
			};
		}
		const regex = new RegExp(`.*[0-9].*`);

		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.password_contains_number')
});

extend('password_contains_special_character', {
	validate: (value) => {
		if (value === null || value === undefined || value === '') {
			return {
				valid: false
			};
		}
		const regex = new RegExp(`^(?=.*?[@$!%*?&])`);

		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.password_contains_special_character')
});

extend('characters_for_name', {
	validate: (value) => {
		if (value[value.length - 1] === '-' || value[value.length - 1] === '\'') {
			return false
		}

		const regex = /(?![\'-])^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜßŸ\'-\s]*$/i;
		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.characters_for_name')
});