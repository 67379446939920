
import { Vue, Component } from 'vue-property-decorator';
import CheckoutReviewUserDetails from './review/UserDetails.vue';
import CheckoutReviewOrderDetails from './review/OrderDetails.vue';

@Component<CheckoutReview>({
	components: {
		CheckoutReviewUserDetails,
		CheckoutReviewOrderDetails
	}
})
export default class CheckoutReview extends Vue {
	/**
	 * Switch to respective screen depending on the category clicked
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private switchTo(index: number) {
		this.$emit('switchTo', index);
	}

	/**
	 * Submit the order to Numenu API for pre-auth
	 *
	 * @return {void}
	 */
	private confirm(): void {
		this.$emit('confirm');
	}
}
