import Cookies from 'js-cookie';

/**
 * Get preferred browser locale but before we verify if the locale is set in the cookies, meaning
 * the user at one point chose a language with the locale switcher, so we will be using that one.
 *
 * @return {string|undefined}
 */
export default function getBrowserLocale(): string|undefined {
	const savedLocale: string|null|undefined = Cookies.get('locale') ? Cookies.get('locale') : localStorage.getItem('locale');
	let locale: Locale|undefined;
	if(savedLocale) {
		locale = findSupportedLocale(savedLocale, savedLocale.substring(0,2));
	}
	else {
		const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
		const trimmedLocale = navigatorLocale.trim().split(/-|_/)[0];
		if (!navigatorLocale) {
			return undefined;
		}
		locale = findSupportedLocale(navigatorLocale, trimmedLocale);
	}
	return locale && locale.code;
}

/**
 * Get current supported locales
 *
 * @return {Locale[]}
 */
export function getSupportedLocales(): Locale[] {
	return [
		{
			code: 'en-CA',
			trimmed_code: 'en',
			name: 'English CA'
		},
		{
			code: 'fr-CA',
			trimmed_code: 'fr',
			name: 'Canadian French'
		}
	];
}

/**
 * We check if the full locale passed (IE: fr-CA) is supported, if not, we get the trimmed locale 
 * (IE: fr) and we will fetch the closest file to their language. If both are undefined we will
 * fallback to the default fallback.
 *
 * @param {string} localeCountryCode
 * @param {string} trimmedLocaleCode
 * @return {Locale|undefined}
 */
export function findSupportedLocale(localeCountryCode: string, trimmedLocaleCode: string): Locale|undefined {
	const supportedLocales: Locale[] = getSupportedLocales();
	const localeWithCountryCode: Locale|undefined = supportedLocales.find((locale: Locale) => {
		if(locale.code === localeCountryCode) {
			return locale;
		}
	});
	const trimmedLocale: Locale|undefined = supportedLocales.find((locale: Locale) => {
		if(locale.trimmed_code === trimmedLocaleCode) {
			return locale;
		}
	});
	return localeWithCountryCode ? localeWithCountryCode : trimmedLocale;
}