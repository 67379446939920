import { ActionTree } from 'vuex';
import { FiltersState, RootState } from '../types';
import { state } from './filters';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';

export const actions: ActionTree<FiltersState, RootState> = {
	setAvailableFilters({ commit, rootGetters }): void {
		const availableDiets = state.dietOptions.filter((dietOption: FilterTag) => rootGetters['restaurant/getAvailableDiets'].includes(dietOption.value));
		const availableAllergens = state.allergenOptions.filter((allergenOption: FilterTag) => rootGetters['restaurant/getAvailableAllergens'].includes(allergenOption.value));

		commit('SET_FILTERS', {
			diets: availableDiets,
			allergens: availableAllergens
		});
	},

	toggleDiet({ commit }, index: number): void {
		fireGoogleTag({ name: 'filterApplied', detail: 'diet' });
		commit('TOGGLE_DIET', index);
	},

	toggleAllergens({ commit }, index: number): void {
		fireGoogleTag({ name: 'filterApplied', detail: 'allergen' });
		commit('TOGGLE_ALLERGENS', index);
	},

	clearFilters({ commit }): void {
		fireGoogleTag({ name: 'filterApplied', detail: 'clear' });
		commit('CLEAR_SELECTED_FILTERS');
	}
};