import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { SearchState, RootState } from '../types';
const namespaced: boolean = true;

export const state: SearchState = {
	searchInput: '',
	items: []
};


export const Search: Module<SearchState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Search;