
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
import LogoutIcon from '../../assets/images/icons/logout.svg?inline';
import EventListIcon from '../../assets/images/icons/event-list.svg?inline';
import ReceiptsIcon from '../../assets/images/icons/receipts.svg?inline';
import SupportIcon from '../../assets/images/icons/support.svg?inline';
import AvatarIcon from '../../assets/images/icons/user-avatar.svg?inline';
import EditIcon from '../../assets/images/icons/edit.svg?inline';

const namespace: string = 'auth';

@Component<ProfileSettings>({
	components: {
		ArrowLeftIcon,
		LogoutIcon,
		EventListIcon,
		ReceiptsIcon,
		SupportIcon,
		AvatarIcon,
		EditIcon,
		PlusIcon
	}
})
export default class ProfileSettings extends Vue {
	@Action('logout', { namespace }) private logout!: () => Promise<void>;
	@Action('suiteLogout', { namespace }) private suiteLogout!: () => Promise<void>;
	@Getter('getUser', { namespace }) private user!: UserInfo;
	@Getter('showLoginAsBanner', { namespace }) private showLoginAsBanner!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('isEventDayOrdering', { namespace: 'suites' }) private isEventDayOrdering!: boolean;
	private promoCode: string = '';
	private loading = {
		promoCode: false,
		logout: false
	};

	/**
	 * Full name of the user
	 *
	 * @return {string}
	 */
	private get fullName(): string {
		return (this.user.firstName || this.user.lastName) ? `${this.user.firstName} ${this.user.lastName}` : '';
	}

	/**
	 * Payment method selected as default
	 *
	 * @return {PaymentOption | undefined}
	 */
	private get selectedPaymentMethod(): SavedPaymentOption | undefined {
		return this.user.paymentMethods.find(paymentOption => paymentOption.isDefault);
	}

	/**
	 * Redirect to the page provided
	 *
	 * @param {string} path
	 * @return {void}
	 */
	private redirectTo(path: string): void {
		this.$router.push({ path: path, query: this.$route.query }).catch(() => {});
	}

	/**
	 * Log user out
	 * Clear cookies and state
	 * Redirect to login page
	 *
	 * @return {Promise<void>}
	 */
	private async logoutUser(): Promise<void> {
		try {
			this.loading.logout = true;
			this.logOutFromFacebook(); // TO REMOVE BEFORE PUSHING TO PROD
			await this.logout();

			this.redirectTo('/login');
		} catch (error) {
			this.$toasted.show(this.$t('auth.login.form.error_general'), { type: 'error', position: 'top-center' }).goAway(5000);
		} finally {
			setTimeout(() => {
				this.loading.logout = false;
			}, 500);
		}
	}

	/**
	 * Log out suite operator from the suite owner's account
	 *
	 * @return {Promise<void>}
	 */
	private async logoutFromSuite(): Promise<void> {
		try {
			this.loading.logout = true;
			await this.suiteLogout();

			this.$router.push({ path: `/${this.restaurant.slug}/events`, query: { suiteOperator: 'true' }}).catch(() => {});
		} catch (error) {
			this.$toasted.show(this.$t('auth.login.form.error_general'), { type: 'error', position: 'top-center' }).goAway(5000);
		} finally {
			setTimeout(() => {
				this.loading.logout = false;
			}, 500);
		}
	}

	/**
	 * Logout user from Facebook if logged in
	 *
	 * @return {void}
	 */
	private logOutFromFacebook(): void {
		FB.getLoginStatus((response: fb.StatusResponse) => {
			if (response.status === 'connected') {
				FB.logout();
			}
		})
	}
}
