
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { scrollIntoViewIfNeeded } from '@/utils/helpers';
import '@/validation-rules';

const DEFAULT_DELIVERY_INFORMATION = {
	type: 'custom',
	extra: ''
};

@Component<DeliveryCustom>({
	components: {
		ValidationProvider
	}
})
export default class DeliveryCustom extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private restaurantDeliveryInfo!: Delivery;
	@Prop({ type: Object, required: true, default: () => {} }) private deliveryInformation!: CheckoutDeliveryInfo;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;

	private delivery: CheckoutDeliveryInfo = { ...DEFAULT_DELIVERY_INFORMATION };
	private customDeliveryLocations: CustomDeliveryLocation[] = [];
	private areas: CustomDeliveryArea[] = [];
	private selectedLocation: { location: string; localizedLocation: string; } = { location: '', localizedLocation: ''};
	private selectedArea: CustomDeliveryArea = { area: '', localizedArea: '' };

	/**
	 * Set custom locations
	 *
	 * @return {void}
	*/
	private created(): void {
		this.customDeliveryLocations = this.restaurantDeliveryInfo.custom!.map((element: CustomDeliveryElement) => {
			const areas: string[] = element.areas.split(',').map((area: string) => area.trim());
			const localizedAreasString: string = this.$options.filters?.localize(element, 'areas');
			const localizedAreas: string[] = localizedAreasString.split(',').map((localizedArea: string) => localizedArea.trim());

			return {
				location: element.location,
				localizedLocation: this.$options.filters?.localize(element, 'location'),
				areas: areas.map((area, index) => ({
					area: area,
					localizedArea: localizedAreas[index] || area
				}))
			}
		});
	}

	/**
	 * Set the current selected values if the user is coming back
	 *t o the contact information page
	 *
	 * @return {void}
	*/
	private mounted(): void {
		if(this.deliveryInformation) {
			const location = this.customDeliveryLocations.find((element: CustomDeliveryLocation) => element.location === this.deliveryInformation.location);
			if(location) {
				this.updateLocation(location);
				this.selectedArea = { area: this.deliveryInformation.area!, localizedArea: this.deliveryInformation.localization && this.deliveryInformation.localization[this.$i18n.locale?.substring(0,2).toLowerCase()]?.area! || this.deliveryInformation.area! };
				this.delivery = this.deliveryInformation;
				this.delivery.type = 'custom';
			}
		}
	}

	/**
	* Save the location with the appropriate locale and update the areas
	*
	* @param {CustomDeliveryLocation} customDeliveryLocation
	* @return {void}
	*/
	private updateLocation(customDeliveryLocation: CustomDeliveryLocation): void {
		this.selectedArea = { area: '', localizedArea: '' };
		this.selectedLocation = { location: customDeliveryLocation.location, localizedLocation: customDeliveryLocation.localizedLocation };
		this.areas = customDeliveryLocation.areas;
	}

	/**
	* Save the area with the appropriate locale and update the delivery info
	*
	* @param {CustomDeliveryArea} customDeliveryLocationArea
	* @return {void}
	*/
	private updateArea(customDeliveryLocationArea: CustomDeliveryArea): void {
		this.selectedArea = customDeliveryLocationArea;
		this.updateDeliveryInfo();
	}

	/**
	* Send event to the parent to update the delivery
	* information in the vuex store
	* Note: The way we are doing the localization works completely fine for now, since we only support english/french.
	* If we ever need to support more languages, we will need to change the way we are storing the localization here.
	*
	* @return {void}
	*/
	private updateDeliveryInfo(): void {
		this.delivery.location = this.selectedLocation!.location;
		this.delivery.area = this.selectedArea!.area;
		const locale = this.$i18n.locale?.substring(0,2).toLowerCase();
		const locationLabel = this.restaurantDeliveryInfo.location_label ? this.restaurantDeliveryInfo.location_label : this.$t('checkout.form.delivery.custom.location_label');
		const areasLabel = this.restaurantDeliveryInfo.areas_label ? this.restaurantDeliveryInfo.areas_label : this.$t('checkout.form.delivery.custom.area_label');
		this.delivery.address = `${locationLabel}: ${this.delivery.location}, ${areasLabel}: ${this.delivery.area}`;
		if(this.restaurant.locales?.length) {
			const defaultLocale = this.restaurant.locales.find((locale: RestaurantLocale) => locale.is_default);
			if(defaultLocale?.locale_short !== this.$i18n.locale?.substring(0,2).toLowerCase()) {
				this.delivery.localization = {
					[locale]: {
						location: this.selectedLocation?.localizedLocation,
						area: this.selectedArea?.localizedArea,
						address: `${this.$options.filters?.localize(this.restaurantDeliveryInfo, 'location_label')}: ${this.selectedLocation?.localizedLocation}, ${this.$options.filters?.localize(this.restaurantDeliveryInfo, 'areas_label')}: ${this.selectedArea?.localizedArea}`
					}
				}
			}
		}
		this.$emit('input', this.delivery);
	}

	/**
	 * Scroll to the bottom when opening a selector
	 *
	 * @param {string} elementId
	 * @return {void}
	 */
	private opened(elementId: string): void {
		setTimeout(() => {
			let contentDiv = document.querySelector(`#${elementId} .vs__dropdown-menu`);
			contentDiv && scrollIntoViewIfNeeded(contentDiv, { behavior: 'smooth', block: 'end', inline: 'start' });
		}, 100);
	}
}
