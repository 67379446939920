import { GetterTree } from 'vuex';
import { FiltersState, RootState } from '../types';

export const getters: GetterTree<FiltersState, RootState> = {
	getDiets(state: FiltersState): Array<FilterTag> {
		return state.dietOptions;
	},

	getAllergens(state: FiltersState): Array<FilterTag> {
		return state.allergenOptions;
	},

	getSelectedDiets(state: FiltersState): string[] {
		const selectedDiets: string[] = [];
		state.dietOptions.forEach(filter => {
			if (filter.selected){
				selectedDiets.push(filter.value);
			}
		});
		return selectedDiets;
	},

	getSelectedAllergens(state: FiltersState): string[] {
		const selectedAllergens: string[] = [];
		state.allergenOptions.forEach(filter => {
			if (filter.selected){
				selectedAllergens.push(filter.value);
			}
		});
		return selectedAllergens;
	},

	isAnyDietOrAllergen(_state: FiltersState, getters: any): boolean {
		return getters.getDiets.length || getters.getAllergens.length
	}
};