
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import CameraIcon from 'vue-feather-icons/icons/CameraIcon';
import QrcodeIcon from '../../assets/images/icons/qrcode.svg?inline';
import QrcodeReader from '@/components/shared/QrcodeReader.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import '@/validation-rules';

const namespace: string = 'cart';

@Component<CartVoucher>({
	components: {
		ValidationObserver,
		ValidationProvider,
		QrcodeIcon,
		QrcodeReader,
		CameraIcon,
		Tooltip
	}
})

export default class CartVoucher extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private restaurantVoucherInfo!: Voucher;
	@Action('validateVoucherCodeAndGetValue', { namespace }) private validateVoucherCodeAndGetValue!: (object: any) => void;
	@Getter('getVoucherInfo', { namespace }) private voucherInfo!: any;

	$refs!: {observer: Validate}

	private voucherCode: string = '';
	private voucherMessage: string = '';
	private validatingCode: boolean = false;
	private showQrcodeReader: boolean = false;
	private qrCodeLoading: boolean = false;
	private tooltipOpened: boolean = false;

	/**
	 * Get voucher code if it was applied before
	 *
	 * @return {void}
	 */
	private created(): void {
		if(this.voucherInfo && this.voucherInfo.code) {
			this.voucherCode = this.voucherInfo.code;
			this.voucherMessage = this.$t('cart.voucher.code_applied');
		}
	}

	/**
	 * Decode qr code
	 *
	 * @return {void}
	 */
	private async decodeQrcode(code: string): Promise<void> {
		Vue.set(this, 'voucherCode', code);
		await this.$nextTick().then(() => {
			this.applyVoucher();
		});
	}

	/**
	 * Validate and apply voucher to the subtotal
	 *
	 * @return {void}
	 */
	private async applyVoucher(): Promise<void> {
		let isValid = await this.$refs.observer.validate();

		if(isValid) {
			this.voucherMessage = '';
			try {
				this.validatingCode = true;
				await this.validateVoucherCodeAndGetValue(this.voucherCode);
				this.voucherMessage = this.$t('cart.voucher.code_applied');
			} catch (error) {
				this.voucherCode = '';
				if(typeof error === 'string') {
					this.voucherMessage = error;
				}
				else {
					this.voucherMessage = this.$t('cart.voucher.code_failed');
				}
			} finally {

				// Give some time to the loader to prevent flashing (response coming back too fast)
				setTimeout(() => {
					this.validatingCode = false;
				}, 500);
				this.voucherCode = this.voucherInfo.code;
				this.showQrcodeReader = false;
			}
		}
	}

	/**
	 * Scroll to the bottom when opening a selector
	 *
	 * @return {void}
	 */
	private openCloseQrcodeReader(): void {
		this.showQrcodeReader = !this.showQrcodeReader;

		if (this.showQrcodeReader) {
			setTimeout(() => {
				var contentDiv = document.getElementById('cart-content');
				contentDiv!.scrollTop = 500;
			}, 100);
		}
	}
}
