
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

const namespace: string = 'filters';

@Component<ItemInformation>({})
export default class ItemInformation extends Vue {
	@Getter('getDiets', { namespace }) private dietOptions!: any[];
	@Getter('getAllergens', { namespace }) private allergenOptions!: any[];
	@Prop({ type: Object, required: true, default: () => {} }) private menuItem!: MenuItem;
	@Prop({ type: Number, required: false }) private displayCalories!: number;

	/**
	 * Get the allergens values
	 *
	 * @return {string[]}
	 */
	private get allergens(): string[] {
		let allergens: string[] = [];
		if (this.menuItem.allergens) {
			allergens = this.menuItem.allergens.toLowerCase().replace(/(_|-)/gi,' ').split(',');
			allergens = allergens.map((allergen: any) => {
				const allergenObject = this.allergenOptions.find((allergenOption: any) => { return allergen === allergenOption.value.toLowerCase() });
				return allergenObject ? allergenObject.itemLabel.toLowerCase() : allergen;
			});
		}
		return allergens;
	}

	/**
	 * Get the dietary values
	 *
	 * @return {string[]}
	 */
	private get diets(): string[] {
		let diets: string[] = [];
		if (this.menuItem.diets) {
			diets = this.menuItem.diets.toLowerCase().replace(/(_|-)/gi,' ').split(',');
			diets = diets.map((diet: any) => {
				const dietObject = this.dietOptions.find((dietOption: any) => { return diet === dietOption.value.toLowerCase() });
				return dietObject ? dietObject.itemLabel.toLowerCase() : diet;
			});
		}
		return diets;
	}
}
