
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ConfirmationOrder from './confirmation/ConfirmationOrder.vue';
import ConfirmationPreOrder from './confirmation/ConfirmationPreOrder.vue';

const namespace: string = 'suites';

@Component<CheckoutConfirmation>({
	components: {
		ConfirmationOrder,
		ConfirmationPreOrder
	}
})
export default class CheckoutConfirmation extends Vue {
	@Getter('getSuiteCateringOrder', { namespace }) private suiteCateringOrder!: EventSuiteOrder | null;
	@Getter('isPreOrdering', { namespace }) private isPreOrdering!: string;

	private close(): void {
		this.$emit('close');
	}

	private switchToCart(): void {
		this.$emit('switchTo', -2);
	}
}
