import { MutationTree } from 'vuex';
import { FiltersState } from '../types';


export const mutations: MutationTree<FiltersState> = {
	/**
	 * Set filters state
	 *
	 * @param {FiltersState} state
	 * @param {{ diets: FilterTag[], allergens: FilterTag[] }} availableOptions
	 */
	SET_FILTERS(state: FiltersState, availableOptions: { diets: FilterTag[], allergens: FilterTag[] }): void {
		state.dietOptions = availableOptions.diets;
		state.allergenOptions = availableOptions.allergens;
	},

	/**
	 * Update if diet filter is selected or not
	 *
	 * @param {FiltersState} state
	 * @param {number} index
	 * @return {void}
	 */
	TOGGLE_DIET(state: FiltersState, index: number): void {
		state.dietOptions[index].selected = !state.dietOptions[index].selected;
	},

	/**
	 * Update if allergen filter is selected or not
	 *
	 * @param {FiltersState} state
	 * @param {number} index
	 * @return {void}
	 */
	TOGGLE_ALLERGENS(state: FiltersState, index: number): void {
		state.allergenOptions[index].selected = !state.allergenOptions[index].selected;
	},

	/**
	 * Set all filters to false
	 *
	 * @param {FiltersState} state
	 * @return {void}
	 */
	CLEAR_SELECTED_FILTERS(state: FiltersState): void {
		state.dietOptions.forEach(filter => {
			filter.selected = false;
		});
		state.allergenOptions.forEach(filter => {
			filter.selected = false;
		});
	}
};
