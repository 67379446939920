import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { LayoutState, RootState } from '../types';
const namespaced: boolean = true;

export const state: LayoutState = {
	tabBar: false
};


export const Search: Module<LayoutState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Search;