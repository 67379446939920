
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import LoginSignup from '@/components/auth/LoginSignup.vue';
import ContactInformation from '@/components/auth/ContactInformation.vue';
import PaymentMethods from '@/components/auth/PaymentMethods.vue';
import CreditCardInfo from '@/components/auth/CreditCardInfo.vue';
import { handleAllErrors } from '@/utils/errorHandling';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
const namespace: string = 'auth';

@Component<Login>({
	components: {
		LoginSignup,
		ContactInformation,
		PaymentMethods,
		CreditCardInfo
	}
})
export default class Login extends Vue {
	@Action('registerUser', { namespace }) private registerUser!: (credentials: any) => Promise<void>;
	@Action('socialLoginOrRegister', { namespace }) private socialLoginOrRegister!: (socialPayload: SocialLoginOrRegisterPayload) => Promise<void>;
	@Getter('getUser', { namespace }) private user!: UserInfo;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;

	private steps = ['LoginSignup', 'ContactInformation', 'PaymentMethods', 'CreditCardInfo'];
	private currentStep = 0;
	private transitionName = 'slide-next';
	private email = '';
	private loading = '';
	private loginType = '';
	private responseSocialAuth: any = {};

	private loginCredentials: LoginCredentials = {
		email: '',
		password: ''
	};

	private userInfo: UserContactInfo = {
		firstName: '',
		lastName: '',
		phoneNumber: ''
	};

	private paymentMethods: PaymentOption[] = [];

	private creditCardToEdit: PaymentOption = {
		ccNumber: '',
		expiryDate: '',
		cvs: '',
		postalCode: '',
		isDefault: false
	};

	/**
	 * Handle what to do with the payload depending of the step
	 * 
	 * @param {StepLoginPayload | UserContactInfo | StepPaymentOptionsPayload | StepAddCardPayload} payload
	 * @return {void}
	 */
	private nextHandler(payload: StepLoginPayload | UserContactInfo | StepPaymentOptionsPayload | StepAddCardPayload): void {
		if (this.currentStep === 0) {
			this.stepLoginHandler(payload as StepLoginPayload);
		}
		else if (this.currentStep === 1) {
			this.stepFillContactInfo(payload as UserContactInfo);
		}
		else if (this.currentStep === 2) {
			this.stepPaymentOptionsHandler(payload as StepPaymentOptionsPayload);
		}
		else if (this.currentStep === 3) {
			this.stepAddOrModifyCardHandler(payload as StepAddCardPayload)
		}
	}

	/**
	 * Fill the user's contact information
	 * 
	 * @param {StepLoginPayload} payload
	 * @return {void}
	 */
	private stepFillContactInfo(payload: UserContactInfo): void {
		this.userInfo = { ...this.userInfo, ...payload };
		this.toNextStep();
	}

	/**
	 * Cache the login info and trigger next step
	 * 
	 * @param {StepLoginPayload} payload
	 * @return {void}
	 */
	private stepLoginHandler(payload: StepLoginPayload): void {
		const { type, data } = payload;
		this.loginType = type;
		this.email = data.email;
		if (type === 'native') {
			this.loginCredentials = data as LoginCredentials;
			this.toNextStep();
		}
		else if (type === 'google' || type === 'facebook' || type === 'apple') {
			this.responseSocialAuth = (data as StepSocialLoginData).social;
			this.userInfo = { ...this.userInfo, ...(data as StepSocialLoginData).user };
			this.toNextStep();
			fireGoogleTag({ name: 'loginSignup', specifier: type, detail: 'signup' });
		}
	}

	/**
	 * Handle what to do in that step depending of the payload
	 * 
	 * @param {any} payload
	 * @return {void}
	 */
	private stepPaymentOptionsHandler(payload: StepPaymentOptionsPayload): void {
		if (payload.type === 'updateDefault') {
			this.paymentMethods.forEach(paymentOption => paymentOption.isDefault = false);
			this.editPaymentOption(payload.paymentMethod as PaymentOption);
		}
		else if (payload.type === 'edit') {
			this.creditCardToEdit = {
				...payload.paymentMethod as PaymentOption,
				cvs: '•••'
			};
			this.toNextStep();
		}
		else {
			this.creditCardToEdit = {
				ccNumber: '',
				expiryDate: '',
				cvs: '',
				postalCode: '',
				isDefault: false
			}
			this.toNextStep();
		}
	}

	/**
	 * Add, edit or delete a payment option
	 * 
	 * @param {any} payload
	 * @return {void}
	 */
	private stepAddOrModifyCardHandler(payload: StepAddCardPayload): void {
		// If new card is default, unselect default for the other cards
		if (payload.paymentInfo.isDefault) {
			this.paymentMethods.forEach(paymentOption => paymentOption.isDefault = false);
		}
		if (payload.type === 'edit') {
			this.editPaymentOption(payload.paymentInfo);
			this.toPreviousStep();
		}
		else if (payload.type === 'delete') {
			this.deletePaymentOption(payload.paymentInfo.ccNumber);
			this.toPreviousStep();
		}
		else {
			this.paymentMethods.push(payload.paymentInfo);
			this.toPreviousStep();
		}
		fireGoogleTag({ name: `${payload.type}PaymentMethod` });
	}

	/**
	 * Finds index of paymentOption and deletes it
	 * 
	 * @param {string} cardNumber
	 * @return {void}
	 */
	private deletePaymentOption(cardNumber: string): void {
		const index = this.paymentMethods.findIndex(paymentOption => {
			return paymentOption.ccNumber === cardNumber;
		});
		this.paymentMethods.splice(index, 1);
	}
	
	/**
	 * Edit payment option with the matching id
	 * 
	 * @param {PaymentOption} paymentInfo
	 * @return {void}
	 */
	private editPaymentOption(paymentInfo: PaymentOption): void {
		this.paymentMethods = this.paymentMethods.map(paymentOption => {
			if (paymentOption.ccNumber === paymentInfo.ccNumber) {
				return paymentInfo;
			}
			return paymentOption;
		});
	}

	/**
	 * Format the data object that will be sent to register the user
	 * depending if it's a native or social register
	 * 
	 * @param {string} type
	 * @return {RegisterPayloadTypes}
	 */
	private buildRegisterPayload(type: string): RegisterPayloadTypes {
		let output = {
			userInfo: this.userInfo,
			paymentMethods: this.paymentMethods,
			register: true
		} as RegisterPayloadTypes;

		if (this.loginType === 'native') {
			output = { ...output, credentials: this.loginCredentials } as RegisterUserPayload;
		}
		else if (this.loginType === 'google') {
			output = { ...output, accessToken: this.responseSocialAuth.access_token } as GoogleLoginPayload;
		}
		else if (this.loginType === 'facebook') {
			output = { ...output, accessToken: this.responseSocialAuth.authResponse.accessToken } as FacebookLoginPayload;
		}
		else {
			output = { ...output, socialAuthData: this.responseSocialAuth } as AppleLoginPayload;
		}

		// If the user chooses to skip adding payment methods, delete property from payload
		if (type === 'skip') {
			delete output.paymentMethods;
		}
		return output;
	}

	/**
	 * Register the user depending if it's native or social then redirect
	 * 
	 * @param {string} type
	 * @return {Promise<void>}
	 */
	private async register(type: string): Promise<void> {
		this.loading = type;
		let payload = this.buildRegisterPayload(type);
		try {
			if (this.loginType === 'native') {
				await this.registerUser(payload as RegisterUserPayload);
			}
			else {
				await this.socialLoginOrRegister({ type: this.loginType, payload: payload } as SocialLoginOrRegisterPayload);
			}
			this.redirectUser();
		} catch (error) {
			handleAllErrors(this.$t('auth.login.form.error_register'), error);
		} finally {
			setTimeout(() => {
				this.loading = '';
			}, 500);
		}
	}

	/**
	 * Make sure login was successful and redirect user to the correct route
	 * 
	 * @return {void}
	 */
	private redirectUser(): void {
		if (this.user.token) {
			if (!this.user.firstName || !this.user.lastName || !this.user.phoneNumber) {
				this.$router.push({ path: '/profile/user-information', query: this.$route.query }).catch(() => {});
			}
			else {
				this.$router.push({ path: `/${this.restaurant.slug}`, query: this.$route.query }).catch(() => {});
			}
		}
	}

	/**
	 * Update transition name and increase step
	 * 
	 * @return {void}
	 */
	private toNextStep(): void {
		this.transitionName = 'slide-next';
		this.currentStep++;
	}

	/**
	 * Update transition name
	 * Decrease step or redirect to profile
	 * 
	 * @return {void}
	 */
	private toPreviousStep(): void {
		if (this.currentStep === 0) {
			this.transitionName = '';
			fireGoogleTag({ name: 'previousStep', detail: this.steps[this.currentStep] });
			// TODO SEND BACK TO LANDING PAGE IF THERE IS ONE, OTHERWISE SEND TO MENU
			this.$router.push({ path: `/${this.restaurant.slug}`, query: this.$route.query }).catch(() => {});
		}
		else {
			this.transitionName = 'slide-prev';
			fireGoogleTag({ name: 'previousStep', detail: this.steps[this.currentStep] });
			this.currentStep--;
		}
	}
}
