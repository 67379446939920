/**
 * A timer that can be paused and resumed.
 * 
 * @param callback The function to call when the timeout is complete.
 * @param delay The delay in milliseconds.
 */
export default class Timer {
	constructor(callback: Function,  delay: number) {
		this.callback = callback;
		this.remaining = delay;
		this.timerId = window.setTimeout(this.callback, this.remaining);
		this.start = Date.now();
	}

	private callback: Function;
	private remaining: number;
	private start: number;
	private timerId: number | null;

	// clear existing timeout and calculate remaining time by subtracting current time from start time
	public pause(): void {
		if (!this.timerId) {
			return;
		}
		window.clearTimeout(this.timerId);
		this.timerId = null;
		this.remaining -= Date.now() - this.start;
	}

	// set new start time and new timeout
	public resume(): void {
		if (this.timerId) {
			return;
		}

		this.start = Date.now();
		this.timerId = window.setTimeout(this.callback, this.remaining);
	}
}