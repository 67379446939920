/**
 * Get the menu styling from the menu_configuration field
 * and apply them respectively.
 *
 * @param {Restaurant} restaurant
 * @return {Promise}
 */
export async function getMenuStyle(restaurant: Restaurant): Promise<any> {
	const theme = restaurant.theme;
	const primaryColor = restaurant.primaryColor;
	const useRestaurantFont = restaurant.defaultFont;
	const primaryFont = restaurant.primaryFont;
	const secondaryFont = restaurant.secondaryFont;

	if (theme) {
		selectTheme(theme);
	}
	if (primaryColor) {
		document.documentElement.style.setProperty('--primary-content-color', primaryColor);
	}
	if (!useRestaurantFont) {
		if (primaryFont) {
			document.documentElement.style.setProperty('--primary-font', primaryFont);
			importFont(primaryFont, 'custom-primary-font');
		}
		if (secondaryFont) {
			document.documentElement.style.setProperty('--secondary-font', secondaryFont);
			importFont(secondaryFont,'custom-secondary-font');
		}
	}
}

/**
 * Import the correct fonts passed from the menu_config from google api.
 *
 * @param {string} font
 * @param {string} querySelector
 * @return {void}
 */
function importFont(font: string, querySelector: string): void {
	const linkElem = document.querySelector(`link[id='${querySelector}']`);
	const fontNoSpace = font.replace(/\s/g, '+');
	const url = `https://fonts.googleapis.com/css?family=${fontNoSpace}`;
	if (linkElem) {
		document.head.removeChild(linkElem);
	}
	const file = document.createElement('link');
	file.id = querySelector;
	file.rel = 'stylesheet';
	file.href = url;
	document.head.appendChild(file);
}

/**
 * Select specific theme css file.
 *
 * @param {string} font
 * @param {string} querySelector
 * @return {void}
 */
export function selectTheme(theme: string): void {
	const linkElem = document.querySelector('link[id="custom-theme"]');

	if (linkElem) {
		document.head.removeChild(linkElem);
	}

	if (theme && theme.length && theme != 'light') {
		const file = document.createElement('link');
		file.id = 'custom-theme';
		file.rel = 'stylesheet';
		file.href = `${process.env.VUE_APP_BASE_URL}/stylesheets/${theme}.css`;
		document.head.appendChild(file);


		// Keeping this for backwards compatibility for now but should be removed
		// TODO: Update this to be in the dark stylesheet
		if(theme === 'dark') {
			document.documentElement.style.setProperty('--text-color', '#e8e8e8');
			document.documentElement.style.setProperty('--secondary-content-color', '#e8e8e8');
		}
	}
}

/**
 * Change the favicon based on the restaurant config, will fall back to the default favicon if not set
 *
 * @param {string} src - source of the favicon
 */
export function changeFavicon(src: string) {
	const link = document.querySelector("link[rel~='icon']");
	if (src && link instanceof HTMLLinkElement) {
		link.href = src;
	}
}
