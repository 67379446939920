import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Index from '../views/Index.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
	// NO HOMEPAGE FOR NOW
	{
		path: '/',
		name: 'home',
		component: Index
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
	},
	{
		path: '/profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
		children: [
			{
				path: 'user-information',
				name: 'UserInformation',
				component: () => import(/* webpackChunkName: "profile-user-information" */ '../views/profile/UserInformation.vue')
			},
			{
				path: 'user-payment-methods',
				name: 'UserPaymentMethods',
				component: () => import(/* webpackChunkName: "profile-user-payment-methods" */ '../views/profile/UserPaymentMethods.vue')
			},
			{
				path: '',
				name: 'ProfileSettings',
				component: () => import(/* webpackChunkName: "profile-settings" */ '../views/profile/Profile.vue')
			},
			{
				path: 'events',
				name: 'EventList',
				component: () => import(/* webpackChunkName: "event-list" */ '../views/profile/EventList.vue')
			},
			{
				path: 'receipts',
				name: 'PastReceipts',
				component: () => import(/* webpackChunkName: "profile-receipts" */ '../views/profile/PastReceipts.vue')
			}
		]
	},
	{
		path: '/cantine',
		name: 'Cantine',
		component: () => import(/* webpackChunkName: "concourses" */ '../views/Concourses.vue'),
	},
	{
		path: '/bowlero-location-search',
		name: 'LocationSearch',
		component: () => import(/* webpackChunkName: "location-search" */ '../views/GeolocationSearch.vue')
	},
	{
		path: '/bowlero-coming-soon',
		name: 'ComingSoon',
		component: () => import(/* webpackChunkName: "coming-soon" */ '../views/ComingSoon.vue')
	},
	{
		path: '/:restaurant_slug',
		component: () => import(/* webpackChunkName: "restaurant" */ '../views/restaurant/Index.vue'),
		props: route => {
			return { restaurantSlug: route.params.restaurant_slug };
		},
		children: [
			{
				path: 'landing',
				name: 'Landing',
				component: () => import(/* webpackChunkName: "restaurant-landing" */ '../views/restaurant/Landing.vue')
			},
			{
				path: '',
				name: 'Menu',
				component: () => import(/* webpackChunkName: "restaurant-menu" */ '../views/restaurant/Menu.vue')
			},
			{
				path: 'order-notification',
				name: 'OrderNotification',
				component: () => import(/* webpackChunkName: "restaurant-order-notification" */ '../views/restaurant/OrderNotification.vue'),
				props: route => {
					return { restaurantSlug: route.params.restaurant_slug};
				}
			},
			{
				path: 'events/:event_id',
				name: 'OperatorSuiteList',
				component: () => import(/* webpackChunkName: "operator-suite-list" */ '../views/operator/OperatorSuiteList.vue')
			},
			{
				path: 'events',
				name: 'OperatorEventList',
				component: () => import(/* webpackChunkName: "operator-event-list" */ '../views/operator/OperatorEventList.vue')
			},
			{
				path: 'suite-settings',
				name: 'SuiteSettings',
				component: () => import(/* webpackChunkName: "suite-settings" */ '../views/operator/SuiteSettings.vue')
			}
		]
	},

	// {
	// 	path: '/:restaurant_slug/order-notification',
	// 	name: 'OrderNotification',
	// 	component: () => import(/* webpackChunkName: "restaurant-order-notification" */ '../views/restaurant/OrderNotification.vue'),
	// 	props: route => {
	// 		return { restaurantSlug: route.params.restaurant_slug};
	// 	}
	// },
	{
		path: '*',
		name: 'PageNotFound',
		component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
