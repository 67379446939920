
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueMasonryPlugin } from 'vue-masonry';
import FeaturedItemCard from '@/components/menu/cards/FeaturedItemCard.vue';
Vue.use(VueMasonryPlugin);

@Component<FeaturedItemList>({ components: { FeaturedItemCard }})
export default class FeaturedItemList extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private items!: MenuItem[];
	@Prop({ type: Boolean, required: true, default: false }) private hasOrderAgain!: boolean;

	private validImages: boolean[] = [];

	private get oneItemHasAValidImage(): boolean {
		return this.validImages.some(valid => valid);
	}

	private mounted(): void {
		this.items.forEach((item, index) => this.checkIfValidImage(item, index));
	}

	/**
	 * Check if the image of an item is valid
	 *
	 * @param {MenuItem} item
	 * @param {number} index
	 * @return {void}
	 */
	private checkIfValidImage(item: MenuItem, index: number): void {
		if (item.image) {
			let img = new Image();
			img.onload = () => Vue.set(this.validImages, index, true);
			img.onerror = () => Vue.set(this.validImages, index, false);
			img.src = this.getImageURL(item);
		}
		else {
			Vue.set(this.validImages, index, false);
		}
	}

	/**
	 * Get the image URL from the item
	 *
	 * @param {MenuItem} item
	 * @return {string}
	 */
	private getImageURL(item: MenuItem): string {
		return process.env.NODE_ENV === 'development' ? `${item.image}` : `${process.env.VUE_APP_IMAGE_BUCKET}/${item.image}`;
	}

	/**
	 * Open the item viewer
	 *
	 * @param {MenuItem} item
	 * @return {void}
	 */
	private clickHandler(item: MenuItem): void {
		this.$emit('on-click', item);
	}
}
