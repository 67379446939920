
import { Component, Vue } from 'vue-property-decorator';
import VueMeta from 'vue-meta';
import PageNotFoundImageEn from '../assets/images/page-not-found-en.svg?inline';
import PageNotFoundImageFr from '../assets/images/page-not-found-fr.svg?inline';

Vue.use(VueMeta);

@Component<PageNotFound>({
	components: {
		PageNotFoundImageEn,
		PageNotFoundImageFr
	},
	metaInfo() {
		return {
			title: ('Page not found') as string,
			meta: [
				{ charset: 'utf-8' },
				{ name: 'keyword', content: 'Not found, Page not found' },
				{ name: 'description', vmid: 'description', content: 'Page not found' },
				{ name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
				{ property: 'og:title', content: 'Page not found' },
				{ property: 'og:description', content: 'Page not found' },
				{ property: 'og:site_name', content: 'Page not found' },
				{ property: 'og:type', content: 'website' },
				{ property: 'twitter:title', content: 'Page not found' },
				{ property: 'twitter:description', content: 'Page not found' }
			]
		};
	}
})
export default class PageNotFound extends Vue {
	private backToMenus(): void {
		this.$router.replace('/').catch(() => {});
	}
}
