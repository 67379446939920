
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import { formatPhoneNumber } from '@/utils/format';
import PoweredByApp8 from '@/components/shared/PoweredByApp8.vue';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import '@/validation-rules';

const namespace: string = 'auth';

@Component<ContactInformation>({
	components: {
		ArrowLeftIcon,
		ValidationObserver,
		ValidationProvider,
		PoweredByApp8
	}
})
export default class ContactInformation extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private userInfo!: UserInfo;
	@Prop({ type: String, required: true, default: () => '' }) private isLoading!: string;
	@Prop({ type: String, required: true, default: () => '' }) private email!: string;
	@Getter('showLoginAsBanner', { namespace }) private showLoginAsBanner!: boolean;
	@Watch('contactInformation.phoneNumber')
	onPhoneNumberInput(value: string, prevValue: string): void {
		// If the user typed more than 1 character in 1 input, could be autofill
		// TODO: Need a better way to handle autofill/autocomplete and area codes in the future
		this.formatPhoneNumber((value.length - prevValue.length) > 1);
	}

	$refs!: {observer: Validate}

	private contactInformation: UserContactInfo = {
		firstName: this.userInfo.firstName,
		lastName: this.userInfo.lastName,
		phoneNumber: this.userInfo.phoneNumber || ''
	 };

	private isRegisterFlow: boolean = this.$route.name === 'Login';

	/**
	 * Format the phone number to ###-###-#### format
	 *
	 * @param {boolean} mayBeAutofill - is the input potentially from autofill
	 * @return {void}
	 */
	private formatPhoneNumber(mayBeAutofill: boolean): void {
		this.contactInformation.phoneNumber = formatPhoneNumber(this.contactInformation.phoneNumber, mayBeAutofill);
	}

	private prev() {
		this.$emit('prev');
	}

	private async submit() {
		const isValid = await this.$refs.observer.validate();
		if (isValid) {
			this.$emit('next', this.contactInformation);
		}
	}
}
