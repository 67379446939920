
import { Vue, Component, Prop } from 'vue-property-decorator';
import Timer from '@/utils/timer';

@Component<BannerContent>({})
export default class BannerContent extends Vue {
	@Prop({ type: Object, required: true }) banner!: BannerItem;

	private showBanner: boolean = true;
	private timer: Timer | null = null;
	private touchstartX: number = 0;
	private touchendX: number = 0;
	private touchstartY: number = 0;
	private touchendY: number = 0;

	$refs!: {
		banner: HTMLDivElement
	}

	private mounted(): void {
		if (this.banner.duration) {
			this.timer = new Timer(() => {
				this.showBanner = false;
				this.banner.onComplete && this.banner.onComplete();
				this.$emit('removed', this.banner);
			}, this.banner.duration);
		}

		if (this.banner.pauseOnHover) {
			this.addHoverListeners();
		}

		// Set up touch events for close on swipe
		if (this.banner.closeOnSwipe) {
			this.addTouchListeners();
		}
	}

	// Remove event listeners if they exist
	private beforeDestroy(): void {
		if (this.banner.pauseOnHover) {
			this.$refs.banner.removeEventListener('mouseenter', () => {});
			this.$refs.banner.removeEventListener('mouseleave', () => {});
		}
		if (this.banner.closeOnSwipe) {
			this.$refs.banner.removeEventListener('touchstart', () => {});
			this.$refs.banner.removeEventListener('touchend', () => {});
		}
	}

	private destroyed(): void {
		this.$emit('removed', this.banner);
	}

	/**
	 * Adds mouseenter and mouseleave event listeners to banner for pauseOnHover functionality
	 *
	 * @return {void}
	 */
	private addHoverListeners(): void {
		this.$refs.banner.addEventListener('mouseenter', () => {
			this.timer?.pause();
		});

		this.$refs.banner.addEventListener('mouseleave', () => {
			this.timer?.resume();
		});
	}
	/**
	 * Adds touchstart and touchend event listeners to banner for closeOnSwipe functionality
	 *
	 * @return {void}
	 */
	private addTouchListeners(): void {
		this.$refs.banner.addEventListener('touchstart', e => {
			this.touchstartX = e.changedTouches[0].clientX;
			this.touchstartY = e.changedTouches[0].clientY;
		});

		this.$refs.banner.addEventListener('touchend', e => {
			this.touchendX = e.changedTouches[0].clientX;
			this.touchendY = e.changedTouches[0].clientY;
			this.handleTouchEvent();
		});
	}

	/**
	 * Checks difference of x and y coordinates between touchstart and touchend events 
	 * Close banner if enough distance is covered (50 for x, 25 for y)
	 *
	 * @return {void}
	 */
	private handleTouchEvent(): void {
		if (Math.abs(this.touchendX - this.touchstartX) > 50 || Math.abs(this.touchendY - this.touchstartY) > 25) {
			this.showBanner = false;
		}
	}
}
