import 'normalize.css';
import Vue from 'vue';
import App from './App.vue';
import Component from 'vue-class-component';
import store from './store/index';
import router from './router';
import Toasted from 'vue-toasted';
import Loading from 'vue-loading-overlay';
import vSelect from 'vue-select';
import i18n from './i18n'
import { state as restaurantState } from './store/restaurant/restaurant';
import { state as authState } from './store/auth/auth';
import { VIEWS } from './utils/constants';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'v-tooltip/dist/v-tooltip.css';
import './filters';

Vue.use(Loading);
Vue.use(Toasted);

Vue.component('loading', Loading);
Vue.component('v-select', vSelect);
Vue.config.productionTip = false;

/**
 * Make sure the user is logged in before entering routes
 *
 * @param {Route} to - route to go to
 * @param {Route} from - route user is from
 * @param {Route} next - allow to proceed to the route
 * @return {void}
 */
router.beforeResolve(async (to, from, next) => {
	if(!from.name) {
		// Determine what type of authentication to do
		// 1. If we have a suite operator and event suite query param, authenticate both suite operator and user (login as)
		// 2. If we have a suite operator query param, authenticate suite operator
		// 3. Otherwise, authenticate user
		if (to.query && !!to.query.suiteOperator && to.query.eventSuite) {
			store.commit('auth/SET_LOGIN_AS', true);
			await store.dispatch('auth/authenticateOperator', to.fullPath);
			if (store.getters['auth/isAuthenticated']) {
				await store.dispatch('auth/authenticate', to.fullPath);
			}
		}
		else if (to.query && !!to.query.suiteOperator && !to.query.eventSuite) {
			store.commit('auth/SET_SUITE_OPERATOR_LOGIN', true);
			await store.dispatch('auth/authenticateOperator');
		}
		else {
			await store.dispatch('auth/authenticate');
		}
	}

	if (!store.getters['auth/isAuthenticated'] && VIEWS.webUserOnly.includes(to.name as string)) {
		next({
			path: '/login',
			replace: true,
			query: to.query
		});
	}

	if ((!store.getters['auth/isSuiteOperator']) && VIEWS.suiteOperatorOnly.includes(to.name as string)) {
		next({
			path: '/login',
			replace: true,
			query: to.query
		});
	}

	// If user token expires when logged in as during event day ordering set login as to false and redirect to login page
	if (!store.getters['auth/isAuthenticated'] && !!to.query.eventDayOrdering) {
		store.commit('auth/SET_LOGIN_AS', false);
		store.commit('auth/SET_SUITE_OPERATOR_LOGIN', true);
		next({
			path: '/login',
			replace: true,
			query: {
				suiteOperator: to.query.suiteOperator,
			}
		});
	}

	if (store.getters['auth/isAuthenticated']) {

		// User already logged in, skip login page
		if (to.name === 'Login') {
			// Preordering with missing profile info
			if (to.query?.eventSuite && (!authState.user.firstName || !authState.user.lastName || !authState.user.phoneNumber)) {
				next({
					path: '/profile/user-information',
					replace: true,
					query: to.query
				})
			}
			// Preordering with missing payment method
			else if (to.query?.eventSuite && !authState.user.paymentMethods.length) {
				next({
					path: '/profile/user-payment-methods',
					replace: true,
					query: to.query
				})
			}
			// Regular user or preordering with all the needed information
			else {
				next({
					path: `/${restaurantState.restaurant.slug ? restaurantState.restaurant.slug : 'profile'}`,
					replace: true,
					query: to.query
				});
			}
		}

		// If we hit landing page, redirect to restaurant page if they are not pre-ordering to avoid
		// user friction. If they are pre-ordering we want them to stay on the landing page.
		if (to.name === 'Landing') {
			if(!to.query || to.query && !to.query.eventSuite) {
				next({
					path: `/${to.params.restaurant_slug}`,
					replace: true,
					query: to.query
				});
			}
		}
	}
	next();
}),

// Register hooks for route navigation guards
Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteLeave',
	'beforeRouteUpdate'
]);

new Vue({
	store,
	render: h => h(App),
	i18n,
	router
}).$mount('#app');

