
// TODO: Add location bias for places predictions
// TODO: Use types from @types/google.maps, uses global type declarations that conflicts with global google definition we use (global.d.ts:1366)
import { Component, Prop, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash';
import MapPinIcon from 'vue-feather-icons/icons/MapPinIcon';

const defaultFields: string[] = ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'permanently_closed', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity'];

@Component<GoogleAutocomplete>({
	components: {
		MapPinIcon
	}
})
export default class GoogleAutocomplete extends Vue {
	@Prop({ type: [String, Array], required: false, default: '' }) private country!: string;
	@Prop({ type: String, required: false, default: 'Start typing' }) private placeholder!: string;
	@Prop({ type: String, required: false, default: 'address' }) private types!: string;
	@Prop({ type: String, required: false, default: '' }) private classname!: string;
	@Prop({ type: String, required: false, default: '' }) private id!: string;
	@Prop({ type: Array, required: false, default: () => defaultFields }) private fields!: string[];
	@Prop({ type: Boolean, required: false, default: false }) private returnPlaceDetails!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private disabled!: boolean;
	private sessionToken: {} | null = null;
	private autocompleteService: any = new google.maps.places.AutocompleteService();
	private placesService: any | null = null;
	private placePredictions: AutocompletePrediction[] = [];
	private inputValue: string = '';
	private prevInputValue: string = '';
	private focusedPredictionIndex: number = -1;
	private showPredictions: boolean = false;
	private dropdownClicked: boolean = false;
	private debounceGetPlacePredictions = debounce(this.getPlacePredictions, 300);

	$refs!: {
		autocomplete: HTMLInputElement;
	};

	/**
	 * Initialize the Google Maps Places service if component should make a getPlaceDetails request when a place is selected
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if (this.returnPlaceDetails && this.$refs.autocomplete) {
			this.placesService = new google.maps.places.PlacesService(this.$refs.autocomplete);
		}
	}

	/**
	 * Format the place prediction to display in the dropdown
	 *
	 * @param {AutocompletePrediction} prediction
	 * @return {string}
	 */
	private formatPrediction(prediction: AutocompletePrediction): string {
		const { main_text, main_text_matched_substrings, secondary_text, secondary_text_matched_substrings } = prediction.structured_formatting;
		const mainTextHtml = this.boldMatchedSubstrings(main_text, main_text_matched_substrings);
		const secondaryTextHtml = secondary_text_matched_substrings?.length > 0 ? this.boldMatchedSubstrings(secondary_text, secondary_text_matched_substrings) : secondary_text;

  		return `<span class="main-text">${mainTextHtml}</span> <span class="secondary-text">${secondaryTextHtml}</span>`;
	}

	/**
	 * Bold the matched substrings in given string
	 *
	 * @param {string} text
	 * @param {MatchedSubstring[]} matchedSubstrings
	 * @return {string}
	 */
	private boldMatchedSubstrings(text: string, matchedSubstrings: MatchedSubstring[]): string {
		let result = '';
		let lastIndex = 0;

		matchedSubstrings.forEach(({ length, offset }) => {
			result += text.slice(lastIndex, offset);
			result += `<b>${text.slice(offset, offset + length)}</b>`;
			lastIndex = offset + length;
		});

		result += text.slice(lastIndex);
		return result;
	}

	/**
	 * Select the focused prediction or the first prediction if none are focused when the enter key is pressed
	 *
	 * @return {void}
	 */
	private selectPrediction(): void {
		this.prevInputValue = '';
		if (this.focusedPredictionIndex > -1) {
			this.placeSelected(this.placePredictions[this.focusedPredictionIndex]);
		}
		else if (this.placePredictions.length) {
			this.placeSelected(this.placePredictions[0]);
		}
	}

	/**
	 * Focus the previous prediction in the list if the down arrow key is pressed
	 *
	 * @return {void}
	 */
	private focusNextPrediction(): void {
		if (!this.prevInputValue) {
			this.prevInputValue = this.inputValue;
		}
		if (this.focusedPredictionIndex < this.placePredictions.length - 1) {
			this.focusedPredictionIndex++;
			this.inputValue = this.placePredictions[this.focusedPredictionIndex].description;
		}
		// If the last prediction is focused remove the focus and set the input value back to the user input
		else if (this.focusedPredictionIndex === this.placePredictions.length - 1) {
			this.focusedPredictionIndex = -1;
			this.inputValue = this.prevInputValue;
		}
	}

	/**
	 * Focus the next prediction in the list if the up arrow key is pressed
	 *
	 * @return {void}
	 */
	private focusPrevPrediction(): void {
		if (!this.prevInputValue) {
			this.prevInputValue = this.inputValue;
		}
		if (this.focusedPredictionIndex > 0) {
			this.focusedPredictionIndex--;
			this.inputValue = this.placePredictions[this.focusedPredictionIndex].description;
		}
		// If the first prediction is focused remove the focus and set the input value back to the user input
		else if (this.focusedPredictionIndex === 0) {
			this.focusedPredictionIndex = -1;
			this.inputValue = this.prevInputValue;
		}
		// If no prediction is focused, focus the last prediction
		else {
			this.focusedPredictionIndex = this.placePredictions.length - 1;
			this.inputValue = this.placePredictions[this.focusedPredictionIndex].description;
		}
	}

	/**
	 * When the input value changes, get the place predictions
	 *
	 * @param {InputEvent} event
	 * @return {void}
	 */
	private onInput(event: InputEvent): void {
		// Create a new session token if one doesn't exist
		// This token should be sent to Remy if the getPlaceDetails if the request is being made there (returnPlaceDetails === false)
		if (!this.sessionToken) {
			this.sessionToken = new google.maps.places.AutocompleteSessionToken();
		}
		this.prevInputValue = '';
		this.focusedPredictionIndex = -1;
		if (this.inputValue) {
			this.debounceGetPlacePredictions();
		}
		else {
			this.showPredictions = false;
			this.placePredictions = [];
		}
		this.$emit('input', event);
	}

	/**
	 * Get and set place predictions from the Google Maps Places API
	 * This is called when the input value changes
	 * If there are no predictions, emit a no-results-found event
	 *
	 * @return {void}
	 */
	private getPlacePredictions(): void {
		this.autocompleteService.getPlacePredictions({
			input: this.inputValue,
			sessionToken: this.sessionToken,
			componentRestrictions: {
				country: this.country
			}
		}, (predictions: AutocompletePrediction[], status: string) => {
			if (status === google.maps.places.PlacesServiceStatus.OK) {
				this.placePredictions = predictions;
				if (predictions.length) {
					this.showPredictions = true;
				}
				else {
					this.showPredictions = false;
					this.$emit('no-results-found', this.inputValue);
				}
			}
		});
	}

	/**
	 * When the input loses focus, hide the predictions
	 * Unless the dropdown was clicked, then keep the predictions open so the click event can be triggered
	 *
	 * @param {FocusEvent} event
	 * @return {void}
	 */
	private onBlur(event: FocusEvent): void {
		setTimeout(() => {
			if (!this.dropdownClicked) {
				this.showPredictions = false;
			}
			this.dropdownClicked = false;
		}, 100);
		this.$emit('blur', event);
	}

	/**
	 * When the input is focused, show the predictions if there are any
	 *
	 * @param {FocusEvent} event
	 * @return {void}
	 */
	private onFocus(event: FocusEvent): void {
		if (this.placePredictions.length) {
			this.showPredictions = true;
		}
		this.$emit('focus', event);
	}

	/**
	 * When a prediction is clicked, emit the placeChanged event with the selected place prediction
	 * If returnPlaceDetails is true, get the place details and emit those instead
	 *
	 * @param {AutocompletePrediction} place
	 * @return {void}
	 */
	private placeSelected(place: AutocompletePrediction): void {
		this.inputValue = place.description;
		this.showPredictions = false;
		this.prevInputValue = '';
		this.focusedPredictionIndex = -1;

		if (this.returnPlaceDetails) {
			this.placesService.getDetails({
				placeId: place.place_id,
				sessionToken: this.sessionToken,
				fields: this.fields
			}, (placeDetails: any, status: string) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					this.$emit('place-changed', placeDetails);
					this.sessionToken = null;
				}
				else {
					this.$emit('error-getting-place-details', status);
				}
			});
		}
		else {
			this.$emit('place-changed', { place, sessionToken: this.sessionToken });
		}
	}

	/******************************************************
	 * Public component functions
	*******************************************************/
	public update(inputValue: string): void {
		this.inputValue = inputValue;
	}

	public focus(): void {
		this.$refs.autocomplete.focus();
	}

	public blur(): void {
		this.$refs.autocomplete.blur();
	}

	public clear(): void {
		this.inputValue = '';
		this.showPredictions = false;
		this.placePredictions = [];
	}

	/**
	 * Reset the session token
	 * When a getPlaceDetails request is made the token is invalidated, and a new session token is required
	 * If the getPlaceDetails is called from Remy, this function should be called after the request is complete
	 *
	 * @return {void}
	 */
	public resetSessionToken(): void {
		this.sessionToken = null;
	}
}
