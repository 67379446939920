
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';

const namespace: string = 'suites';

@Component({
	components: {
		ArrowLeftIcon,
		LoadingSpinner
	}
})
export default class OperatorSuiteList extends Vue {
	@Action('suiteOpGetEventInfo', { namespace }) private suiteOpGetEventInfo!: (payload: { eventId: number, userId: string, token: string }) => void;
	@Action('setEventDayOrderingFlag', { namespace }) private setEventDayOrdering!: (eventDayOrdering: boolean) => void;
	@Action('loginAs', { namespace: 'auth' }) private loginAs!: (credentials?: LoginAsCredentials) => void;
	@Getter('getSelectedEvent', { namespace }) private event!: EventInfo;
	@Getter('getSuiteOperator', { namespace: 'auth' }) private suiteOperator!: UserInfo;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;

	private isLoading: boolean = false;
	private loadingMessage: string | null = null;

	private get eventSuites(): EventSuite[] {
		return this.event.event_suites;
	}

	private async created(): Promise<void> {
		// Fetch event suite if it doesn't exist or if the id is different
		if (!this.event || this.event.id !== +this.$route.params.event_id) {
			try {
				this.isLoading = true;
				this.loadingMessage = this.$t('operator.suite_list.fetching_suites');
				await this.suiteOpGetEventInfo({
					eventId: +this.$route.params.event_id,
					userId: this.suiteOperator.id,
					token: this.suiteOperator.token
				});
			} catch (error) {
				this.$toasted.show(error.response?.data?.message, { type: 'error', position: 'top-center' }).goAway(5000);
			} finally {
				setTimeout(() => {
					this.isLoading = false;
					this.loadingMessage = null;
				}, 500);
			}
		}
	}

	/**
	 * Login as suite owner on event suite selection then navigate to suite settings
	 *
	 * @param {EventSuite} eventSuite
	 * @return {Promise<void>}
	 */
	private async handleLoginAs(eventSuite: EventSuite): Promise<void> {
		try {
			this.isLoading = true;
			this.loadingMessage = this.$t('operator.suite_list.logging_in', { name: eventSuite.suite_owner.name });
			if (eventSuite.suite_owner.email) {
				await this.setEventDayOrdering(true);
				await this.loginAs({ eventSuiteId: eventSuite.id });

				// Navigate to menu if there is a balance on the tab otherwise navigate to suite settings with necessary query params
				this.$router.push({
					path: eventSuite.tab_balance ? `/${this.restaurant.slug}` : `/${this.restaurant.slug}/suite-settings`,
					query: {
						...this.$route.query,
						suiteOperator: 'true',
						eventDayOrdering: 'true',
						eventSuite: `${eventSuite.id}`
					}
				}).catch(() => {})
			}
		} catch (error) {
			this.$toasted.show(error.response?.data?.message, { type: 'error', position: 'top-center' }).goAway(5000);
			this.setEventDayOrdering(false);
		} finally {
			setTimeout(() => {
				this.isLoading = false;
				this.loadingMessage = null;
			}, 500);
		}
	}
}
