import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { CartState, RootState } from '../types';
const namespaced: boolean = true;

// TODO: Refactor this store into sub stores (esp for configs)
export const state: CartState = {

	// Ordering config
	config: {
		tableNum: '',
		tableInfo: {
			tableNum: '',
			tableArea: '',
			tableLocation: ''
		},
		takeout: '',
		takeoutType: '',
		genericCatering: '',
		viewOnly: '',
		takeoutDateRange: '',
		takeoutSpecificDates: '',
		menuList: '',
		genericTableLocation: ''
	},

	// Order items
	items: [],
	numberOfItems: 0,
	temporarySelectedItem: {} as OrderItem,
	orderId: 1,

	// Ordering costs
	costs: {
		subtotal: 0,
		subtotalDiscounted: 0,
		taxBrackets: {},
		taxes: 0,
		taxInfo: [],
		tip: {
			percentageValue: 0,
			amount: 0,
			type: 'dollar'
		},
		serviceCharge: {
			percentageValue: 0,
			amount: 0,
			type: 'dollar',
			label: ''
		},
		deliveryCharge: {
			percentageValue: 0,
			amount: 0,
			type: 'dollar'
		},
		voucher: {
			code: '',
			balance: 0,
			amount: 0
		},
		discount: {
			percentageValue: 0,
			amount: 0,
			type: 'percentage',
			code: ''
		},
		memberDiscount: {
			preTax: false,
			amount: 0
		},
		total: 0,
		totalWithTip: 0
	},

	// Checkout info (payment chosen, order type info)
	checkout: {
		paymentMethod: '',
		pickup: null,
		delivery: {
			type: ''
		},
		contact: {
			full_name: '',
			email: '',
			phone_number: '',
			password: '',
		},
		card: {
			number: '',
			cvd: '',
			expiry_date: '',
			postal_code: '',
			type: ''
		},
		savePaymentMethodOnCheckout: false,
		applePayPaymentToken: null,
		applePayPaymentDetails: null,
		googlePayPaymentPayload: null,
		purchaseOrder: null,
		costCenter: null,
		invoice: false,
		loyaltyProgram: null,
		memberInfo: null,
		suitesInfo: null,
		questions: null
	},

	// User chosen CC
	chosenCreditCard: null,
	errorValidation: {
		message: '',
		invalid_items: []
	},

	// General
	today: '',
	dateSelectedByUser: false,
	tipSelected: false,
	legal: false,
	showMembershipBanner: false
};

export const cart: Module<CartState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default cart;