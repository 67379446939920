
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/validation-rules';
import { Validate } from '@/types';

@Component<CartQuantityAmount>({
	components: {
		ValidationObserver,
		ValidationProvider
	}
})

export default class CartQuantityAmount extends Vue {
	@Prop({ type: Number, required: true, default: 1 }) private quantity!: number;
	@Prop({ type: Number, required: true, default: 0 }) private itemId!: number;

	$refs!: {observer: Validate}

	private tempQuantity: number = 0;

	/**
	 * Set the quantity to whatever the quantity is
	 * from the vuex store value
	 *
	 * @return {void}
	 */
	private mounted(): void {
		this.tempQuantity = this.quantity;
	}

	/**
	 * Validate the input value and then set the new quantity
	 * to the store
	 *
	 * @return {Promise<void>}
	 */
	private async setQuantity(): Promise<void> {
  	const isValid = await this.$refs.observer.validate();
		if (isValid) {
			if(Number.isInteger(+this.tempQuantity)) {
				this.$emit('save', { id: this.itemId, quantity: this.tempQuantity });
			}
			this.$emit('close');
		}
	}

	/**
	 * Send the event to the parent to close the modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
