
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { formatDate, formatTime } from '@/utils/format';
import ConfirmationImage from '../../../assets/images/undraw_order_confirmed_aaw7.svg?inline';

const namespace: string = 'cart';

@Component<ConfirmationOrder>({
	components: {
		ConfirmationImage
	}
})
export default class ConfirmationOrder extends Vue {
	@Getter('isTakeOut', { namespace }) private isTakeOut!: boolean;
	@Getter('isDelivery', { namespace }) private isDelivery!: boolean;
	@Getter('getPickupInformation', { namespace }) private pickupInformation!: CheckoutPickupInfo | null;
	@Getter('getDeliveryInformation', { namespace }) private deliveryInformation!: CheckoutDeliveryInfo;
	@Getter('isAnonymousUser', { namespace: 'auth' }) private isAnonymousUser!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('noTakeoutTimeslot', { namespace: 'restaurant' }) private noTakeoutTimeslot!: boolean;
	@Getter('getConcourseUrl', { namespace: 'restaurant'}) private concourse!: string;
	@Getter('getHideGuestInfo', { namespace: 'restaurant' }) private hideGuestInfo!: HideGuestInfo | null;

	private windowWebkit: WindowWebkit = window;

	/**
	 * Add the event listener to send an event to app8
	 * to close the webview and redirect the app to the
	 * respective screen.
	 *
	 * @return {void}
	 */
	private mounted(): void {
		this.sendApp8Event();
	}

	/**
	 * Add the event listener to send an event to app8
	 * to close the webview and redirect the app to the
	 * respective screen.
	 *
	 * @return {void}
	 */
	private sendApp8Event(): void {
		if(this.windowWebkit.webkit) {
			this.windowWebkit.webkit.messageHandlers.iOS.postMessage({ 'event': 'paymentConfirmed' });
		}
		if(Android && Android.paymentComplete) {
			Android.paymentComplete('');
		}
	}

	/**
	 * Format the pick up date to human format
	 *
	 * @return {string}
	 */
	private formatLocalDate(): string {
		return formatDate(this.pickupInformation!);
	}

	/**
	 * Format the pick up time to human format
	 *
	 * @return {string}
	 */
	private formatLocalTime(): string {
		return formatTime(this.pickupInformation!);
	}

	/**
	 * Close confirmation modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		if(this.concourse) {
			window.location.href = this.concourse;
		}
		this.$emit('close');
	}
}
