
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import BannerContainer from '@/components/shared/BannerContainer.vue';

const namespace: string = 'auth';

@Component<ProfileIndex>({
	components: {
		BannerContainer
	}
})
export default class ProfileIndex extends Vue {
	@Getter('getUser', { namespace }) private user!: UserInfo;
	@Getter('showLoginAsBanner', { namespace }) private showLoginAsBanner!: boolean;

	private get fullName(): string {
		return (this.user.firstName || this.user.lastName) ? `${this.user.firstName} ${this.user.lastName}` : '';
	}

	private get profileBanners(): BannerItem[] {
		return [
			{
				name: 'login-as',
				type: 'info',
				show: !!(this.showLoginAsBanner && this.fullName),
				message: this.showLoginAsBanner ? this.$t('menu.logged_in_as_banner') : this.$t('menu.welcome_back_banner'),
				action: {
					text : this.fullName,
					onClick : () => this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {})
				}
			}
		]
	}
}
