
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { setTemporaryOption, formatTemporarySelectedOrderOption } from '@/utils/format';
import { getElementBySkuOrId } from '@/utils/helpers';

@Component<SubAddOns>({})
export default class SubAddOns extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private options!: any[];
	@Prop({ type: Array, required: true, default: () => [] }) private lastOrderedSubOptions!: LastOrderedItem[];
	@Prop({ type: Object, required: true, default: () => {} }) private option!: OptionGroup;
	@Prop({ type: Object, required: true, default: () => {} }) private value!: Option;
	@Prop({ type: Object, required: true, default: () => {}  }) private menuItem!: MenuItem;
	@Prop({ type: Boolean, required: true, default: false }) private isViewOnly!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private showErrorMessages!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private areSubOptionsPreselected!: boolean;
	@Prop({ type: Set, required: false, default: () => {} }) private requiredOptions!: any;
	@Watch('options')
	onOptionsChanged() {
		this.mutableOptions = this.options;
	}

	private mutableOptions: any[] = this.options;
	private subOptions: any[] = [];

	private mounted(): void {
		if (this.lastOrderedSubOptions?.length && !this.areSubOptionsPreselected) {
			this.selectLastOrderedSubOptions();
		}
	}

	/**
	 * Change selected state of the options and add to the vuex options selected.
	 * First check the option type if it's pricing/addon. If pricing replace
	 * the price with the value. If addon add to the price and checks if multiple
	 * selections are allowed.
	 *
	 * @param {OptionGroup} optionGroup - the group option
	 * @param {Option} option - the value of chosen of the group option
	 * @param {boolean} checked
	 * @return {void}
	 */
	private selectSubOption(optionGroup: OptionGroup, option: Option, checked: boolean): void {
		let tempOptionGroup: OrderOptionGroup = setTemporaryOption(optionGroup, option);

		// Add-ons
		if(optionGroup.allow_multiple_selection) {
			this.multipleOptionGroupValueSelected(tempOptionGroup, optionGroup, option);
		}
		else {
			this.singleOptionGroupValueSelected(tempOptionGroup, optionGroup, option, checked);
		}
		this.$emit('update-sub-options', {
			optionGroup: this.option,
			option: this.value,
			subOptions: this.subOptions
		});
	}

	/**
	 * If sub-options were selected in a previous order, pre-select them.
	 * Get the data needed to trigger the selectSubOption.
	 * And manually check the checkboxes.
	 *
	 * @return {void}
	 */
	private selectLastOrderedSubOptions(): void {
		if (this.lastOrderedSubOptions?.length) {
			for (const lastSubOption of this.lastOrderedSubOptions) {
				const optionGroup: OptionGroup|undefined = this.value.options?.find((option: OptionGroup) => option.values?.some((value: Option) => getElementBySkuOrId(value, lastSubOption.id, lastSubOption.sku)));
				if (optionGroup?.values?.length) {
					const option: Option|undefined = optionGroup.values?.find((value: Option) => getElementBySkuOrId(value, lastSubOption.id, lastSubOption.sku));
					if (option) {
						Vue.nextTick(() => {
							// Make sure we don't select more sub-options than the limit
							if (optionGroup && typeof optionGroup.limit === 'number' && optionGroup.limit > 0 && this.subOptions.length > optionGroup.limit) {
								return;
							}
							this.selectSubOption(optionGroup, option, true);
							const checkbox = optionGroup.allow_multiple_selection
								? document.getElementById(`${optionGroup.name}-checkbox-${option.name}-${option.id}`) as HTMLInputElement
								: document.getElementById(`${optionGroup.name}-${optionGroup.id}-checkbox-single-${option.id}`) as HTMLInputElement
							if (checkbox) {
								checkbox.checked = true;
							}
						});
					}
				}

			};
		}
		this.$emit('sub-options-pre-selected');
	}

	/**
	 * Multiple option group value selected
	 *
	 * @param {any} tempOptionGroup
	 * @param {any} optionGroup - the group option
	 * @param {any} option - the value of chosen of the group option
	 * @return {void}
	 */
	private multipleOptionGroupValueSelected(tempOptionGroup: OrderOptionGroup, optionGroup: OptionGroup, option: Option): void {
		const index = this.subOptions.findIndex(item => item.name === optionGroup.name);

		/**
		 * If there are already values for that group, we have to check
		 * if the value was also added, if it was we change its boolean,
		 * if it wasn't we add it to the group array.
		 */
		if(index > -1) {
			let valueExist: boolean = false;
			for (let i = 0; i < this.subOptions[index].values.length; i++) {
				if(this.subOptions[index].values[i].name === option.name) {
					this.subOptions[index].values.splice(i, 1);
					valueExist = true;
				}
			}

			if (!valueExist) {
				if(optionGroup.limit && (this.subOptions[index].values.length+1) > optionGroup.limit) {
					(document.getElementById(`${optionGroup.name}-checkbox-${option.name}-${option.id}`) as HTMLInputElement).checked = false;
					alert(this.$t('menu.item_viewer.addons.error_max_exceeded', { optionGroupLimit: optionGroup.limit }));
				}
				else {
					this.subOptions[index].values.push(formatTemporarySelectedOrderOption(option));
				}
			}

			// Remove the option from this.options if there are no remaining selected values
			if (!this.subOptions[index].values.length) {
				this.subOptions.splice(index, 1);
			}
		}

		// If there are no values for that group we simply add it to the options array
		else {
			this.subOptions.push(tempOptionGroup);
		}
	}

	/**
	 * Single selection, just have to filter out the past values if there
	 * was any and then we add the option to the options array.
	 *
	 * @param {any} tempOptionGroup
	 * @param {any} optionGroup - the group option
	 * @param {any} option - the value of chosen of the group option
	 * @return {void}
	 */
	private singleOptionGroupValueSelected(tempOptionGroup: OrderOptionGroup, optionGroup: OptionGroup, option: Option, checked: boolean): void {
		if(checked && tempOptionGroup.values) {
			this.unselectRadioButton(optionGroup);
			this.subOptions = this.subOptions.filter((opt: OptionGroup) => {
				return opt.name !== optionGroup.name;
			});
			this.subOptions.push(tempOptionGroup);
		}
		else {
			this.subOptions = this.subOptions.filter((opt: OptionGroup) => {
				return opt.name !== optionGroup.name;
			});
		}
	}

	/**
	 * Unselect radio buttons from the same type if there is already one selected
	 *
	 * @param {OptionGroup} optionGroup - the group option
	 * @return {void}
	 */
	private unselectRadioButton(optionGroup: OptionGroup): void {
		let prevSelectedOptions = this.subOptions.filter((opt: OptionGroup) => {
			return opt.id === optionGroup.id;
		});
		if(prevSelectedOptions.length) {
			// Value
			let prevElement = prevSelectedOptions[0];
			let prevElementInput: HTMLInputElement = (document.getElementById(`${prevElement.name}-${prevElement.id}-checkbox-single-${prevElement.values[0].id}`) as HTMLInputElement);
			prevElementInput.checked = false;
		}
	}
}
