
import { Vue, Prop, Component } from 'vue-property-decorator';
import InfoIcon from 'vue-feather-icons/icons/InfoIcon';
import VTooltip from 'v-tooltip';
Vue.use(VTooltip);
VTooltip.options.themes.tooltip.triggers = ['hover', 'click'];

@Component<Tooltip>({
	components: {
		InfoIcon
	}
})

export default class Tooltip extends Vue {
	@Prop({ type: String, required: true, default: '' }) private text!: string;
	@Prop({ type: String, required: true, default: '' }) private name!: string;

	private tooltipOpened: boolean = false;

	/**
	 * Open tooltip event
	 *
	 * @return {void}
	 */
	private openTooltip(): void {
		this.tooltipOpened = true;
	}

	/**
	 * Close tooltip event
	 *
	 * @return {void}
	 */
	private closeToolTip(): void {
		this.tooltipOpened = false;
	}
}
