
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
import { DateTime } from 'luxon';
import { changeFavicon } from '@/utils/styling';
import VueMeta from 'vue-meta';
import ErrorValidationInformation from '@/components/shared/ErrorValidationInformation.vue';
import GenericTableLocationsModal from '@/components/shared/GenericTableLocationsModal.vue';
import StarRating from 'vue-star-rating';
const namespace: string = 'suites';

Vue.use(VueMeta);

@Component<MenuLanding>({
	components: {
		ErrorValidationInformation,
		StarRating,
		GenericTableLocationsModal
	},
	metaInfo() {
		changeFavicon(this.restaurant.favicon);

		return {
			title: (this.$t('menu.meta.page_title', {restaurantName: this.restaurant.name})) as string,
			meta: [
				{ charset: 'utf-8' },
				{ name: 'keyword', content: `${this.restaurant.name}, restaurants near me, restaurants nearby, food near me, places to eat, diner, menu` },
				{ name: 'description', vmid: 'description', content: `${this.restaurant.name}\'s landing page` },
				{ name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
				{ property: 'og:title', content: `${this.restaurant.name}` },
				{ property: 'og:description', content: `${this.restaurant.name}` },
				{ property: 'og:site_name', content: `${this.restaurant.name}` },
				{ property: 'og:type', content: 'website' },
				{ property: 'twitter:title', content: `${this.restaurant.name}` },
				{ property: 'twitter:description', content: `${this.restaurant.name}` }
			]
		};
	},
	beforeRouteEnter(to, from, next) {
		fireGoogleTag({ name: 'landingPage', specifier: to.query && to.query.eventSuite ? 'preOrdering' : 'default' });
		next();
	},
	beforeRouteLeave(to, from, next) {
		if (to.name === 'Login') {
			if(to.query && to.query.eventSuite) {
				fireGoogleTag({ name: 'preOrderStart' });
			}
			else {
				fireGoogleTag({ name: 'createAccount' });
			}
		} else if (to.name === 'Menu'){
			fireGoogleTag({ name: 'viewGuest' });
		}
		next();
	}
})
export default class MenuLanding extends Vue {
	@Prop({ type: Boolean, required: true, default: '' }) private dataFetched!: boolean;
	@Prop({ type: Boolean, required: true, default: '' }) private cssLoaded!: boolean;
	@Getter('isPreOrdering', { namespace }) private isPreOrdering!: boolean;
	@Getter('getMinimalEventInfo', { namespace }) private minimalEventInfo!: MinimalEventInfo | null;
	@Getter('getEventSuiteId', { namespace }) private eventSuiteId!: number | null;
	@Getter('getTableNum', { namespace: 'cart' }) private tableNum!: string | null;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('isAuthenticated', { namespace: 'auth' }) private isAuthenticated!: boolean;

	private rating: number | null = 0; // TODO UPDATE THIS TO BE THE RESTAURANT RATING
	private startTime: string = '';
	private startDate: string = '';
	private preOrderLockDateISO: string = '';
	private preOrderLockDate: string = '';
	private bannerStatusColor: string = '';
	private bannerStatusText: string = '';

	private get imageURL(): string | null {
		if(this.restaurant.image) {
			return `${process.env.VUE_APP_IMAGE_BUCKET}/${this.restaurant.image}`;
		}
		else {
			return null;
		}
	}

	private async created(): Promise<void> {
		if(this.eventSuiteId) {
			this.setMinimalEventInfoToDisplay();
		}
	}

	/**
	 * Set the minimal event info to display. We set all the dates needed to show here
	 * and the banner status color and text.
	 *
	 * @return {void}
	 */
	private setMinimalEventInfoToDisplay(): void {
		if(this.minimalEventInfo && this.minimalEventInfo.event && this.minimalEventInfo.event.start_date) {
			// Dates
			const tempStartDate: string = (this.minimalEventInfo && this.minimalEventInfo.event && this.minimalEventInfo.event.start_date) as string;
			const localeDate = DateTime.fromISO(tempStartDate).setLocale(this.$i18n.locale);
			this.startTime = localeDate.toFormat(this.$t('landing.time_format'));
			this.startDate = localeDate.toFormat(this.$t('landing.date_format'));
			this.preOrderLockDateISO = localeDate.minus({ days: this.minimalEventInfo.event.order_lock_period }).toISO();
			this.preOrderLockDate = localeDate.minus({ days: this.minimalEventInfo.event.order_lock_period }).toFormat(this.$t('landing.pre_order_lock_full_date_format'));
			const diffInDays = DateTime.fromISO(this.preOrderLockDateISO).diff(DateTime.local(), 'days').toObject().days;

			// Banner status colors
			if(diffInDays && diffInDays > 1) {
				this.bannerStatusColor = 'status-green';
				this.bannerStatusText = this.$t('landing.pre_ordering_available_until');
			}
			else if(diffInDays && diffInDays < 1 && diffInDays > 0) {
				this.bannerStatusColor = 'status-yellow';
				this.bannerStatusText = this.$t('landing.pre_ordering_available_until');
			}
			else {
				this.bannerStatusColor = 'status-blue';
				this.bannerStatusText = this.$t('landing.pre_ordering_unavailable_since');
			}
		}
	}


	/**
	 * Redirect to another page
	 *
	 * @param {string} path
	 * @return {void}
	 */
	private redirectTo(path: string): void {
		this.$router.push({ path: path, query: this.$route.query }).catch(() => {});
	}
}
