
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { getYPositionsOfElements, getSelectionFromVisibleSections, scrollToElement } from '@/utils/helpers';

const namespace = 'layout';

@Component<TabBar>({})
export default class TabBar extends Vue {
	@Action('setTabBar', { namespace }) private setTabBar!: (isDisplayed: boolean) => void;
	@Getter('isTabBarDisplayed', { namespace }) private show!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Prop({ type: Array, required: false, default: () => {} }) private elements!: Menu[] | MenuSection[];

	private currentElementIdx: number = 0;
	private windowHeight: number = 0;

	/**
	 * Add the scroll event and set the window height
	 *
	 * @return {void}
	 */
	private created(): void {
		window.addEventListener('scroll', this.handleScroll);
		this.windowHeight = window.innerHeight;
	}

	/**
	 * Add the resize event
	 *
	 * @return {void}
	 */
	private mounted(): void {
		window.addEventListener('resize', this.handleResize);
	}

	/**
	 * Remove resize listener
	 *
	 * @return {void}
	 */
	private beforeDestroy(): void {
		window.removeEventListener('resize', this.handleResize);
	}

	/**
	 * Remove scroll listener
	 *
	 * @return {void}
	 */
	private destroyed(): void {
		window.removeEventListener('scroll', this.handleScroll);
	}

	/**
	 * Scroll to the respective menu or section selected
	 *
	 * @param {MenuSection} section
	 * @param {number} sIdx
	 * @return {void}
	 */
	private scrollToSelectedElement(section: MenuSection, sIdx: number): void {
		scrollToElement(section, sIdx, 50);
	}

	/**
	 * Listen to the scroll event and set the selected menu or section based on Y axis
	 *
	 * @return {void}
	 */
	private handleScroll(): void {
		const yPositions: number[] = getYPositionsOfElements(this.elements);
		this.currentElementIdx = getSelectionFromVisibleSections(yPositions, this.windowHeight);

		const sidebar = document.getElementsByClassName('sidebar')[0];
		if (sidebar && window.innerWidth < 1024) {
			if (!this.show && sidebar.getBoundingClientRect().bottom < 100) {
				this.setTabBar(true)
			}
			else if (this.show && sidebar.getBoundingClientRect().bottom >= 100) {
				this.setTabBar(false);
			}
		}
	}

	/**
	 * Update window height on viewport resize
	 *
	 * @return {void}
	 */
	private handleResize(): void {
		this.windowHeight = window.innerHeight;
	}
}

