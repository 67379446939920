
import VueMeta from 'vue-meta';
import Tooltip from '@/components/shared/Tooltip.vue';
import SimpleKeyboard from '@/components/shared/SimpleKeyboard.vue';
import http from '@/utils/http';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import '@/validation-rules';

const namespace: string = 'cart';

Vue.use(VueMeta);

@Component<OrderNotification>({
	components: {
		SimpleKeyboard,
		Tooltip,
		ValidationObserver,
		ValidationProvider
	},
	metaInfo() {
		return {
			title: (`${this.restaurant.name}\'s Order Notification`) as string,
			meta: [
				{ charset: 'utf-8' },
				{ name: 'keyword', content: this.$t('menu.meta.keyword', {restaurantName: this.restaurant.name}) },
				{ name: 'description', vmid: 'description', content: this.$t('menu.meta.description') },
				{ name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
				{ property: 'og:title', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) },
				{ property: 'og:description', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) },
				{ property: 'og:site_name', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) },
				{ property: 'og:type', content: 'Website' },
				{ property: 'twitter:title', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) },
				{ property: 'twitter:description', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) }
			]
		};
	}
})
export default class OrderNotification extends Vue {
	@Action('submitOrderNotification', { namespace }) private submitOrderNotification!: (payload: OrderNotificationPayload) => Promise<void>;
	@Action('updateValidationError', { namespace }) private updateValidationError!: (error: object) => void;
	@Action('setRestaurant', { namespace: 'restaurant' }) private setRestaurant!: (Restaurant: Restaurant) => void;
	@Action('setRestaurantCharges', { namespace }) private setRestaurantCharges!: (restaurant: Restaurant) => void;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Prop({ type: String, required: true, default: '' }) private restaurantSlug!: string;
	$refs!: {observer: Validate}

	private orderId: string = '';
	private phoneNumber: string = '';
	private inputName: string = 'orderId';
	private inputType: string = 'text';
	private orderError: string = '';
	private phoneNumberError: string = '';
	private loading: boolean = false;

	// TODO TEMPORARY UNTIL CHILDREN ROUTE FIX WITH WEB LOGIN (We need to customization, etc here)
	private async created(): Promise<void> {

		// Already fetched the data
		if(!this.restaurant || !this.restaurant.name) {
			try {
				// Get menus and restaurant info
				const { restaurant } = await http.getData(this.restaurantSlug) as any;
				this.setRestaurant(restaurant);
				this.setRestaurantCharges(restaurant);
			} catch (err) {
				this.$router.push({ name: 'PageNotFound' }).catch(() => {});
				throw err;
			}
		}
	}

	/**
	 * Format the phone number - remove non-digital characters
	 * and add dashes to the respective positions
	 *
	 * @return {void}
	 */
	private formatPhoneNumber(): void {
		// TODO: modifying the input breaks virtual keyboard positioning when combining real keyboard inputs with virtual inputs
		if(this.phoneNumber) {
			this.phoneNumber = this.phoneNumber.replace(/\D/g,'');
			this.phoneNumber = this.phoneNumber.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-');
		}

	}

	/**
	 * Virtual keyboard on change event, target the input
	 * 
	 * @param {string} input
	 * @return {void}
	 */
	private onChange(input: string) {
		(this as any)[this.inputName] = input;

		if(this.inputName === 'phoneNumber') {
			this.formatPhoneNumber();
		}
	}

	/**
	 * Let the keyboard know which ID we are typing into
	 * 
	 * @param {any} input
	 * @return {void}
	 */
	private onInputFocus(input: any): void {
		this.inputName = input.target.id;
		this.inputType = input.target.type;
	}

	/**
	 * Validate all the input fields and then submit an order notification
	 *
	 * @return {Promise<void>}
	 */
	 public async saveAndContinue(): Promise<any> {
		this.loading = true;
		const isValid = await this.$refs.observer.validate()
		// If everything is validated, we can go next.
		if (!isValid) {
			this.loading = false;
			this.orderError = this.$t('checkout.form.notification.order_error');
			this.phoneNumberError = this.$t('checkout.form.notification.phone_number_error');
			return setTimeout(() => {
				const requiredLabel = document.getElementsByClassName('error-label')[0] as HTMLElement;
				requiredLabel.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
			}, 250);
		}
		else {
			this.orderError = '';
			this.phoneNumberError = '';
			await this.submitOrderNotification({ orderId: this.orderId, phoneNumber: this.phoneNumber })
				.then(() => {
					this.$toasted.show(this.$t('checkout.form.notification.success_message'), { type: 'error', position: 'top-center', className: 'success-toast' }).goAway(5000);
					this.orderId = '';
					this.phoneNumber = '';
					setTimeout(() => {
						this.loading = false;
					}, 1000);
				})
				.catch((error: any) => {
					this.displayErrorMessage(error);
					setTimeout(() => {
						this.loading = false;
					}, 1000);
					throw error;
				});
		}
	}

	/**
	 * Display the backend's error message to the user
	 *
	 * @param {any} error
	 * @return {void}
	 */
	private displayErrorMessage(error: any): void {
		let errorPrefix = this.$t('checkout.error_order_submission');

		// 500s
		if (typeof error === 'string') {
			this.$toasted.show(errorPrefix + error, { type: 'error', position: 'top-center' }).goAway(5000);
		}

		// 400s (error we can show to users)
		else if (error.response && error.response.data && error.response.data.message) {
			const tempMessage = error.response.data.message;

			// We don't want to show the dto validation errors here. Maybe in the future when we display those in a more user-friendlyish
			if(Array.isArray(tempMessage)) {
				this.$toasted.show(errorPrefix, { type: 'error', position: 'top-center' }).goAway(5000);
			}

			// Other errors
			else {
				this.$toasted.show(errorPrefix + error.response.data.message, { type: 'error', position: 'top-center' }).goAway(5000);
			}
		}

		// 500s
		else {
			this.$toasted.show(errorPrefix, { type: 'error', position: 'top-center' }).goAway(5000);
		}
	}
}
