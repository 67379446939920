
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';
import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
import ChevronUpIcon from 'vue-feather-icons/icons/ChevronUpIcon';
import LocaleSwitcher from '@/components/navigation/LocaleSwitcher.vue';
import MenuDropdownItem from '@/components/navigation/MenuDropdownItem.vue';
const clickaway = require('vue-clickaway').mixin;

const namespace: string = 'restaurant';

@Component<MenuDropdown>({
	components: {
		ChevronDownIcon,
		ChevronUpIcon,
		LocaleSwitcher,
		MenuDropdownItem
	},
	mixins: [ clickaway ]
})

export default class MenuDropdown extends Vue {
	@Getter('getRestaurant', { namespace }) private restaurant!: Restaurant;
	@Getter('noTakeoutTimeslot', { namespace }) private noTakeoutTimeslot!: boolean;
	@Getter('getConcourseUrl', { namespace }) private concourse!: string;
	@Getter('isGenericTableLocation', { namespace: 'cart' }) private isGenericTableLocation!: boolean;
	@Getter('getTableNum', { namespace: 'cart' }) private tableNum!: string | null;
	@Prop({ type: Boolean, required: false, default: false }) private more!: boolean;
	@Prop({ type: Array, required: true, default: () => {} }) private elements!: Menu[] | MenuGroup[];
	@Prop({ type: Number, required: false, default: 0 }) private limit!: number;
	@Prop({ type: Number, required: true, default: 0 }) private currentElement!: number;
	@Prop({ type: String, required: true, default: '' }) private dropdownTitle!: string;

	private opened: boolean = false;
	private menuSelectedThisSession: boolean = false;

	// In locations (i.e. Bowlero) where generic table locations are used as well as having the menu dropdown open on load,
	// we need to prevent the menu dropdown from closing when the user is selecting their table location
	private get preventClickaway(): boolean {
		return !!(this.restaurant.openMenuDropdownOnLoad && !this.menuSelectedThisSession && this.isGenericTableLocation && !this.tableNum);
	}

	// If the restaurant is configured to have the menu dropdown open on load and the user has not selected a menu this session, open the dropdown
	private created(): void {
		this.menuSelectedThisSession = !!sessionStorage.getItem('menu_selected');
		if (this.restaurant.openMenuDropdownOnLoad && !this.menuSelectedThisSession) {
			this.opened = true;
		}
	}

	/**
	 * Close the dropdown on clickaway
	 *
	 * @return {void}
	 */
	private away(): void {
		if(this.opened && !this.preventClickaway) {
			this.opened = false;
		}
	}

	/**
	 * Select the correct menu or menu group and send the event to the parent
	 * to make the respective update
	 *
	 * @param {number} idx
	 * @param {string} elementName
	 * @return {void}
	 */
	private selectElement(idx: number, elementName: string): void {
		this.setMenuSelectedSessionCookie();
		this.$emit('select-element', { 'idx': idx, 'elementName': elementName });
		fireGoogleTag({ name: 'menuSelected', detail: elementName });
		this.opened = false;
	}

	/**
	 * Toggle the dropdown
	 *
	 * @return {void}
	 */
	private toggleDropdown(): void {
		this.setMenuSelectedSessionCookie();
		this.opened = !this.opened;
	}

	/**
	 * Set the menu selected cookie
	 * Prevents the menu dropdown from opening again on render for the session
	 *
	 * @return {void}
	 */
	private setMenuSelectedSessionCookie(): void {
		if (this.restaurant.openMenuDropdownOnLoad && !this.menuSelectedThisSession) {
			sessionStorage.setItem('menu_selected', 'true');
		}
	}
}
